import axiosInstance from './axiosInstance';
import Service from '../../../models/ServiceModel';

const getAllServices = async (): Promise<Service[]> => {
    const response = await axiosInstance.get<Service[]>('/Service/');
    return response.data;
};

const getServiceByRestaurant = async (restaurantId: number): Promise<Service[]> => {
    const response = await axiosInstance.get<Service[]>(`/Service/restaurant/${restaurantId}`);
    return response.data;
};

const getServiceByRestaurantAndType = async (restaurantId: number, type: string): Promise<Service[]> => {
    const response = await axiosInstance.get<Service[]>(`/Service/restaurantId=${restaurantId}&type=${type}`);
    return response.data;
};

export const servicesRestauService = {
    getAllServices,
    getServiceByRestaurant,
    getServiceByRestaurantAndType
};
