import { useEffect, useImperativeHandle, useState, forwardRef, useRef } from 'react';
import { Modal, Box, Typography, Button, TextField, Autocomplete, Backdrop, Grid, InputLabel, FormControlLabel, Select, MenuItem, Checkbox, CardContent, capitalize, FormControl } from '@mui/material';
import getAllLanguages from 'iso-639-1';
import CustomNumberInput from '../../../shared/CustomInputs/NumberInput';
import { MuiTelInput } from 'mui-tel-input';
import CustomerStatus from '../../../../enums/CustomerEnums/CustomerStatus';
import Customer from '../../../../models/Customers';
import Reservation from '../../../../models/ReservationModel';
import AllergySelect from '../../../shared/CustomInputs/AllergySelect';
import FoodPrefsSelect from '../../../shared/CustomInputs/FoodPrefsSelect';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import Food_Preferences from '../../../../models/Food_Preferences';
import Allergies from '../../../../models/Allergies';

import "./ReservationForm.css"

interface ICustomerReservationForm {
  langage: string,
  customersList: Customer[],
  customer: Customer,
  onCustomerChange: (customer: Customer) => void,
  foodPrefs: Food_Preferences[],
  allergies: Allergies[],
  allergiesCustomer: AllergiesCustomer[],
  foodPrefsCustomer: FoodPreferencesCustomer[],
  setAllergiesIds: React.Dispatch<React.SetStateAction<Number[]>>,
  setFoodPrefsIds: React.Dispatch<React.SetStateAction<Number[]>>,
}

export default forwardRef((props: ICustomerReservationForm, ref) => {
  const [bedroom, setRoom] = useState('');
  const [allergiesCustIds, setAllergiesCustIds] =  useState<Number[]>([]); 
  const [foodPrefsCustIds, setFoodPrefsCustIds] = useState<Number[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const firstNameRef = useRef<HTMLInputElement | null>(null);
  


  useEffect(() => {
    props.setAllergiesIds(allergiesCustIds)
  }, [allergiesCustIds])

  useEffect(() => {
    props.setFoodPrefsIds(foodPrefsCustIds)
  }, [foodPrefsCustIds])

  // useEffect(() =>{
  //   props.setAllergiesIds(allergiesCustIds);
  //   props.setFoodPrefsIds(foodPrefsCustIds);
  // }, [foodPrefsCustIds, allergiesCustIds])

  useEffect(() => {
    if (props.customer){
      setRoom(props.customer.bedroom);
    }
  }, [props.customer])

  useImperativeHandle(ref, () => ({
    validateFields: () => {
      let newErrors: { [key: string]: string } = {};

      if (!props.customer.firstName.trim()) {
        newErrors.customerName = "Prénom requis";
        setTimeout(() => {
          firstNameRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
          firstNameRef.current?.focus();
        }, 100); // Petit délai pour éviter d’éventuels conflits de mise à jour
      }

      setErrors(newErrors);
      return newErrors;
    },
  }));

  const handleRoomChange = (value: string) => {
    setRoom(value);
    props.customer.bedroom = value;
  }

  const handlePhoneChange = (value: string) => {
    // Mettez à jour à la fois `phone` et `Number` en une seule opération
    const updatedCustomer = { ...props.customer, phone: value, Number: value };
    props.onCustomerChange(updatedCustomer);
  };

  const languageCodes = getAllLanguages.getAllCodes();
  const languageOptions = languageCodes.map((code) => ({
      label: getAllLanguages.getName.toString(),
      value: code,
  })); 

    // useEffect(() => {
    //   console.log(' languageOptions() : ',  languageOptions)
    //   console.log(' getAllLanguages.getAllNames() : ',  getAllLanguages.getAllNames())
    //   console.log(' getAllLanguages.getAllNativeNames() : ',  getAllLanguages.getAllNativeNames())
    // }, [])

  const handleCustomerChange = (field: string, value: any) => {
    const updatedCustomer = { ...props.customer, [field]: value };
    props.onCustomerChange(updatedCustomer);
  };

  const handleTitleAndSexChange = (title: string) => {
    if (title === 'Mr' ){
      handleCustomerChange("sex", 'Male')
    }else if (title === 'Mrs' || title === 'Miss'){
      handleCustomerChange("sex", 'Female')
    }
    handleCustomerChange("title", title)
  }

  const handleAutoComplete = (customer: Customer) =>{
    props.onCustomerChange(customer);
  }

  
  const resetCustomer = () =>{
    props.onCustomerChange(new Customer())
  }

  return (
    <div>
      <Grid container rowSpacing={2}>
        <Grid container item justifyContent="end">
          {/* <Grid>
            <div>Chambre</div>
            <TextField
              placeholder={bedroom}
              onChange={(event) => event ? handleRoomChange(event.target.value) : bedroom}
            />
          </Grid> */}
          <Button type='reset' onClick={resetCustomer}>Réinitialiser client</Button>
          {/* <div>
            <div>Type</div>
            <select value={Number(customerStatus)} onChange={(e) => handleCustomerStatusChange(Number(e.target.value) as unknown as CustomerStatus)}>
              <option value={0} disabled>Choisir régime</option>
              <option value={CustomerStatus.Excluded}>Hotel</option>
              <option value={CustomerStatus.External}>Externe</option>
            </select>
          </div> */}
          
        </Grid>
        <Grid container item alignItems="flex-end" columnSpacing={2}>
          <Grid item xs={12} md={4}>
            <Typography className='input-title'>Titre</Typography>
            <Select variant='outlined' size='small' value={props.customer.title} onChange={(e) => handleTitleAndSexChange(e.target.value)}>
              <MenuItem value="">Choisir titre</MenuItem>
              <MenuItem value="Mr">M.</MenuItem>
              <MenuItem value="Mrs">Mme</MenuItem>
              <MenuItem value="Miss">Mlle</MenuItem>
              <MenuItem value="Professeur">Pr.</MenuItem>
              <MenuItem value="Docteur">Dr.</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className='input-title'>Nom</Typography>
            <Autocomplete
                size='small'
                options={props.customersList}
                getOptionLabel={(option) => (typeof option === "string" ? option : option.lastName.toUpperCase())}
                filterOptions={(options, { inputValue }) =>
                  options.filter((customer) =>
                    customer.lastName.toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                value={props.customer || { lastName: "" }} // Assure que `value` est toujours un objet
                isOptionEqualToValue={(option, value) =>
                  typeof option !== "string" && typeof value !== "string" && option.idCustomer === value.idCustomer
                }
                onChange={(event, newValue) => {
                  // if (newValue === null) {
                  //   // Si l'utilisateur clique sur la croix, NE PAS RESET si c'était un nom libre
                  //   if (props.customer?.idCustomer) {
                  //     props.onCustomerChange(new Customer()); // Reset si c'était un client existant
                  //   }
                  // } else
                  if (typeof newValue === "string") {
                    handleCustomerChange("lastName", newValue);
                  } else if (newValue) {
                    handleAutoComplete(newValue);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  // Mettre à jour le champ de saisie avec le nouveau texte
                  handleCustomerChange("lastName", newInputValue);
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.idCustomer}>
                    {option.lastName.toUpperCase()} {option.firstName}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} placeholder="Nom"/>}
                freeSolo // Autorise la saisie manuelle
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className='input-title'>Prénom</Typography>
              <TextField variant='outlined' size='small' ref={firstNameRef} type="text" value={capitalize(props.customer.firstName)} onChange={(e) => handleCustomerChange("firstName", e.target.value)} spellCheck={false}></TextField>
            </Grid>
            {errors.customerName && <p style={{ color: "red" }}>{errors.customerName}</p>}
        </Grid>

        <Grid container item alignItems="flex-end" columnSpacing={2}>
          <Grid item xs={12} md={4}>
            <Typography className='input-title'>Téléphone</Typography>
            <MuiTelInput variant='outlined' size='small' defaultCountry={'FR'} value={props.customer.phone} onChange={(e) => handlePhoneChange(e)}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className='input-title'>Email</Typography>
            <Autocomplete
              size='small'
              options={props.customersList}
              getOptionLabel={(option) => (typeof option === "string" ? option : option.email)}
              filterOptions={(options, { inputValue }) =>
                options.filter((customer) =>
                  customer.email?.toLowerCase().includes(inputValue.toLowerCase())
                )
              }
              value={props.customer || { email: "" }} // Assure que `value` est toujours un objet
              isOptionEqualToValue={(option, value) =>
                typeof option !== "string" && typeof value !== "string" && option.idCustomer === value.idCustomer
              }
              onChange={(event, newValue) => {
                // if (newValue === null) {
                //   // Si l'utilisateur clique sur la croix, NE PAS RESET si c'était un nom libre
                //   if (props.customer?.idCustomer) {
                //     props.onCustomerChange(new Customer()); // Reset si c'était un client existant
                //   }
                // } else if (typeof newValue === "string") {
                //   // L'utilisateur tape un nouveau nom → Mettre à jour normalement
                //   handleCustomerChange("email", newValue);
                // } else {
                //   // Sélection d'un client existant
                //   handleAutoComplete(newValue);
                // }
                if (typeof newValue === "string") {
                  handleCustomerChange("email", newValue);
                } else if (newValue) {
                  handleAutoComplete(newValue);
                }
              }}
              onInputChange={(event, newInputValue) => {
                // Mettre à jour le champ de saisie avec le nouveau texte
                handleCustomerChange("email", newInputValue);
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.idCustomer}>
                  {option.email}
                </li>
              )}
              renderInput={(params) => <TextField {...params} placeholder="Email"/>}
              freeSolo // Autorise la saisie manuelle
            />

          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className='input-title'>Langue</Typography>
            <Select 
              variant='outlined'
              size='small'
              value={
                props.customer.languageCode && props.customer.languageCode.includes('-') 
                  ? props.customer.languageCode.split('-')[0] 
                  : props.customer.languageCode || ""
              }
              onChange={(e) => handleCustomerChange("languageCode", e.target.value)}>
              {languageOptions.map((langue, index) => (
                  <MenuItem key={index} value={ langue.value}>{getAllLanguages.getName(langue.value)}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>


        <Grid container item alignItems="flex-end" columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <Typography className='input-title'>Allergies</Typography>
            <AllergySelect
              allergies={props.allergiesCustomer}
              allergiesList={props.allergies}
              setAllergiesIds={setAllergiesCustIds}
              allergieType='customer'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className='input-title'>Préférences</Typography>
            <FoodPrefsSelect
              foodPref={props.foodPrefsCustomer}
              foodPrefList={props.foodPrefs}
              setFoodPrefIds={setFoodPrefsCustIds}
              foodPrefType='customer'
            />
          </Grid>
        </Grid>
      </Grid>
      <hr/>
    </div>
  );
})