interface CancelReservationFormProps{
    setShowCancelResa: React.Dispatch<React.SetStateAction<boolean>>;
}

function CancelReservationForm(props: CancelReservationFormProps){
    return (
        <div className="show-bon-overlay">
            <div className="show-bon-container">
            <button className="close-button" onClick={() => props.setShowCancelResa(false)}>X</button>
            <br/>
                <label>Merci de préciser la raison de l'annulation de la réservation</label>
                <br/>
                <input type='text'/>
            </div>
        </div>
    )
}

export default CancelReservationForm;