import React, { useEffect, useState } from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Allergy from "../../../enums/ReservationEnums/ReservationAllergy";
import Allergies from "../../../models/Allergies";
import Reservation from "../../../models/ReservationModel";
import AllergiesReservation from "../../../models/AllergiesReservation";
import AllergiesCustomer from "../../../models/AllergiesCustomer";

interface IAllergySelect{
    allergies: AllergiesReservation[] | AllergiesCustomer[],
    allergiesList: Allergies[],
    setAllergiesIds: React.Dispatch<React.SetStateAction<Number[]>>;
    allergieType: string
}

export default function AllergySelect(props: IAllergySelect) {
  const [label_allergy, setLabelAllergy] = useState<Record<number, string>>({});
  const [allergiesIds, setAllergiesIds] = useState<Number[]>([])

  // useEffect(() => (
  //   setAllergiesIds(allergiesIds)
  // ), [props.setAllergiesIds])

  useEffect(() => {
    console.log('je pase dans le useEffect Allergy Select')
    const labelMap: Record<number, string> = {};
    const ids: Number[] = [];
    props.allergiesList.forEach((f) => {
        labelMap[f.id] = f.displayAs;
    });

    props.allergies.forEach((a) => {
      const foodPref = props.allergiesList.find(allergie => allergie.id === Number(a.allergiesId));
      if (foodPref) {
        ids.push(foodPref.id);
      }
    });
  
    setLabelAllergy(labelMap);
    props.setAllergiesIds(ids);
    setAllergiesIds(ids);
    
  }, []);
  

  return (
    <Select
      size="small"
      variant='outlined'
      multiple
      value={allergiesIds}
      onChange={(e) => {
        const newValues = Array.isArray(e.target.value) ? e.target.value : [];
        props.setAllergiesIds(newValues);
        setAllergiesIds(newValues);
        // props.setAllergiesIds(newValues);
      }}
      renderValue={(selectedPref) => (
        <Stack gap={1} direction="row" flexWrap="wrap">
          {selectedPref.map((value: Number) => (
            value !== 0 ?
            <Chip
              key={value.toString()}
              label={label_allergy[Number(value)]}
              onDelete={() => {
                const updatedIds = allergiesIds.filter((item) => item !== value);
                props.setAllergiesIds(updatedIds);
                setAllergiesIds(updatedIds);
                // props.setAllergiesIds(updatedIds);
              }}
              deleteIcon={
                <CancelIcon
                  onMouseDown={(event) => event.stopPropagation()}
                />
              }
            /> : null
          ))}
        </Stack>
      )}
    >
      {props.allergiesList ? (
      props.allergiesList.map((foodPref) => (
        <MenuItem key={foodPref.id} value={foodPref.id}>
          {foodPref.displayAs}
        </MenuItem>
      ))
    ) : null}
    </Select>
  );
}