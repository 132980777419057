import { useEffect, useRef, useState } from 'react';
import GnzButton from '../shared/gnz-button/GnzButton';
import FormMode from '../../enums/FormMode';
import GnzImage from '../shared/gnz-image/GnzImage';
import plus2 from '../../images/shared/plus2.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FeedbackTable from './FeedbackTable/FeedbackTable';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import _Feedback from '../../models/Feedback';
import GnzDrawer from '../shared/gnz-drawer/GnzDrawer';
import { API_MEWS_URL }   from '../../ConfigUrl';


import './Feedback.css';
import axios from 'axios';

function Feedback() {
  const { userName } = useSelector((state: RootState) => state.user);
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const drawerRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const [feedback, setFeedbacks] = useState<Array<_Feedback>>([]);
  
  const Url = API_MEWS_URL;

  const newFeedback = () => {
    setMode(FormMode.New);
    openDrawer();
  };

  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }

  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }
  
  useEffect(() => {
    const startDateEsist = localStorage.getItem('startDateFeedback');   
    if(startDateEsist == null){
      setStartDate(date);
      setEndDate(date);
      localStorage.setItem('startDateFeedback',date);
      localStorage.setItem('endDateFeedback',date);
    }   else{
      setStartDate(localStorage.getItem('startDateFeedback') ?? date);  
      setEndDate(localStorage.getItem('endDateFeedback')?? date); 
    }
    }, [])

  const handleChangeDate = (event: any) => {    
    setStartDate(event.target.value);
    localStorage.setItem('startDateFeedback',event.target.value);
    LoadFeedback();
  };

  const handleChangeDateEnd = (event: any) => {
    setEndDate(event.target.value);
    localStorage.setItem('endDateFeedback',event.target.value);
    LoadFeedback();       
  };

const LoadFeedback = () => {
  var startDate1 = localStorage.getItem('startDateFeedback') ?? date;  
  var endDate1 = localStorage.getItem('endDateFeedback')?? date;  

  axios.get(Url + 'Feedback/' + startDate1 + '/' + endDate1)
  .then(res => setFeedbacks(res.data));
  }

  useEffect(() => {
    LoadFeedback();
  }, [])

  return (
    <div className="feed">
      <div className="FeedbackTopActions">
        <h3 className='FeedbackTopActionsh3'>Feedback Clients</h3>

        <div className='FeedbackSelectSyleDiv'>
          {/* <input className="FeedbackDatasyle" placeholder='Du'></input>
          <input className="FeedbackDatasyle" placeholder='Au'></input> */}
          <input type="date" className="DatasyleFeddback" defaultValue={startDate} onChange={handleChangeDate} />
          <input type="date" className="DatasyleFeddback" defaultValue={endDate} onChange={handleChangeDateEnd} />
          <GnzButton leftIcon={<GnzImage src={plus2} />} label="Nouveau Feedback" onClick={newFeedback}
            color="#1D62AF" backgroundColor="#A0E295" />
        </div>
      </div>

      <FeedbackTable  feedback ={feedback}/>

      <GnzDrawer ref={drawerRef} component={
        <FeedbackForm
          mode={mode}
          feedbackType={'Feedback'}
          feedback={undefined}
          startDate={startDate}
          endDate={endDate}
          title={'Nouveau Feedback'}
          closeForm={closeDrawer}          
          customerId={""}
        />
      } />
     
    </div>
  );
}
export default Feedback;