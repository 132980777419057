import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import './calendar.css';
import Day from './day';
import { monthStatus } from '../../temp/data';

interface CalendarProps {
    onClose: () => void;
}

type CalendarDay = {
    day: number;
    status: string;
    tablesRemaining: number;
};

const Calendar: React.FC<CalendarProps> = ({ onClose }) => {
    const [updateRestau, setUpdateRestau] = useState('');
    const [updateService, setUpdateService] = useState('');
    const [updateServiceType, setUpdateServiceType] = useState('');

    const handleChangeRestau = (event: SelectChangeEvent) => {
        setUpdateRestau(event.target.value);
    };

    const handleChangeService = (event: SelectChangeEvent) => {
        setUpdateService(event.target.value);
    };

    const handleChangeServiceType = (event: SelectChangeEvent) => {
        setUpdateServiceType(event.target.value);
    };

    // State to hold the selected date from the DatePicker
    const [selectedDate, setSelectedDate] = useState(new Date());
    // State to hold the calendar data including days with and without reservations
    const [calendarData, setCalendarData] = useState < CalendarDay[] > ([]);

    useEffect(() => {

        // Calculate the number of days in the selected month
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth();

        // Get the number of days in the month
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        // Create an array to represent all days of the selected month
        const allDays = [];
        for (let day = 1; day <= daysInMonth; day++) {
            // Check if the day exists in the mock data
            const dayData = monthStatus.find(d => d.day === day);
            // If it exists, use that data, otherwise set default values
            allDays.push(dayData || { day, status: 'available', tablesRemaining: 100 });
        }

        setCalendarData(allDays);
    }, [selectedDate]);

    const handleDateChange = (newDate: string) => {
        if (newDate) {
            const [year, month] = newDate.split('-');
            setSelectedDate(new Date(Number(year), Number(month) - 1, 1));
        }
    };

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="restau-calendar-container">
                <div className='restau-calendar-header'>
                    <ArrowCircleLeftIcon onClick={onClose} style={{ color: "#1d62af", fontSize: "36px", cursor: "pointer" }} />
                    <h1>Visualisation globale mensuelle</h1>
                    <span></span>
                </div>

                <div className="restau-calendar-filter">
                    <FormControl sx={{ m: 1, minWidth: 120, width: 220, border: "none" }} size="small">
                        <InputLabel id="demo-select-small-label">Restaurant</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={updateRestau}
                            label="Restaurant"
                            onChange={handleChangeRestau}
                            style={{ backgroundColor: "#fff", border: "none" }}
                        >
                            <MenuItem value="">
                                <em>Tous</em>
                            </MenuItem>
                            <MenuItem value={"horaire"}>Restau X</MenuItem>
                            <MenuItem value={"date"}>Restau Y</MenuItem>
                        </Select>
                    </FormControl>

                    <input
                        type="month"
                        name="Mois" id=""
                        value={formatDate(selectedDate)}
                        onChange={(e) => handleDateChange(e.target.value)}
                    />

                    <FormControl sx={{ m: 1, minWidth: 120, width: 220 }} size="small">
                        <InputLabel id="demo-select-small-label">Service</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={updateServiceType}
                            label="Service"
                            onChange={handleChangeServiceType}
                            style={{ backgroundColor: "#fff" }}
                        >
                            <MenuItem value="">
                                <em>Tous</em>
                            </MenuItem>
                            <MenuItem value={"nonstop"}>Non-Stop</MenuItem>
                            <MenuItem value={"lunch"}>Déjeuner</MenuItem>
                            <MenuItem value={"diner"}>Dîner</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120, width: 220 }} size="small">
                        <InputLabel id="demo-select-small-label">Sous-Service</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={updateService}
                            label="Sous-Service"
                            onChange={handleChangeService}
                            style={{ backgroundColor: "#fff" }}
                        >
                            <MenuItem value="">
                                <em>Tous</em>
                            </MenuItem>
                            <MenuItem value={"horaire"}>1er service</MenuItem>
                            <MenuItem value={"date"}>2eme service</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="restau-calendar">
                    {calendarData.map((dayData: CalendarDay) => (
                        <Day
                            key={dayData.day}
                            day={dayData.day}
                            status={dayData.status}
                            tablesRemaining={dayData.tablesRemaining}
                        />
                    ))}
                </div>
            </div>
        </Modal>
    );
}

export default Calendar;