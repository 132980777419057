import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import Restaurant from '../../../../models/Restaurant';
// import { Restaurant } from '../../../restaurant/types/types';

interface EstablishmentFilterProps {
    filterEstablishmentId: number, 
    establishmentList: Restaurant[],
    onFilterChange: (id: number) => void;
}

function EstablishmentFilter(props: EstablishmentFilterProps) { 
    return (
        <select value={props.filterEstablishmentId ? props.filterEstablishmentId : ""} className="filter-input" onChange={e => props.onFilterChange(Number(e.target.value))}>
        {props.establishmentList.map((e, index) =>
            <option key={index} value={e.id}>{e.nom}</option>
            )}
        </select>
    );
}

export default EstablishmentFilter;