import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Checkbox } from 'antd';
import Tag from '../../../../models/Tag';
import TagIcon from '../../../../images/ProfilClients_img/TagImg.png'
import Allergies from '../../../../models/Allergies';
import FoodPreferences from '../../../../models/Food_Preferences';
import axios from 'axios';
import './ProfilClientsDetailsForm.css';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import GnzButton from '../../../shared/gnz-button/GnzButton';
import { API_MEWS_URL } from '../../../../ConfigUrl';

interface IProfilClientsTagsForm {
  myParam: string,
  allergiesCustomer: Array<AllergiesCustomer>,
  foodPreferencesCustomer: Array<FoodPreferencesCustomer>,
  closeForm: () => void,
}

function ProfilClientsTagsForm(props: IProfilClientsTagsForm) {
  const [Tag, tag] = useState<Array<Tag>>([]);
  const [Allergies, allergies] = useState<Array<Allergies>>([]);
  const [FoodPreferences, foodPreferences] = useState<Array<FoodPreferences>>([]);
  const tagIcon = TagIcon;
  const Url = API_MEWS_URL;

  useEffect(() => {
    axios.get(Url + 'Tag')
      .then(res => tag(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'Allergy')
      .then(res => allergies(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPref')
      .then(res => foodPreferences(res.data));
  }, [])

  const handleChange = (event: any) => {
    axios.post(Url + 'CustomerTag', {
      "CustomerId": props.myParam,
      "IdTag": event.target.value
    })
      .then();
  };

  const handleChangeAllergiesCustomer = async (event: any) => {
    try {
      const response = await axios.post(Url + 'AllergiesCustomer', {
        "CustomerId": props.myParam,
        "allergiesId": event.target.value
      })
        .then();
    } catch (error) {
      console.error('Hubo un error:', error);
    } finally {

    }
  };

  const handleChangeFoodPreferenceCustomer = async (event: any) => {
    try {
      const response = await axios.post(Url + 'FoodPreferenceCustomer', {
        "CustomerId": props.myParam,
        "food_PreferencesId": event.target.value
      })
        .then();
    } catch (error) {
      console.error('Hubo un error:', error);
    } finally {

    }
  };


  const saveChanges = async (event: any) => {
    window.location.reload();
  }


  return (
    <div className="FeedbackForm">
      <div className="FormActionsLefth">
        <div className="FeedbackFormClose" onClick={props.closeForm}>X</div>
      </div>

      <div className="moreContent">

        <div className='TagsTitle'>
          <h4>Allergies  <img className="TagImg" src={tagIcon} alt="" /></h4>         
        </div>

        <div className='Tag_content'>
          {Allergies.map(r => (
            <div className='TagsLine'>
              <Checkbox value={r.id} onChange={handleChangeAllergiesCustomer} defaultChecked={props.allergiesCustomer.some(item => r.id === item.allergiesId)} />
              {r.displayAs} <img className="TagImg" src={`data:image/jpeg;base64,${r.image}`} alt="" />
            </div>
          ))}
        </div>

        <div className='TagsTitle'>
          <h4>FoodPreferences  <img className="TagImg" src={tagIcon} alt="" /></h4>         
        </div>

        <div className='Tag_content'>
          {FoodPreferences.map(r => (
            <div className='TagsLine'>
              <Checkbox value={r.id} onChange={handleChangeFoodPreferenceCustomer} defaultChecked={props.foodPreferencesCustomer.some(item => r.id === item.food_PreferencesId)} />
              {r.displayAs}<img className="TagImg" src={`data:image/jpeg;base64,${r.image}`} alt="" />
            </div>
          ))}
        </div>
      </div>

      <div className="SaveStyle">
        <div className="FormActionsRight">
          <GnzButton label="Save" onClick={saveChanges} color="#ffffff" backgroundColor="#1D62AF" />
        </div>
      </div>

    </div>
  );
}

export default ProfilClientsTagsForm;
