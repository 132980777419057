import axiosInstance from './axiosInstance';
import ReservationStatus from '../../../models/ReservationStatus';

const getAllReservationStatus = async (): Promise<ReservationStatus[]> => {
    const response = await axiosInstance.get<ReservationStatus[]>('/ResaStatus/');
    return response.data;
};

const getReservationStatusById = async (id: number): Promise<ReservationStatus> => {
    const response = await axiosInstance.get<ReservationStatus>(`/ResaStatus/${id}`);
    return response.data;
};

export const reservationStatusService = {
    getAllReservationStatus,
    getReservationStatusById
};
