import { useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import './CreateCardForm.css'
import FormMode from "../../../../enums/FormMode";
import { Customer, Reservation, Service, CardType, CardStatus } from "./CarteCreditInterfaces";

interface CreateCardFormProps{
    customersList: Customer[];
    servicesList: Service[];
    reservationsList: Reservation[];
    cardTypesList: CardType[];
    cardStatusesList: CardStatus[];
    showCardForm: boolean;
    setShowCardForm: React.Dispatch<React.SetStateAction<boolean>>;
    handleResetDateExpiration: () => void;
    handleSubmit: (e: React.FormEvent) => void;
    nom: string;
    setNom: React.Dispatch<React.SetStateAction<string>>;
    prenom: string;
    setPrenom: React.Dispatch<React.SetStateAction<string>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    telephone: string;
    setTelephone: React.Dispatch<React.SetStateAction<string>>;
    dateExpiration: Date | null;
    setDateExpiration: React.Dispatch<React.SetStateAction<Date | null>>;
    closeForm: () => void;
}

function CreateCardForm(props: CreateCardFormProps){
    const [nameSuggestions, setNameSuggestions] = useState<Customer[]>([]);
    const [phoneSuggestions, setPhoneSuggestions] = useState<Customer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [selectedService, setSelectedService] = useState<string>('');
    const [mode, setMode] = useState<FormMode>(FormMode.None);

    const [nameSearch, setNameSearch] = useState('');
    const [phoneSearch, setPhoneSearch] = useState('');
    const [isCleared, setIsCleared] = useState(false);

    // Fonction pour gérer l'autocomplétion sur le champ "Nom Client"
      const handleNameSearchChange = (value: string) => {
        setNameSearch(value.toUpperCase());
    
        if (value.trim() !== '') {
            // Filtrer les suggestions en fonction de la saisie de l'utilisateur
            const matchingCustomers = props.customersList.filter((customer) =>
              customer.lastname.toLowerCase().includes(value.toLowerCase())
            );
            setNameSuggestions(matchingCustomers);
          } else {
            setNameSuggestions([]); // Aucune saisie, pas de suggestions
          }
      };
    
      // Fonction pour gérer l'autocomplétion sur le champ "N° Téléphone"
      const handlePhoneSearchChange = (value: string) => {
        setPhoneSearch(value);
    
        if (value.trim() !== '') {
          // Filtrer les suggestions en fonction de la saisie de l'utilisateur
          const matchingCustomers = props.customersList.filter((customer) =>
            customer.phone.includes(value)
          );
          setPhoneSuggestions(matchingCustomers);
        } else {
          setPhoneSuggestions([]); // Aucune saisie, pas de suggestions
        }
      };

    const renderNameSuggestions = () => {
        if (nameSuggestions.length === 0) {
          return null;
        }
    
        return (
          <div className="suggestions">
            {nameSuggestions.map((customer) => (
              <p
                key={customer.id}
                onClick={() => handleNameSelect(customer)}
              >
                {customer.lastname.toUpperCase()}
              </p>
            ))}
          </div>
        );
    }

    const renderPhoneSuggestions = () => {
        if (phoneSuggestions.length === 0) {
          return null;
        }
    
        return (
          <div className="suggestions">
            {phoneSuggestions.map((customer) => (
              <p
                key={customer.id}
                onClick={() => handlePhoneSelect(customer)}
              >
                {customer.phone}
              </p>
            ))}
          </div>
        );
    };

    const handleClear = () => {
        setSelectedCustomer(null);
        setNameSearch('');
        setPhoneSearch('');
        setSelectedService('');
        props.setNom('');
        props.setPrenom('');
        props.setTelephone('');
        props.setEmail('');
        setIsCleared(false); // Marquez le bouton "Clear" comme cliqué
    };

    const handleNameSelect = (customer: Customer) => {
        setSelectedCustomer(customer);
        setNameSearch(customer.lastname.toUpperCase()); // Remplit le champ avec le nom sélectionné
        setNameSuggestions([]); // Cache la liste de suggestions
        setPhoneSearch(customer.phone);
        props.setNom(customer.lastname);
        props.setPrenom(customer.firstname);
        props.setTelephone(customer.phone);
        props.setEmail(customer.email);

        const customerResa = props.reservationsList.find((reservation) => reservation.customerId === customer.id)
        const matchingService = props.servicesList.find((service) => service.id === customerResa?.serviceId);
            if (matchingService) {
            setSelectedService(matchingService.name); // Mettez à jour la valeur du champ <select>
            } else {
            setSelectedService(''); // Si le service n'est pas trouvé, définissez la valeur sur vide
            }
    };
    
      // Fonction pour gérer la sélection d'un client par numéro de téléphone
    const handlePhoneSelect = (customer: Customer) => {
        setSelectedCustomer(customer);
        setPhoneSearch(customer.phone); // Remplit le champ avec le numéro de téléphone sélectionné
        setPhoneSuggestions([]); // Cache la liste de suggestions
        setNameSearch(customer.lastname.toUpperCase())
        props.setNom(customer.lastname);
        props.setPrenom(customer.firstname);
        props.setTelephone(customer.phone);
        props.setEmail(customer.email);

        const customerResa = props.reservationsList.find((reservation) => reservation.customerId === customer.id)
        const matchingService = props.servicesList.find((service) => service.id === customerResa?.serviceId);
            if (matchingService) {
            setSelectedService(matchingService.name); // Mettez à jour la valeur du champ <select>
            } else {
            setSelectedService(''); // Si le service n'est pas trouvé, définissez la valeur sur vide
            }
    };

    const submit = () =>{

    }

    return (
        <div className="CreditCardForm">
            <div className="cartes-form-container">
            <div className="CreditCardFormClose" onClick={props.closeForm}>X</div>
                <form onSubmit={props.handleSubmit} id='create-card'>
                    {/* Ajoutez votre formulaire ici */}
                    <h3>Nouvelle Carte de Crédit</h3>
                    <hr/>
                    <h5>Client existant ?</h5>
                    <label>Recherche par nom</label>
                    <input
                        id='name-search'
                        type="text"
                        placeholder="Nom"
                        value={nameSearch}
                        onChange={(e) => [handleNameSearchChange(e.target.value), setIsCleared(true)]}
                    />
                    {renderNameSuggestions()}

                    <label>Recherche par numéro de téléphone</label>
                    <input
                        id='phone-search'
                        type="text"
                        placeholder="N° Téléphone"
                        value={phoneSearch}
                        onChange={(e) => [handlePhoneSearchChange(e.target.value), setIsCleared(true)]}
                    />
                    {renderPhoneSuggestions()}
                    <button className="clear-button" onClick={handleClear}>
                        Clear
                    </button>
                    <hr/>
                    <input
                        type="text"
                        placeholder="Nom Client"
                        value={props.nom.toUpperCase()}
                        onChange={(e) => props.setNom(e.target.value)}
                        disabled={isCleared}
                    />
                    <input
                        type="text"
                        placeholder="Prénom Client"
                        value={props.prenom}
                        onChange={(e) => props.setPrenom(e.target.value)}
                        disabled={isCleared}

                    />
                    <input
                        type="text"
                        placeholder="N° Téléphone"
                        value={props.telephone}
                        onChange={(e) => props.setTelephone(e.target.value)}
                        disabled={isCleared}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={props.email}
                        onChange={(e) => props.setEmail(e.target.value)}
                        disabled={isCleared}
                    />
                    <select
                        className="filter-input"
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                        disabled={isCleared}
                    >
                        {props.servicesList.map((service, index) => (
                        <option key={index} value={service.name}>
                            {service.name}
                        </option>
                        ))}
                    </select>
                    <hr/>
                    <h4>Caractéristiques Carte</h4>
                    <select
                        className="filter-input"
                    >
                        {props.cardTypesList.map((b, index) => (
                            <option key={index} value={b.type}>{b.type}</option>
                        ))}
                        {/* Ajoutez d'autres options de statut ici */}
                    </select>  
                    <input
                        type="text"
                        placeholder="Numéro de la carte"
                    /> 
                    <div>  
                    <DatePicker
                        placeholderText='Date Expiration'
                        value={props.dateExpiration ? props.dateExpiration.toLocaleDateString() : ''}
                        selected={props.dateExpiration}
                        onChange={(date: Date | null) => {
                            if (date) {
                                props.setDateExpiration(date);
                            }
                        }}
                    />
                    <button onClick={props.handleResetDateExpiration}>X</button>
                    </div>
                    <input 
                        type='number'
                        placeholder='Cryptogramme'
                    />                
                    <input
                        type="text"
                        placeholder="Nom exact du détenteur"
                    />
                    <select
                        className="filter-input"
                    >
                        {props.cardStatusesList.map((b, index) => (
                            <option key={index} value={b.status}>{b.status}</option>
                        ))}
                    </select>
                    <select
                        className="filter-input"
                    >
                        {/* {types_debit.map((b, index) => (
                            <option key={index} value={b.type}>{b.type}</option>
                        ))} */}
                    </select>
                    <hr/>
                    <button className="close-button" type="submit">Valider</button>
                </form>
                </div>
        </div>
    )
}

export default CreateCardForm;