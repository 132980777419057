import halal from '../../../../images/icones_restau/halal.png';
import casher from '../../../../images/icones_restau/casher.png';
import vegan from '../../../../images/icones_restau/vegan.png';
import vegetarien from '../../../../images/icones_restau/vegetarien.png';
import seafood from '../../../../images/icones_restau/seafood.png';
import sugarfree from '../../../../images/icones_restau/sugarfree.png';
import porkfree from '../../../../images/icones_restau/porkfree.png';
import diabetique from '../../../../images/icones_restau/diabetique.png';
import enceinte from '../../../../images/icones_restau/enceinte.png';
import paleo from '../../../../images/icones_restau/paleo.png';
import pescetarien from '../../../../images/icones_restau/pescetarien.png';

export default function FoodPrefsMod() {
    const logos_fp = new Map([
        [1, halal],
        [2, casher],
        [3, vegan],
        [4, vegetarien],
        [5, seafood],
        [6, sugarfree],
        [7, porkfree],
        [8, diabetique],
        [9, enceinte],
        [10, paleo],
        [11, pescetarien],
      ])

    const foodPrefs =new Map([
        [1, "Halal"],
        [2, "Casher"],
        [3, "Vegan"],
        [4, "Végétarien"],
        [5, "Sans seafood"],
        [6, "Sans sucre"],
        [7, "Sans porc"],
        [8, "Diabétique"],
        [9, "Enceinte"],
        [10, "Régime Paleo"],
        [11, "Régime Pescetarien"],
      ])

      return (
        <div>
          {Array.from(foodPrefs).map(([id, label]) => (
            <div className="modal-item" key={id}>
                <img                                
                        className=''
                        key={id}
                        src={logos_fp.get(id)}
                        alt={"img_" + label}                    
                    /> 
            {label}
            </div>
          ))}
        </div>
      );
}

