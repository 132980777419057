export default class SpecialOccasion {
    public id: number = 0;
    public displayAs: string = '';
    public label: string = '';

    constructor(
        id: number = 0,
        displayAs: string = '',
        label: string = ''        
    )
    {
        this.id = id;
        this.displayAs = displayAs;
        this.label = label;
    }
} 