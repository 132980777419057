import { useNavigate } from 'react-router-dom';
import customerImg1 from '../../../images/ProfilClients_img/Ale.png';
import Reservation from '../../../models/Reservation';
import ReservationsRestau from '../../../models/ReservationsRestau';
import GnzDrawer from '../../shared/gnz-drawer/GnzDrawer';
import ImgCcTrue from '../../../images/ProfilClients_img/CcT.png'
import ImgCcFalse from '../../../images/ProfilClients_img/CcF.png'
import ImgMailTrue from '../../../images/ProfilClients_img/mailT.png'
import ImgMailFalse from '../../../images/ProfilClients_img/mailF.png'
import ImgNotifyTrue from '../../../images/ProfilClients_img/notificationT.png'
import ImgNotifyFalse from '../../../images/ProfilClients_img/notificationF.png'
import ProfilClientsCreditCardForm from '../ProfilClienteDetails/ProfilClientsDetailsForm/ProfilClientsCreditCardForm'
import { format } from 'date-fns';
import { API_MEWS_URL }   from '../../../ConfigUrl';
import './ProfilClientsTable.css';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Typography } from '@mui/material';

interface IProfilClientsTable {
    search: string,
    Reservation: Array<Reservation>,
    ReservationsRestau: Array<ReservationsRestau>,
    Stato: string,
    TypeClient: string,
    Order: string,
    reloadData: () => void,
}

function ProfilClientsTable(props: IProfilClientsTable) {
    const [statoName, SetStatoName] = useState('');
    const [typeUpdate, SetTypeUpdate] = useState('');
    const [_myParam, Set_myParam] = useState('');
    const drawerApproveCreditcard = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const customerImg = customerImg1;
    const imgCcTrue = ImgCcTrue;
    const imgCcFalse = ImgCcFalse;
    const imgMailTrue = ImgMailTrue;
    const imgMailFalse = ImgMailFalse;
    const imgNotifyTrue = ImgNotifyTrue;
    const imgNotifyFalse = ImgNotifyFalse;
    const Url = API_MEWS_URL;

    const CreditCardAproved = (statoId: SetStateAction<string>, idCustomer: SetStateAction<string>) => {
        const drawerRefACc: any = drawerApproveCreditcard.current;
        SetTypeUpdate(statoId);
        Set_myParam(idCustomer);
        switch (statoId) {
            case "1":
                SetStatoName("Carte de crédit");
                break;
            case "2":
                SetStatoName("Relancer client pour carte de crédit");
                break;
            case "3":
                SetStatoName("Reminder");
                break;
        }
        drawerRefACc.open();
    };

    const saveChanges = () => {
        switch (typeUpdate) {
            case "1":
                axios.put(Url + 'Customers/UpdateCreditCardCustomer/' + _myParam)
                    .then();
                break;
            case "2":
                axios.put(Url + 'Customers/UpdateIsSendMailCreditCardCustomer/' + _myParam)
                    .then();
                break;
            case "3":
                axios.put(Url + 'Customers/UpdateIsReminderCustomer/' + _myParam)
                    .then();
                break;
        }
        closeCreditCardAproved();
        props.reloadData();
    };

    const closeCreditCardAproved = () => {
        const drawerRefACc: any = drawerApproveCreditcard.current;
        drawerRefACc.close();
    }

    var Restaurant = false;  // Cambiar a false para ver el otro caso

    if (props.TypeClient == "Ristorante") {
        Restaurant = true;
    }

    const goToDetails = (_idCustomer: string, _idReservation: string) => {       
        localStorage.setItem('idCustomer',_idCustomer);
        localStorage.setItem('idReservation',_idReservation);
        localStorage.setItem('TypeClient','H');
        window.location.href = `/clientsDetails`;
    }

    const goToReservationDetails = (_idCustomer: string, _idReservation: string) => {       
        localStorage.setItem('idCustomer',_idCustomer);
        localStorage.setItem('idReservation',_idReservation);
        localStorage.setItem('TypeClient','R');
        window.location.href = `/ProfilClientsRistorantDetails`;
    }
    
    return (
        <div className='user_payment'>
            <h3 className='user_header'>Trombinoscope {props.TypeClient}</h3>
            <div className='user_content'>
                <div className="statoDiv">
                    <p>{props.Stato}</p>
                </div>

                {!Restaurant &&
                
                <>
                        {props.Reservation.filter((item) => {
                            return (props.search.toLowerCase() === '' ? item : item.customers.lastName.toLowerCase().includes(props.search)
                                || item.number.includes(props.search))
                        }).map((item) => (
                             <div className="user_element">
                                <div>
                                    <img className="PrimaryHeaderLogo" key={item.idCustomer} onClick={() => goToDetails(item.idCustomer,item.idReservation)} alt="" src={customerImg} />
                                    <p className='user_chambre'>Chamber {item.number}</p>
                                    <p className='user_info'>{item.customers.lastName ?? '---'} {item.customers.firstName ?? '---'} </p>
                                    <p className='user_info'> {format(item.startUtcDate, 'dd/MM/yyyy')} au {format(item.endUtcDate, 'dd/MM/yyyy')}  </p>
                                    <p className='user_nome'>VIP : {item.customers.classificationName ?? '---'}</p>
                                    <p className='user_info'>{item.state}</p>
                                    <p className='user_info'>Heure de arr :{format(item.startUtcDate, 'HH:mm:ss')}</p>
                                    <p className='user_info'>Heure de départ : {format(item.endUtcDate, 'HH:mm:ss')}</p>

                                    <div className="userDetails_detailsCc">
                                        {/* 
                                {item?.customers?.isPms &&
                                    <div>
                                        <div className="userDetails_CreditCard">
                                            {!item?.customers?.isCreditcard &&
                                                <div>
                                                    <a onClick={() => CreditCardAproved('1', item.idCustomer)}>
                                                        <img className="user_chambre" src={imgCcFalse} />
                                                    </a>
                                                </div>}
                                            {item?.customers?.isCreditcard &&
                                                <div>
                                                    <img className="user_chambre" src={imgCcTrue} />
                                                </div>}
                                        </div>
                                        <div className="userDetails_CreditCard">
                                            {!item?.customers?.isSendMailCreditCard &&
                                                <div>
                                                    <a onClick={() => CreditCardAproved('2', item.idCustomer)}>
                                                        <img className="user_chambre" src={imgMailFalse} />
                                                    </a>
                                                </div>}
                                            {item?.customers?.isSendMailCreditCard &&
                                                <div>
                                                    <img className="user_chambre" src={imgMailTrue} />
                                                </div>}
                                        </div>
                                        <div className="userDetails_CreditCard">
                                            {!item?.customers?.isReminder &&
                                                <div>
                                                    <a onClick={() => CreditCardAproved('3', item.idCustomer)}>
                                                        <img className="user_chambre" src={imgNotifyFalse} />
                                                    </a>
                                                </div>}
                                            {item?.customers?.isReminder &&
                                                <div>
                                                    <img className="user_chambre" src={imgNotifyTrue} />
                                                </div>}
                                        </div>
                                    </div>
                                }
                                {!item?.customers?.isPms &&
                                    <div>
                                        <div className="userDetails_CreditCard">
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                } */}
                                    </div>
                                </div>
                            </div>
                        ))}   

                        </>              
                    }

{Restaurant &&

<>

    {props.ReservationsRestau.filter((item) => {
        return (props.search.toLowerCase() === '' ? item : item.customer.lastName.toLowerCase().includes(props.search))
    }).map((item) => (
        <div className="user_element">
            <div>
                <img className="PrimaryHeaderLogo" key={item.customerId} onClick={() => goToReservationDetails(item.customerId,item.id)} alt="" src={customerImg} />
                <p className='user_chambre'>Restaurant</p>
                <p className='user_info'>{item.customer.lastName ?? '---'} {item.customer.firstName ?? '---'} </p>
                <p className='user_info'>Réservée le :</p>
                <p className='user_info'> {format(item.date, 'dd/MM/yyyy')} á {format(item.date, 'HH:mm:ss')}  </p>
                <p className='user_info'> {item.adultCount + item.childrenCount}  </p>

                <p className='user_nome'>VIP : {item.customer.classificationName ?? '---'}</p>
                {/* <p className='user_info'>{item.state}</p>
        <p className='user_info'>Heure de arr :{format(item.startUtcDate, 'HH:mm:ss')}</p>
        <p className='user_info'>Heure de départ : {format(item.endUtcDate, 'HH:mm:ss')}</p> */}

                <div className="userDetails_detailsCc">

                    {/* {item?.customer?.isPms && */}
                        <div>
                            <div className="userDetails_CreditCard">
                                {!item?.customer?.isCreditcard &&
                                    <div>
                                        <a onClick={() => CreditCardAproved('1', item.idCustomer)}>
                                            <img className="user_chambre" src={imgCcFalse} />
                                        </a>
                                    </div>}
                                {item?.customer?.isCreditcard &&
                                    <div>
                                        <img className="user_chambre" src={imgCcTrue} />
                                    </div>}
                            </div>
                            <div className="userDetails_CreditCard">
                                {!item?.customer?.isSendMailCreditCard &&
                                    <div>
                                        <a onClick={() => CreditCardAproved('2', item.idCustomer)}>
                                            <img className="user_chambre" src={imgMailFalse} />
                                        </a>
                                    </div>}
                                {item?.customer?.isSendMailCreditCard &&
                                    <div>
                                        <img className="user_chambre" src={imgMailTrue} />
                                    </div>}
                            </div>
                            <div className="userDetails_CreditCard">
                                {!item?.customer?.isReminder &&
                                    <div>
                                        <a onClick={() => CreditCardAproved('3', item.idCustomer)}>
                                            <img className="user_chambre" src={imgNotifyFalse} />
                                        </a>
                                    </div>}
                                {item?.customer?.isReminder &&
                                    <div>
                                        <img className="user_chambre" src={imgNotifyTrue} />
                                    </div>}
                            </div>
                        </div>
                    {/* // }
                    // {!item?.customer?.isPms && 
                    //     <div>
                    //         <div className="userDetails_CreditCard">
                    //             <div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // }*/}
                </div>
            </div>
        </div>
    ))}
</>
}
                

            </div>

            <GnzDrawer ref={drawerApproveCreditcard} component={
                <ProfilClientsCreditCardForm
                    myParam={_myParam ?? "0"}
                    statoName={statoName ?? ""}
                    closeForm={closeCreditCardAproved}
                    saveChanges={saveChanges}
                    reloadData={props.reloadData}
                />
            } />

        </div>
    );
}

export default ProfilClientsTable