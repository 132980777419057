import Reservation from '../../../models/Reservation';
import axiosInstance from './axiosInstance';

const getResaHotelByCustomer = async (customerId: number): Promise<Reservation[]> => {
    const response = await axiosInstance.get<Reservation[]>(`/Reservation/${customerId}`);
    return response.data;
};


export const reservationHotelService = {
    getResaHotelByCustomer,
};
