import TableModel from '../../../models/Table';
import axiosInstance from './axiosInstance';

const getRestaurantTables = async (id: number): Promise<TableModel[]> => {
    const response = await axiosInstance.get<TableModel[]>(`/Table/restaurant/${id}`);
    return response.data;
};

export const tableService = {
    getRestaurantTables,
};