import axiosInstance from './axiosInstance';
import Restaurant from '../../../models/Restaurant';

const getAllRestaurants = async (): Promise<Restaurant[]> => {
    const response = await axiosInstance.get<Restaurant[]>('/Restaurant/');
    return response.data;
};

const getRestaurantById = async (restaurantId: number): Promise<Restaurant> => {
    const response = await axiosInstance.get<Restaurant>(`/Restaurant/${restaurantId}`);
    return response.data;
};

export const restaurantService = {
    getAllRestaurants,
    getRestaurantById,
};
