import axiosInstance from './axiosInstance';
import Food_Preferences from '../../../models/Food_Preferences';
import FoodPreferencesReservation from '../../../models/FoodPreferencesReservation';
import FoodPreferencesCustomer from '../../../models/FoodPreferencesCustomer';

const getAllFoodPrefs = async (): Promise<Food_Preferences[]> => {
    const response = await axiosInstance.get<Food_Preferences[]>('/FoodPref/');
    return response.data;
};

const getFoodPrefReservation = async (reservationId: number): Promise<FoodPreferencesReservation[]> => {
    const response = await axiosInstance.get<FoodPreferencesReservation[]>(`/FoodPreferenceResa/ResaId=${reservationId}`);
    return response.data;
};

const getFoodPrefCustomer = async (customerId: number): Promise<FoodPreferencesCustomer[]> => {
    const response = await axiosInstance.get<FoodPreferencesCustomer[]>(`/FoodPreferenceCustomer/${customerId}`);
    return response.data;
};

export const foodPrefsService = {
    getAllFoodPrefs,
    getFoodPrefReservation,
    getFoodPrefCustomer
};