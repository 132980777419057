import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  SelectChangeEvent,
  Grid,
  Typography
} from "@mui/material";
import Menu from "../../../models/MenuModel";
import ReservationMenu from "../../../models/ReservationMenu";

interface IMenuSelect{
    menusList: Menu[],
    setMenusResaIds: React.Dispatch<React.SetStateAction<Number[]>>,
    menusResa: ReservationMenu[],
    totalPrice: number,
    setTotalPrice: React.Dispatch<React.SetStateAction<number>>,
    menusCount: Map<number, number>,
    setMenusCount: React.Dispatch<React.SetStateAction<Map<number, number>>>
}

export default function MenuSelect(props: IMenuSelect) {
  const [selectedOption, setSelectedOption] = useState<number | undefined>();
  const [totalPrice, setTotalPrice] = useState(0);
  const [menuResaIds, setMenusResaIds] = useState<Number[]>([])

  const menusLabel = new Map<number, string>();
  const menuPrices = new Map<number, number>();

  props.menusList.forEach((m) => {
    menusLabel.set(m.id, m.nom);
    menuPrices.set(m.id, m.prix);
  });

  useEffect(() => {
    if (props.menusResa && props.menusResa.length > 0) {
      const initialMenusCount = new Map<number, number>();
      let calculatedTotalPrice = 0;
      const ids: Number[] = [];
  
      // Parcourir les menus existants dans `props.menusResa` et compter leurs occurrences
      props.menusResa.forEach((menu) => {
        const existingMenu = initialMenusCount.get(menu.menuId) || 0;
        initialMenusCount.set(menu.menuId, existingMenu + 1);

        const menuData = props.menusList.find((m) => m.id === menu.menuId);
        if (menuData) {
          calculatedTotalPrice += menuData.prix;
          ids.push(menuData.id);
        }
      });
      
      // Mettre à jour `menusCount` avec les données initiales
      props.setMenusCount(initialMenusCount);
      setTotalPrice(calculatedTotalPrice);
      setMenusResaIds(ids);
    }
  }, []);

  useEffect(() => (
    props.setTotalPrice(totalPrice)
  ), [totalPrice])

  useEffect(() => (
    props.setMenusResaIds(menuResaIds)
  ), [menuResaIds])

  useEffect(() => (
    console.log('Menu Resa Ids : ', menuResaIds)
  ), [menuResaIds])



  const handleOptionChange = (event: SelectChangeEvent<{ value: number }>) => {
    const selectedValue = Number(event.target.value);
    setSelectedOption(selectedValue !== 0 ? selectedValue : undefined);
  };

  const handleAddMenu = () => {
    if (selectedOption !== undefined || selectedOption !== 0) {
      const selectedMenu = props.menusList.find((m) => m.id === selectedOption);
      if (selectedMenu) {
        setMenusResaIds((prevMenusResaIds) => [...prevMenusResaIds, selectedMenu.id]);

        props.setMenusCount((prevMenusCount) => {
          const updatedMenusCount = new Map(prevMenusCount);
          const menuCount = (updatedMenusCount.get(selectedMenu.id) || 0) + 1;
          updatedMenusCount.set(selectedMenu.id, menuCount);
          return updatedMenusCount;
        });
        const newTotalPrice = props.totalPrice + selectedMenu.prix;
        props.setTotalPrice(newTotalPrice);
        setSelectedOption(undefined);
      }
      setSelectedOption(undefined);
    }
  };

  const handleMenuDelete = (valueToDelete: number) => {
    // Trouver la première occurrence de l'ID dans `menuResaIds`
    setMenusResaIds((prevMenusResaIds) => {
      const updatedMenuIds = [...prevMenusResaIds]; // Copie de la liste
      const indexToRemove = updatedMenuIds.indexOf(valueToDelete); // Index du menu à supprimer
      if (indexToRemove > -1) {
        updatedMenuIds.splice(indexToRemove, 1); // Supprime une seule occurrence
      }
      return updatedMenuIds; // Mise à jour de l'état
    });
  
    // Gestion des occurrences dans `menusCount`
    props.setMenusCount((prevMenusCount) => {
      const updatedMenusCount = new Map(prevMenusCount);
      const menuCount = updatedMenusCount.get(valueToDelete) || 0;
  
      if (menuCount <= 1) {
        updatedMenusCount.delete(valueToDelete); // Supprime complètement si le menu n'existe plus
      } else {
        updatedMenusCount.set(valueToDelete, menuCount - 1); // Diminue de 1
      }
      return updatedMenusCount;
    });
  
    // Mise à jour du prix total
    const deletedMenu = props.menusList.find((menu) => menu.id === valueToDelete);
    if (deletedMenu) {
      props.setTotalPrice((prevTotal) => prevTotal - deletedMenu.prix);
    }
  };
  

  useEffect(() => {
    const newTotalPrice = props.menusResa.reduce((acc, menu) => {
      if (menuPrices.has(menu.id)) {
        return acc + menuPrices.get(menu.id)!;
      }
      return acc;
    }, 0);

    props.setTotalPrice(newTotalPrice);
  }, [props.menusResa]);

  return (

    <Grid container item alignItems="flex-end" columnSpacing={2}>
      <Grid container item alignItems="flex-end" columnSpacing={2}>
        <Grid item xs={12} md={9}>
          <Grid display="flex" flexDirection="row" justifyContent="space-between">
            <Typography className="input-title">Menu</Typography>
            <Grid item xs={6} md={6} display="flex" flexDirection="row">
              <Grid item xs={6} md={8} textAlign="right">
                <Typography className="input-title">Total actuel :</Typography>
              </Grid>
              <Grid item xs={6} md={4} justifyContent="flex-end" textAlign="right">
                <Typography className="input-title">{props.totalPrice}€</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Select
            size='small' 
            variant='outlined'
            id="special-request-select"
            value={selectedOption === undefined ? '' : selectedOption as any}
            onChange={handleOptionChange}
            style={{ minWidth: '200px' }}>
            {/* Ajoutez ici vos options du select */}
            {props.menusList.map((m, index )=>
              <MenuItem key={index} value={m.id as number}>{m.nom}</MenuItem>  
              )}
            {/* ... */}
          </Select>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button size="large" variant="outlined" onClick={handleAddMenu} fullWidth>Ajouter</Button>
        </Grid>
      </Grid>


      <Grid container item xs={12} md={9}>
        {Array.from(props.menusCount.entries()).map(([id, count], index) => (
          <Grid item xs={12} md={12} display="flex" flexDirection="row" alignItems="center" marginTop={1}>
            <Grid item xs={12} md={1}>
              <Typography>{count}x</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography>{props.menusList.find(m => m.id === id)?.nom} - {props.menusList.find(m => m.id === id)?.prix}€</Typography>
            </Grid>
            <Grid item xs={12} md={2} justifyItems="end">
              <Button size="small" variant="outlined" onClick={() => handleMenuDelete(id)} fullWidth>X</Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}