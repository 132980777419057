import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import '../RestaurantHotelDashboard.css';
import calendar from '../../../images/icones_restau/calendar.svg';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import Reservation from '../../../models/ReservationModel';
import Restaurant from '../../../models/Restaurant';
import React from 'react';
import ReservationStatus from '../../../models/ReservationStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import PrimaryHeader from '../../shared/PrimaryHeader/PrimaryHeader';
import axios from 'axios';
import { Table as TableType } from '../../restaurant/types/types';
import Service from '../../../models/ServiceModel';
import { reservationStatusService } from '../api/ReservationStatusService';
import { servicesRestauService } from '../api/ServicesRestauService';
import { reservationRestauService } from '../api/ReservationRestauService';
import { restaurantService } from '../api/RestaurantService';
import { tableService } from '../api/TableService';
import TableModel from '../../../models/Table';
import { count } from 'console';
import { Typography } from '@mui/material';

interface IRow {
  restaurant: Restaurant,
  reservations: Reservation[],
  currentDate: number
}

function Row(props: IRow) { 
  const navigate = useNavigate();
  const location = useLocation(); 
  const [remainingPlaces, setRemainingPlaces] = useState<Record<number, number>>({});
  const [state, setState] = useState({
    services: [] as Service[],
    reservationStatus: [] as ReservationStatus[],
    tables: [] as TableModel[],
    totalPlaces: 0,
    confirmCount: 0,
    pendingCount: 0,
    waitingListCount: 0,
    remainingPlaces: 0
  })

  useEffect(() => {
    fetchAllStatuses();
  }, [])

  useEffect(() => {
    fetchServiceByRestau();
    fetchTables();
  }, [props.restaurant])
 
  const fetchAllStatuses = async () => {
    const reservationStatuses = await reservationStatusService.getAllReservationStatus();
    setState((prevState) => ({ ...prevState, reservationStatus: reservationStatuses}))
  }
  
  const fetchServiceByRestau = async () => {
    if (props.restaurant.id){
      const services = await servicesRestauService.getServiceByRestaurant(Number(props.restaurant.id));
      setState((prevState) => ({ ...prevState, services: services}))
    }
  }

  const fetchTables = async () => {
    const tables = await tableService.getRestaurantTables(Number(props.restaurant.id));
    var count = 0;
    tables.forEach(table => {
      count += table.nbPersonne
    });
    setState((prevState) => ({ ...prevState, tables: tables, totalPlaces: count}))
  }

  useEffect(() => {
    const fetchRemainingPlaces = async () => {
      const newRemainingPlaces: Record<number, number> = {};
  
      for (const service of state.services) {
        const count = await getServicePlacesRemaining(service);

        newRemainingPlaces[service.id] = count;
      }
      setRemainingPlaces(newRemainingPlaces);
    };
    
    fetchRemainingPlaces();
  }, [state.services, props.reservations]);

  const getServicePlacesRemaining = async (service: Service) => {
    const date = new Date(props.currentDate).toISOString().split('T')[0];
    const openingTime = service.heureDebut;
    const [openingHours, openingMinutes] = (openingTime.split(':'));
    const closingTime = service.heureFin;
    const [closingHours, closingMinutes] = (closingTime.split(':'));
    var count = 0;
    const filteredResa = props.reservations.filter(resa => {
      
      const resaDate = new Date(resa.date);
      const resaHours = resaDate.getHours();
      const resaMinutes = resaDate.getMinutes();
      
      return (
        (resaDate.toISOString().split('T')[0] === date) &&
        ((resaHours > Number(openingHours) && resaHours < Number(closingHours)) ||
        (resaHours === Number(openingHours) && resaMinutes >= Number(openingMinutes)) ||
        (resaHours === Number(closingHours) && resaMinutes <= Number(closingMinutes))) &&
        resa.reservationStatus.state !== "confirmed"
      ) 
    })
    if (filteredResa.length !== 0) {
    const availableTables = state.tables.filter((table) => 
      !filteredResa.some((reservation: Reservation) => reservation.table?.id === table.id)
    );

    availableTables.map((table: TableModel) => count += table.nbPersonne ? table.nbPersonne : 0)
    }else{
      count = state.totalPlaces
    }
    return count
  }
  
  useEffect(() => {
    const statusCount = {
      confirmCount: 0,
      pendingCount: 0,
      waitingListCount: 0
    };
    
    props.reservations.forEach((reservation: Reservation) => {
      if (reservation.restaurantId === Number(props.restaurant.id)) {
        const status = state.reservationStatus.find(s => s.id === reservation.reservationStatusId);
        if (status) {
          switch (status.state) {
            case 'confirmed':
            statusCount.confirmCount++;
            break;
            case 'pending':
            statusCount.pendingCount++;
            break;
            case 'waiting_list':
            statusCount.waitingListCount++;
            break;
            default:
            break;
          }
        }
      }
    });
    
    setState(prevState => ({
      ...prevState,
      confirmCount: statusCount.confirmCount,
      pendingCount: statusCount.pendingCount,
      waitingListCount: statusCount.waitingListCount
    }));
  }, [props.reservations, state.reservationStatus, props.restaurant.id]);
  
  
  return (
    <React.Fragment>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}>
    {props.restaurant.nom}
    </TableCell>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}> 
    {state.confirmCount}         
    </TableCell>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}>
    {state.pendingCount}
    </TableCell>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}>
    {state.waitingListCount}
    </TableCell>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}>
      {Object.values(
    state.services
      // Trie par type de service (lunch, diner, nonstop) en premier
      .sort((a, b) => {
        const typeOrder = ['lunch', 'diner', 'nonstop'];
        return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
      })
      // Puis trie par heure de début dans chaque type
      .sort((a, b) => {
        const [aHours, aMinutes] = a.heureDebut.split(':').map(Number);
        const [bHours, bMinutes] = b.heureDebut.split(':').map(Number);
        return aHours === bHours ? aMinutes - bMinutes : aHours - bHours;
      })
      // Regroupe les services par type pour numéroter les services
      .reduce((acc, service) => {
        if (!acc[service.type]) acc[service.type] = [];
        acc[service.type].push(service);
        return acc;
      }, {} as Record<string, Service[]>)
  ).flatMap((services) =>
    services.map((service, index) => {
      const formattedStart = service.heureDebut.slice(0, 5);
      const formattedEnd = service.heureFin.slice(0, 5);
      var serviceNumber = "";
      if (service.type !== "nonstop"){
        serviceNumber = index === 0 ? "1er service" : "2e service";
      }else {
        serviceNumber = "Service unique";
      }
      return (
        <p key={service.id}>
          {serviceNumber} ({formattedStart} - {formattedEnd}) : {remainingPlaces[service.id] ?? 'Calcul...'}/{state.totalPlaces}
        </p>
      );
    })
  )}
        </TableCell> 
    
        <TableCell 
  className="details_link" 
  style={{ 
    width: '10%',
    verticalAlign: 'middle',
    padding: 0, 
    height: '100%',
  }}
>
<div style={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1, // 💡 Permet à la div de remplir toute la cellule
    minHeight: '100%', // 💡 Empêche la div de se réduire à la taille du contenu
  }}>
    {Array.from(new Set(state.services.map((s: Service) => s.type)))
      .sort((a, b) => {
        const order = ['lunch', 'diner'];
        return order.indexOf(a) - order.indexOf(b);
      })
      .map((type, index, array) => {
        let label;
        switch (type) {
          case 'nonstop':
          label = 'Service continu';
          break;
          case 'lunch':
          label = 'Déjeuner';
          break;
          case 'diner':
          label = 'Dîner';
          break;
          case 'breakfast':
          break;
        }
        return (
          <Typography 
            key={type}
            style={{ 
              flex: 1, // 💡 Chaque élément prend une part égale de la hauteur
              minHeight: 0, // 💡 Évite que le contenu force la hauteur globale
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              borderBottom: index !== array.length - 1 ? '1px solid #ccc' : 'none', 
            }}
            onClick={() => 
            navigate(`${location.pathname}/services`, { 
              state: { serviceTypeParam: type, restaurantIdParam: props.restaurant.id } 
            })
          }>
          {label}
          </Typography>
        );
      })
    }    
    </div>
    </TableCell>
    </TableRow>
    
    </React.Fragment>
  );
}

export default function RestaurantHotel() {
  const { currentDate } = useSelector((state: RootState) => state.app); 
  const cd = moment(currentDate).startOf('day').format('YYYY-MM-DD');
  const [state, setState] = useState({
    reservations: [] as Reservation[],
    restaurants: [] as Restaurant[],
  })   

  useEffect(() => {
    fetchAllRestaurants();
  }, [])

  useEffect(() => {
    fetchAllReservations();
  }, [cd, currentDate])
  
  const fetchAllReservations = async () => {
    const reservations = await reservationRestauService.getAllReservationRestau();
    var filteredReservations = (reservations.filter((r: Reservation) => {
      const reservationDate = moment(r.date).startOf('day').format('YYYY-MM-DD');
      return reservationDate === cd;
    }));
    setState((prevState) => ({ ...prevState, reservations: filteredReservations}))
  }
  
  const fetchAllRestaurants = async () => {
    const restaurants = await restaurantService.getAllRestaurants();
    setState((prevState) => ({ ...prevState, restaurants: restaurants}))
  }

  return (
    <div className='ReservationTable'>
    <PrimaryHeader src={calendar} title="Réservations du jour" />
    <TableContainer component={Paper} className="EstablishementTableContainer">   
    <Table aria-label="collapsible table">
    <TableHead>
    <TableRow>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>Etablissement</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>Confirmées</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>En attente</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>Waiting List</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>Places restantes</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '10%'
    }}/>
    </TableRow>
    </TableHead>
    <TableBody>
    {state.restaurants.map((restaurant, key) => ( 
      <Row key={key} restaurant={restaurant} reservations={state.reservations} currentDate={currentDate}/>
    ))} 
    </TableBody>
    </Table>
    </TableContainer>
    </div>
  );
}
