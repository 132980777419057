import Billing from "../../../models/Billing";
import axiosInstance from "./axiosInstance";

const getBillingById = async (id: number): Promise<Billing> => {
    const response = await axiosInstance.get<Billing>(`/Billing/${id}`);
    return response.data;
};

export const billingService = {
    getBillingById
}