import './App.css';

import { Route, Routes } from 'react-router-dom';

import Layout from './components/shared/layout/Layout';

import SignIn from './components/sign-in/SignIn';
import SignUp from './components/sign-up/SignUp';
import ForgotPassword from './components/forgot-password/ForgotPassword';

import To from './components/to/To';
import Caisse from './components/caisse/Caisse';
import Dashboard from './components/dashboard/Dashboard';
import Rooming from './components/rooming/Rooming';
import Consignes from './components/consignes/Consignes';
import NoMatch from './components/no-match/NoMatch';
import Checklist from './components/check-list/Checklist';
import Voiturier from './components/voiturier/Voiturier';
import EspacePrivee from './components/espace-privee/EspacePrivee';
import Whatsapp from './components/whatsapp/Whatsapp';

import Welcome from './components/welcome/Welcome';
import Organigram from './components/organigram/Organigram';
import Fiche from "./components/fiche/fiche";
import List from "./components/liste/ListeEmployee";
import Calendar from "./components/planning/calendrier";
import Absence from "./components/absence/Absence";
import Demande from "./components/demande/Demande";
import ListDemande from "./components/demande/listDemande";
import ListAbsence from "./components/absence/listAbsence";
import Cantine from "./components/cantine/cantine";
import RestauDetails from './components/restaurant/restau/RestauDetails';
import AddRestau from './components/restaurant/restau/addRestau';
import ReservationRestau from './components/restaurant/restau/reservation/ReservationRestau';
import ProfilClients from './components/ProfilClients/ProfilClients';
import ProfilClientsDetails from './components/ProfilClients/ProfilClienteDetails/ProfilClientsDetails';
import ProfilClientsRistorantDetails from './components/ProfilClients/ProfilClienteDetails/ProfilClientsRistorantDetails';
import ProfilClientPreferences from './components/ProfilClients/ProfilClientPreferences/ProfilClientPreferences';
import ItineraireClients from './components/ProfilClients/ItineraireClients/ItineraireClients';
import Feedback from './components/Feedback/Feedback';
import FeedbackClient from './components/Feedback/FeedbackClient';

// import RestaurantClients from './components/RestaurantClients/RestaurantClients'
import RestaurantHotelDashboard from './components/RestaurantHotel/RestaurantHotelDashboard';
// import ServicesRestaurant from './components/RestaurantHotel/Restaurant/ServicesRestaurant';
import BonCadeau from './components/RestaurantHotel/Paiement/BonCadeau/BonCadeau';
import CarteCredit from './components/RestaurantHotel/Paiement/CarteCredit/CarteCredit';
// Transfer import 
import ListProvider from './components/Provider/ListProvider';
import AddProvider from './components/Provider/AddProvider';
import UpdateProvider from './components/Provider/UpdateProvider';
import AddTransfer from './components/Transfer/AddTransfer';
import ListTransfer from './components/Transfer/ListTransfer';
import UpdateTransfer from './components/Transfer/UpdateTransfer';
import ServicesRestaurant from './components/RestaurantHotel/Restaurant/ServicesRestaurant';
import MssAdmin from './components/mss-admin/MssAdmin';
import Breakfast from './components/Breakfast/components/Breakfast';

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/to" element={<To />} />
        <Route path="/caisse" element={<Caisse />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/rooming" element={<Rooming />} />
        <Route path="/consignes" element={<Consignes />} />
        <Route path="/check-list" element={<Checklist />} />
        <Route path="/voiturier" element={<Voiturier />} />
        <Route path="/espace-prive" element={<EspacePrivee />} />
        <Route path="/whatsapp" element={<Whatsapp />} />

        <Route path="/welcome" element={<Welcome />} />
        <Route path="/organigram" element={<Organigram />} />
        <Route path="/fiche/:id" element={<Fiche />} />
        <Route path="/list" element={<List />} />
        <Route path="/planning" element={<Calendar />} />
        <Route path="/absence/:id" element={<Absence />} />
        <Route path="/list-absence" element={<ListAbsence />} />
        <Route path="/cantine" element={<Cantine />} />
        <Route path="/list-demande" element={<ListDemande />} />
        <Route path="/demande/:id" element={<Demande />} />

        {/* Restau section */}
        {/* /:id */}
        <Route path="/restaurant-hotel/" element={<RestaurantHotelDashboard />} />
        <Route path="/restaurant-hotel/services" element={<ServicesRestaurant />} />
        <Route path="/bons-cadeaux" element={<BonCadeau />} />
        <Route path="/carte-credit" element={<CarteCredit />} />
        <Route path="/breakfast" element={<Breakfast />} />

        <Route path="/add-restaurant" element={<AddRestau />} />
        <Route path="/restaurantTest/:id" element={<RestauDetails />} />
        {/*<Route path="/reservationTest" element={<ReservationRestau />} />*/}
        <Route path="/profilClients" element={<ProfilClients />} />
        <Route path="/clientsDetails" element={<ProfilClientsDetails />} />
        <Route path="/ProfilClientsRistorantDetails" element={<ProfilClientsRistorantDetails />} />
        <Route path="/clientsPreferences" element={<ProfilClientPreferences />} />        
        <Route path="/clientsItineraire" element={<ItineraireClients />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/feedbackClient" element={<FeedbackClient />} />

        <Route path="/admin" element={<MssAdmin />} />

        <Route path="*" element={<NoMatch />} />
        {/* Transfer routes */}
        {/* <Route path="/addProvider" element={< AddProvider />} />   */}
        <Route path="/ListProvider" element={< ListProvider />} />  
        {/* <Route path="/UpdateProvider/:id" element={<UpdateProvider/>}/> */}
        {/* <Route path="/AddTransfer" element={<AddTransfer/>}/> */}
        <Route path='/ListTransfer' element={<ListTransfer/>} />
        {/* <Route path='/UpdateTransfer/:id' element={<UpdateTransfer/>}/> */}
      </Route>
    </Routes>
  );
}

export default App;
