import './Paiement.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import check_card from '../../../images/icones_restau/check_card.png';
import waiting_card from '../../../images/icones_restau/waiting_card.png';
import gift_card from '../../../images/icones_restau/gift_card.png';

function Paiement() {
    const navigate = useNavigate();
    const [cards] = useState(['Cartes de crédit confirmées', 'Bons Cadeaux', 'Cartes de crédit en attente'])
    const statuts = [
        {label: "confirmed"},
        {label: "pending"}
    ]

    return (
        <div className='booking_payment'>
            <h3 className='payment_header'>Encaissement Réservation</h3>
            <div className='payment_content'>
                <div className="card_element" key={cards[0]} onClick={() => navigate(`/carte-credit?carte=${statuts[0].label}`)}>
                    <img src={check_card} alt={cards[0]} className="card_image" />
                    <div className="card_label">{cards[0]}</div>
                </div>
                <div className="card_element" key={cards[1]} onClick={() => navigate('/bons-cadeaux')}>
                    <img src={gift_card} alt={cards[1]} className="card_image" />
                    <div className="card_label">{cards[1]}</div>
                </div>
                <div className="card_element" key={cards[2]} onClick={() => navigate(`/carte-credit?carte=${statuts[1].label}`)}>
                    <img src={waiting_card} alt={cards[2]} className="card_image" />
                    <div className="card_label">{cards[2]}</div>
                </div>

            </div>
        </div>
    );
}

export default Paiement;