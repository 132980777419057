import axiosInstance from './axiosInstance';
import Employee from '../../../models/Employee';

const getEmployeeWaitersFB = async (): Promise<Employee[]> => {
    const response = await axiosInstance.get<Employee[]>('/Employee/WaitersFB/');
    return response.data;
};

export const waiterService = {
    getEmployeeWaitersFB,
};