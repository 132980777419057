import axiosInstance from "./axiosInstance";
import Reservation from "../../../models/ReservationModel";
import Customer from "../../../models/Customers";
import Menu from "../../../models/MenuModel";
import Billing from "../../../models/Billing";
import AddResaAndCustomerRequest from "../../../models/AddResaAndCustomer";

const getAllReservationRestau = async () =>{
  const response = await axiosInstance.get(`/ResaRestau/isBreakfast=${false}`)
  return response.data;
}

const addResaAndCustomer = async (customer: Customer, reservation: Reservation, allergieResaIds: number[], foodPrefResaIds: number[], allergiesCustIds: number[], foodPrefCustIds: number[], menus: Menu[]): Promise<AddResaAndCustomerRequest> => {
  const response = await axiosInstance.post(`/ResaRestau/AddResaAndCustomer`, 
    { customer: customer, 
      reservation: reservation, 
      allergiesResaIds: allergieResaIds, 
      foodPrefResaIds: foodPrefResaIds,
      allergiesCustIds: allergiesCustIds,
      foodPrefCustIds: foodPrefCustIds,
      menus: menus,
    },
      { headers: { "Content-Type": "application/json" }}
    );
    return response.data;
  };
  
const updateResaAndCustomer = async (customer: Customer, reservation: Reservation, allergieResaIds: number[], foodPrefResaIds: number[], allergiesCustIds: number[], foodPrefCustIds: number[], menus: Menu[]): Promise<AddResaAndCustomerRequest> => {
  const response = await axiosInstance.post(`/ResaRestau/UpdateResaAndCustomer`, 
    { customer: customer, 
      reservation: reservation, 
      allergiesResaIds: allergieResaIds, 
      foodPrefResaIds: foodPrefResaIds,
      allergiesCustIds: allergiesCustIds,
      foodPrefCustIds: foodPrefCustIds,
      menus: menus,
    },
      { headers: { "Content-Type": "application/json" }}
    );
    return response.data;
  };
  
  const getResaByRestauAndService = async (restaurantId: number, start: string, end: string) => {
    const response = await axiosInstance.get(`/ResaRestau/restaurantId=${restaurantId}&start=${start}&end=${end}&isBreakfast=${false}`);
    return response.data;
  };
    
const getResaByRestauAndDate = async (restaurantId: number, date: string) => {
  const response = await axiosInstance.get(`/ResaRestau/restaurantId=${restaurantId}&date=${date}&isBreakfast=${false}`);
  return response.data;
} 

const getBreakfastByRestauAndDate = async (restaurantId: number, date: string) => {
  const response = await axiosInstance.get(`/ResaRestau/restaurantId=${restaurantId}&date=${date}&isBreakfast=${true}`);
  return response.data;
} 

const getBreakfastByRestauAndService = async (restaurantId: number, start: string, end: string) => {
  const response = await axiosInstance.get(`/ResaRestau/restaurantId=${restaurantId}&start=${start}&end=${end}&isBreakfast=${false}`);
  return response.data;
};

export const reservationRestauService = {
  getAllReservationRestau,
  addResaAndCustomer,
  updateResaAndCustomer,
  getResaByRestauAndService,
  getResaByRestauAndDate,
  getBreakfastByRestauAndDate,
  getBreakfastByRestauAndService
};