import axiosInstance from './axiosInstance';
import Customer from '../../../models/Customers';

const getAllCustomers = async (): Promise<Customer[]> => {
    const response = await axiosInstance.get<Customer[]>('/Customers/');
    return response.data;
};

const getCustomerById = async (customerId: number): Promise<Customer> => {
    const response = await axiosInstance.get<Customer>(`/Customers/${customerId}`);
    return response.data;
};

export const customersService = {
    getAllCustomers,
    getCustomerById
};
