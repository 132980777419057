import axiosInstance from './axiosInstance';
import Menu from '../../../models/MenuModel';
import ReservationMenu from '../../../models/ReservationMenu';

const getMenusByRestaurant = async (restaurantId: number): Promise<Menu> => {
    const response = await axiosInstance.get<Menu>(`/Menu/restaurantId=${restaurantId}`);
    return response.data;
};

const getReservationMenusByReservation = async (reservationId: number): Promise<ReservationMenu[]> => {
    const response = await axiosInstance.get<ReservationMenu[]>(`/ReservationMenu/reservationId=${reservationId}`)
    return response.data;
}

export const menuService = {
    getMenusByRestaurant,
    getReservationMenusByReservation
};
