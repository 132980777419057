import CustomerAddresss from "./CustomerAddresss";

export default class Customer {
    public idCustomer?: number = 0;
    public title: string = '';
    public sex: string = '';
    public number: string = '';
    public bedroom: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public classificationName: string = '';
    public accompanist: string = '';
    public nationality: string = '';
    public arrivalDate: string = '';
    public departureDate: string = '';
    public createdUtc: string = '';
    public departureTime: string = '';
    public customerAddresss: CustomerAddresss = new CustomerAddresss();  
    public birthDate: string = '';
    public postalCode: string = '';
    public nationalityCode: string = '';
    public city: string = '';
    public phone: string = '';
    public phone2: string = '';
    public email: string = '';
    public email2: string = '';  
    public languageCode: string = '';
    public importantDate: string = '';
    public arrivalTime: string = '';
    public vipCode: string = '';
    public isPms: string = '';
    public isCreditcard: string = '';
    public isSendMailCreditCard: string = '';
    public isReminder: string = '';

    public Id: string = '';
    public Notes: string = '';
    public Number: string = '';
    public ChainId: string = '';
    public Options: string[] = [];
    public AddressId: string = '';
    public CompanyId: string = '';
    public BirthPlace: string = '';
    public Occupation: string = '';
    public UpdatedUtc: string = '';
    public BillingCode: string = '';
    public LoyaltyCode: string = '';
    public ActivityState: string = '';
    public MergeTargetId: string = '';
    public AccountingCode: string = '';
    public SecondLastName: string = '';
    public Classifications: string[] = [];
    public TaxIdentificationNumber: string = '';

    constructor(
        idCustomer: number = 0,
        title: string = '',
        sex: string = '',
        number: string = '',
        bedroom: string = '',
        firstName: string = '',
        lastName: string = '',
        classificationName: string = '',
        accompanist: string = '',
        nationality: string = '',
        arrivalDate: string = '',
        departureDate: string = '',
        createdUtc: string = '',
        departureTime: string = '',
        customerAddresss: CustomerAddresss = new CustomerAddresss(),       
        birthDate: string = '',
        postalCode: string = '',
        nationalityCode: string = '',
        city: string = '',
        phone: string = '',
        phone2: string = '',
        email: string = '',
        email2: string = '',       
        languageCode: string = '',
        importantDate: string = '',        
        arrivalTime: string = '',  
        
        Id: string = '',
        Notes: string = '',
        Number: string = '',
        ChainId: string = '',
        Options: string[] = [],
        AddressId: string = '',
        CompanyId: string = '',
        BirthPlace: string = '',
        Occupation: string = '',
        UpdatedUtc: string = '',
        BillingCode: string = '',
        LoyaltyCode: string = '',
        ActivityState: string = '',
        MergeTargetId: string = '',
        AccountingCode: string = '',
        SecondLastName: string = '',
        Classifications: string[] = [],
        TaxIdentificationNumber: string = '',
        vipCode: string = '', 
        isPms: string = '',     
        isCreditcard: string = '',     
        isSendMailCreditCard: string = '',     
        isReminder: string = '',       

        
    ) {
        this.idCustomer = idCustomer;
        this.title = title;
        this.sex = sex;
        this.number = number;
        this.bedroom = bedroom;
        this.firstName = firstName;
        this.lastName = lastName;   
        this.classificationName = classificationName;  
        this.accompanist = accompanist;   
        this.nationality = nationality;  
        this.arrivalDate = arrivalDate;
        this.departureDate = departureDate;
        this.createdUtc = createdUtc;  
        this.departureTime = departureTime;   
        this.customerAddresss = customerAddresss;
        this.birthDate = birthDate;
        this.customerAddresss = customerAddresss;
        this.postalCode = postalCode;
        this.nationalityCode = nationalityCode;
        this.city = city;
        this.phone = phone;
        this.phone2 = phone2;
        this.email = email;
        this.email2 = email2;
        this.languageCode = languageCode;
        this.importantDate = importantDate;
        this.arrivalTime = arrivalTime;
        this.vipCode = vipCode;

        this.Id = Id;
        this.Notes = Notes;
        this.Number = Number;
        this.ChainId = ChainId;
        this.Options = Options;
        this.AddressId = AddressId;
        this.CompanyId = CompanyId;
        this.BirthPlace = BirthPlace;
        this.Occupation = Occupation;
        this.UpdatedUtc = UpdatedUtc;
        this.BillingCode = BillingCode;
        this.LoyaltyCode = LoyaltyCode;
        this.ActivityState = ActivityState;
        this.MergeTargetId = MergeTargetId;
        this.AccountingCode = AccountingCode;
        this.SecondLastName = SecondLastName;
        this.Classifications = Classifications;
        this.TaxIdentificationNumber = TaxIdentificationNumber;
        this.isPms = isPms;
        this.isCreditcard = isCreditcard;
        this.isSendMailCreditCard = isSendMailCreditCard;
        this.isReminder = isReminder;
        
    }
}