import './CarteCredit.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import CarteCreditFilters from './CarteCreditFilters';
import CreateCardForm from './CreateCardForm';
import CancelReservationForm from './CancelReservationForm';
import UpdateCardForm from './UpdateCardForm';
import GnzButton from '../../../shared/GnzButton/GnzButton';
import GnzImage from '../../../shared/GnzImage/GnzImage';
import plus2 from '../../../../images/shared/plus2.png';
import GnzDrawer from '../../../shared/GnzDrawer/GnzDrawer';
import { CardStatus, CardType, CreditCard, Customer, Reservation, Service } from './CarteCreditInterfaces';
// import PrimaryBreadcrumb from '../../../../shared/PrimaryBreadcrumb/PrimaryBreadcrumb';


interface Statut{
    statut: string;
}
interface Client{
    nom: string;
    prenom: string
}
interface TypeCarte {
    type: string;
}
interface TypeDebit {
    type: string;
}

function CarteCredit() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const carteParam = queryParams.get("carte");

    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const [filterService, setFilterService] = useState("");
    const [filterStatut, setFilterStatut] = useState(carteParam || "");;
    const [filterTypeCarte, setFilterTypeCarte] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [sortOption, setSortOption] = useState("default");
    const [showCardForm, setShowCardForm] = useState(false);
    const [showCardFormUpdate, setShowCardFormUpdate] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [showCancelResa, setShowCancelResa] = useState(false);
    const [dateExpiration, setDateExpiration] = useState<Date | null>(null);
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [telephone, setTelephone] = useState('');
    const [email, setEmail] = useState('');
    const [adresse, setAdresse] = useState('');
    const [cp, setCp] = useState('');
    const [ville, setVille] = useState('');

    const [creditCardsList, setCreditCards] = useState<CreditCard[]>([]);
    const [customersList, setCustomers] = useState<Customer[]>([]);
    const [cardTypesList, setCardTypes] = useState<CardType[]>([]);
    const [cardStatusesList, setCardStatuses] = useState<CardStatus[]>([]);
    const [reservationsList, setReservations] = useState<Reservation[]>([]);
    const [servicesList, setServices] = useState<Service[]>([]);
    const drawerRef = useRef<HTMLDivElement>(null);

    const types_debit: TypeDebit[] = [
        {type: "Débitée pour non présentation"},
        {type: "Débitée pour garantie"},
        {type: "Annulation sans frais"}
    ]

    const handleResetDateExpiration = () => {
        setDateExpiration(null);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const customerData = {
            lastname: nom,
            firstname: prenom,
            phone: telephone,
            email,
            address: adresse,
            zipcode: cp,
            city: ville
        };
        const cardData = {

        };
    
       axios.post('http://localhost:9000/add-customer', customerData)
        .then((response) => {
            // Réinitialiser les états après la soumission
            setNom('');
            setPrenom('');
            setTelephone('');
            setEmail('');
            setAdresse('');
            setCp('');
            setVille('');
        })
        .catch(error => {
            console.error('Erreur lors de l\'ajout du destinataire :', error);
        });

        axios.post('http://localhost:9000/add-card', cardData)
        .then((response) => {
            // Réinitialiser les états après la soumission

        })
        .catch(error => {
            console.error('Erreur lors de l\'ajout du destinataire :', error);
        });
    };

    useEffect(() =>{
        axios
            .get('http://localhost:9000/get-reservations')
            .then((response) => {
                const reservations: Reservation[] = response.data;
                if (reservations) {
                    setReservations(reservations);
                    const customerIds = reservations.map((reservation) => reservation.customerId);                    
                    axios
                        .get(`http://localhost:9000/get-credit-cards`)
                        .then((cardsResponse) => {
                            const cardsData: CreditCard[] = cardsResponse.data;
                            setCreditCards(cardsData);
                        })
                        .catch((error) => {
                            console.error('Erreur lors de la récupération des données des cartes :', error);
                        });

                    axios
                        .get(`http://localhost:9000/get-card-types`)
                        .then((cardTypeResponses) => { 
                            const cardTypeData: CardType[] = cardTypeResponses.data;
                            setCardTypes(cardTypeData);
                        })
                        .catch((error) => {
                            console.error('Erreur lors de la récupération des données des types :', error);
                        });

                    Promise.all(
                        customerIds.map((customerId) =>
                        axios.get(`http://localhost:9000/get-customer/${customerId}`)
                        )
                    )
                        .then((customerResponses) => {
                            const customerData = customerResponses.map((response) => response.data);     
                            setCustomers(customerData);
                        })
                        .catch((error) => {
                            console.error('Erreur lors de la récupération des données des clients :', error);
                        });   

                    axios
                        .get(`http://localhost:9000/get-card-statuses`)
                        .then((cardStatusResponses) => {
                            const statusData: CardStatus[] = cardStatusResponses.data;
                            setCardStatuses(statusData);
                        })
                        .catch((error) => {
                            console.error('Erreur lors de la récupération des données des status :', error);
                        });

                    axios
                        .get(`http://localhost:9000/get-services`)
                        .then((serviceResponse) => {
                            const servicesData: Service[] = serviceResponse.data;
                            setServices(servicesData);
                        })
                        .catch((error) => {
                            console.error('Erreur lors de la récupération des données des services :', error);
                        });
                } else {
                    console.log('PAS DE CARTES');
                };
            })
            .catch((error) => {
                console.error('Erreur CLIENT lors de la récupération de la liste des cartes :', error);
            });
    }, []);

    const sortedCards = reservationsList.slice().sort((a, b) => {
        if (sortOption === "date") {
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();

            return dateA - dateB;
        } 
        else if (sortOption === "client") {
            const customerA = customersList.find((customer) => customer.id === a.customerId);
            const customerB = customersList.find((customer) => customer.id === b.customerId);
            const lastNameA = customerA?.lastname ?? "";
            const lastNameB = customerB?.lastname ?? "";

            return lastNameA.localeCompare(lastNameB);
        }
        return 0;
    });

    const newCreditCard = () => {
        openDrawer();
      };
      
      const openDrawer = () => {
        const drawerRefEl: any = drawerRef.current;
        drawerRefEl.open();
      }
      
      const closeDrawer = () => {
        const drawerRefEl: any = drawerRef.current;
        drawerRefEl.close();
      }

    return (
        <div>
        <div className='cartes_table'>
        <div className="PrimaryHeader">
        <div className="PrimaryHeaderTitle">
            {/* <PrimaryBreadcrumb breadcrumbs={['Restaurant Hotel', filterStatut === "confirmed" ? "Cartes confirmées" : "Cartes en attente"]}/> */}
        </div>
            <div className="PrimaryHeaderSeparator"></div>
        </div>
            <GnzButton leftIcon={<GnzImage src={plus2} />} label="Nouvelle carte" onClick={newCreditCard} color="#003671" backgroundColor="#95E2AF" />
            <CarteCreditFilters
            filterService={filterService}
            setFilterService={setFilterService}
            filterStatut={filterStatut}
            setFilterStatut={setFilterStatut}
            filterTypeCarte={filterTypeCarte}
            setFilterTypeCarte={setFilterTypeCarte}
            sortOption={sortOption}
            setSortOption={setSortOption}
            serviceList={servicesList}
            cardTypeList={cardTypesList}
            statusCardList={cardStatusesList}
            />
            <h3 className='cartes_header'>Cartes enregistrées</h3>
            <table>
                <thead>                
                </thead>
                <tbody>
                    <tr>
                        <td>Date</td>
                        <td>Service</td>
                        <td>Client</td>
                        <td>Type de carte</td>
                    </tr>
                    {sortedCards
                    .filter(resa => {
                        const type = cardTypesList.find((cardType) => cardType.id === resa.customerId);
                        const status = cardStatusesList.find((cardStatus) => cardStatus.id === resa.cardStatusId);
                        const service = servicesList.find((service) => service.id === resa.serviceId)
                        return (
                        (!startDate || new Date(resa.date) >= startDate) &&
                        (!endDate || new Date(resa.date) <= new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1)) &&
                        (filterService === "" || (service?.label.toLowerCase().includes(filterService.toLowerCase()))) &&
                        (filterStatut === "Confirmed" ? (status?.status.toLowerCase().includes(filterStatut.toLowerCase())) : (status?.status.toLowerCase().includes(filterStatut.toLowerCase()))) &&
                        (filterTypeCarte === "" || (type?.type.toLowerCase().includes(filterTypeCarte.toLowerCase())))
                    )}
                    )
                    .map((resa, index) => {
                    const customer = customersList.find((customer) => customer.id === resa.customerId);
                    const card = creditCardsList.find((card) => card.customerId === resa.customerId)
                    const cardType = cardTypesList.find((cardType) => cardType.id === card?.cardTypeId);
                    const cardStatus = cardStatusesList.find((cardStatus) => cardStatus.id === resa.cardStatusId);
                    const service = servicesList.find((service) => service.id === resa?.serviceId )

                    return(
                    <React.Fragment key={index}>
                    <tr className={selectedRowIndex === index ? 'selected-row' : ''} onClick={() => setSelectedRowIndex(selectedRowIndex === index ? null : index)}>
                        <td className='table_data'>{new Date(resa.date).toLocaleDateString() }</td>
                        <td className='table_data'>{service?.name}</td>
                        <td className='table_data'>{customer?.lastname.toUpperCase()} {customer?.firstname}</td>
                        { cardType ?
                        (<td className='table_data'>{cardType.type}</td>)
                        :
                        (<td className='table_data'>-</td>)
                        }
                    </tr>
                    {selectedRowIndex === index && (
                        <Fragment>
                        <tr className="details">
                            <td colSpan={1}>Numero</td>
                            {card ? (
                            <td colSpan={3}>{card.cardNumber}</td>
                            ) : (
                            <td colSpan={3}>-</td>
                            )
                            }
                        </tr>
                        <tr className="details">
                            <td colSpan={1}>Date Expiration</td>
                            <td colSpan={3}>
                                { card ? new Date(card.expirationDate).getMonth() < 10 ? (
                                    <p>0{ new Date(card.expirationDate).getMonth()}/{ new Date(card.expirationDate).getFullYear().toString().slice(-2)}</p>
                                ) : (
                                    <p>{ new Date(card.expirationDate).getMonth()}/{ new Date(card.expirationDate).getFullYear().toString().slice(-2)}</p>
                                ) : 
                                    <p>-</p>}
                            </td>
                        </tr>
                        <tr className="details">
                            <td colSpan={1}>Cryptogramme</td>
                            {card ? (
                            <td colSpan={3}>{card.crypto}</td>
                            ) : (
                            <td colSpan={3}>-</td>
                            )
                            }
                        </tr>
                        <tr className="details">
                            <td colSpan={1}>Réservation pour</td>
                            <td colSpan={3}>{resa?.nbPerson}</td>
                        </tr>
                        <tr className="details">
                            <td colSpan={1}>Statut Carte</td>
                            <td colSpan={3}>{cardStatus?.status.toLowerCase() === "confirmed" ? "Carte confirmée" : "Carte en attente"}</td>
                        </tr>
                        <tr className="details">
                            <td colSpan={4}>                            
                            {cardStatus?.status.toLowerCase() === "confirmed" ?
                            <div>
                                <button className="create-button" onClick={() => setShowCardFormUpdate(true)}>Modifier carte</button> 
                                <button className="create-button" onClick={() => setShowCard(true)}>Voir Carte</button>
                            </div>
                            :
                            <div>
                                <button className="create-button">Relancer par email</button>
                                <button className="create-button">Relancer par SMS</button>
                            </div>}
                            <button className="create-button" onClick={() => setShowCancelResa(true)}>Annuler réservation</button>
                            </td>
                        </tr>
                        {showCard && (
                            <div className="show-carte-overlay">
                                <div className='carte'>
                                <h3>Carte de {customer?.lastname.toUpperCase()} {customer?.firstname}</h3>
                                <div className="show-carte-container">
                                    <p>{card?.cardNumber}</p>
                                    { card ? new Date(card.expirationDate).getMonth() < 10 ? (
                                        <p>0{ new Date(card.expirationDate).getMonth()}/{ new Date(card.expirationDate).getFullYear().toString().slice(-2)}</p>
                                    ) : (
                                        <p>{ new Date(card.expirationDate).getMonth()}/{ new Date(card.expirationDate).getFullYear().toString().slice(-2)}</p>
                                    ) : 
                                        ""}
                                    <p>{card?.crypto}</p>
                                </div>
                                </div>
                                <button className="close-button" onClick={() => setShowCard(false)}>X</button>
                            </div>
                        )}
                        {showCardFormUpdate && (
                            <UpdateCardForm 
                                servicesList = {servicesList}
                                cardTypesList = {cardTypesList}
                                cardStatusesList = {cardStatusesList}
                                handleResetDateExpiration = {handleResetDateExpiration}
                                handleSubmit = {handleSubmit}
                                nom = {nom}
                                setNom = {setNom}
                                prenom = {prenom}
                                setPrenom = {setPrenom}
                                email = {email}
                                setEmail = {setEmail}
                                telephone = {telephone}
                                setTelephone = {setTelephone}
                                dateExpiration = {dateExpiration}
                                setDateExpiration = {setDateExpiration}
                                setShowCardFormUpdate = {setShowCardFormUpdate}
                            />
                        )}
                        </Fragment>
                        )}
                    </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
            <GnzDrawer ref={drawerRef} component={
                <CreateCardForm
                    customersList={customersList}
                    servicesList={servicesList}
                    reservationsList={reservationsList}
                    cardTypesList={cardTypesList}
                    cardStatusesList={cardStatusesList}
                    showCardForm={showCardForm}
                    setShowCardForm={setShowCardForm}
                    handleResetDateExpiration={handleResetDateExpiration}
                    handleSubmit={handleSubmit}
                    nom={nom}
                    setNom={setNom}
                    prenom={prenom}
                    setPrenom={setPrenom}
                    email={email}
                    setEmail={setEmail}
                    telephone={telephone}
                    setTelephone={setTelephone}
                    dateExpiration={dateExpiration}
                    setDateExpiration={setDateExpiration}
                    closeForm={closeDrawer}
                />
          } />
            {showCancelResa && (
                <CancelReservationForm
                    setShowCancelResa = {setShowCancelResa}
                />
            )}
        </div>
    </div>
    );
}

export default CarteCredit;