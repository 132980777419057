import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import FormMode from '../../../../enums/FormMode';
import GnzDrawer from '../../../shared/gnz-drawer/GnzDrawer';
import HistoriClientsTable from '../StaysClientsTable/HistoriClientsTable';
import ProfilClientsCarousel from '../ProfilClientsCarousel/ProfilClientsCarousel';
import ProfilClientsTagsForm from '../ProfilClientsDetailsForm/ProfilClientsTagsForm'
import ProfilClientsEditForm from '../ProfilClientsDetailsForm/ProfilClientsEditForm'
import ProfilClientsCreditCardForm from '../ProfilClientsDetailsForm/ProfilClientsCreditCardForm'
import Customers from '../../../../models/Customers';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import ReservationsRestau from '../../../../models/ReservationsRestau';
import ImgMailTrue from '../../../../images/ProfilClients_img/mailT.png'
import ImgMailFalse from '../../../../images/ProfilClients_img/mailF.png'
import TagIcon from '../../../../images/ProfilClients_img/Vector.png'
import axios from 'axios';
import { API_MEWS_URL }   from '../../../../ConfigUrl';
import './ProfilClientsDetailsTable.css';


interface UserCivilite {
  CiviliteTxt: string;
  MandameTxt: string;
}

interface UserCreditcard {
  statoId: any;
  statoName: any;
  statoCreditCard: any;
  ImgStato: any;
}

function ProfilClientsRistorantDetailsTable() {
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const drawerprofilClientsEdit = useRef<HTMLDivElement>(null);
  const drawerTags = useRef<HTMLDivElement>(null);
  const drawerApproveCreditcard = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const id = localStorage.getItem('idReservation');
  const idCustomer = localStorage.getItem('idCustomer');
  const [typeUpdate, SetTypeUpdate] = useState('');
  const [statoName, SetStatoName] = useState('');
  const [CustomerDetails, SetCustomerDetails] = useState<Customers>();
  const [allergiesCustomer, AllergiesCustomer] = useState<Array<AllergiesCustomer>>([]);
  const [foodPreferencesCustomer, FoodPreferencesCustomer] = useState<Array<FoodPreferencesCustomer>>([]);
  const [reservation, SetReservationsRestau] = useState<ReservationsRestau>();
  const imgMailTrue = ImgMailTrue;
  const imgMailFalse = ImgMailFalse; 
  const tagIcon = TagIcon;
  const [urlProfile, setUrlProfile] = useState('');  
  
  const Url = API_MEWS_URL;
 
  
  useEffect(() => {
    localStorage.setItem('TypeProfilDetails',"ProfilClientsRistorantDetails"); 
    setUrlProfile("ProfilClientsRistorantDetails");    
  }, [])

  useEffect(() => {
    axios.get(Url + 'ResaRestau/' + id)
      .then(res => SetReservationsRestau(res.data)); 
  }, [])

  useEffect(() => { 
    axios.get(Url + 'AllergiesCustomer/' + idCustomer)
      .then(res => AllergiesCustomer(res.data));     
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPreferenceCustomer/' + idCustomer)
      .then(res => FoodPreferencesCustomer(res.data));
  }, [])  


  
  const Civilite: UserCivilite[] = [

    { CiviliteTxt: "Civilité", MandameTxt: reservation?.customers?.sex ?? "------" },
    { CiviliteTxt: "Nom", MandameTxt: reservation?.customers?.firstName ?? "------" },
    { CiviliteTxt: "Prénom", MandameTxt: reservation?.customers?.lastName ?? "------" },
    { CiviliteTxt: "Date de naissance", MandameTxt: reservation?.customers?.birthDate ?? "------" },
    { CiviliteTxt: "Adresse", MandameTxt: reservation?.customers?.customerAddresss?.line1 ?? "------" },
    { CiviliteTxt: "Code postal", MandameTxt: reservation?.customers?.nationalityCode ?? "------" },
    { CiviliteTxt: "Ville", MandameTxt: reservation?.customers?.customerAddresss?.city ?? "------" },
    { CiviliteTxt: "N° de téléphone", MandameTxt: reservation?.customers?.phone ?? "------" },
    { CiviliteTxt: "Email", MandameTxt: reservation?.customers?.email ?? "------" },
    { CiviliteTxt: "Nationalité", MandameTxt: reservation?.customers?.nationalityCode ?? "------" },
    { CiviliteTxt: "Langue(s)", MandameTxt: reservation?.customers?.languageCode ?? "------" },
    { CiviliteTxt: "Date importante", MandameTxt: reservation?.customers?.importantDate ?? "------" }
  ];
  
  const CreditCard: UserCreditcard[] = [
    { statoId: "1", statoName: "Carte de crédit", statoCreditCard: reservation?.customers?.isCreditcard ?? false, ImgStato: reservation?.customers?.isCreditcard ? imgMailTrue : imgMailFalse },
    { statoId: "2", statoName: "Relancer client pour carte de crédit", statoCreditCard: reservation?.customers?.isSendMailCreditCard ?? false, ImgStato: reservation?.customers?.isSendMailCreditCard ? imgMailTrue : imgMailFalse },
    { statoId: "3", statoName: "Reminder", statoCreditCard: reservation?.customers?.isReminder ?? false, ImgStato: reservation?.customers?.isReminder ? imgMailTrue : imgMailFalse },
  ];

  const CreditCardAproved = (statoId: SetStateAction<string>) => {
    const drawerRefACc: any = drawerApproveCreditcard.current;
    SetTypeUpdate(statoId);
    switch (statoId) {
      case "1":
        SetStatoName("Carte de crédit");
        break;
      case "2":
        SetStatoName("Relancer client pour carte de crédit");
        break;
      case "3":
        SetStatoName("Reminder");
        break;
    }
    drawerRefACc.open();
  };

  const saveChanges = async () => {

var ruta = "";

    switch (typeUpdate) {
      case "1":     
        ruta = 'UpdateCreditCardCustomer';        
        break;
      case "2":
        ruta = 'UpdateIsSendMailCreditCardCustomer'; 
        break;
      case "3":
        ruta = 'UpdateIsReminderCustomer';        
        break;
    }     
    try {
      const response = await axios.put(Url + 'Customers/'+ ruta +'/' + idCustomer)
      .then(); 
    } catch (error) {
      console.error('Hubo un error:', error);
    } finally {
      closeCreditCardAproved();
      window.location.href = `/ProfilClientsRistorantDetails`;
    }
  };  

  const closeCreditCardAproved = () => {
    const drawerRefACc: any = drawerApproveCreditcard.current;
    drawerRefACc.close();
  }

  const ClientTagEdit = () => {
    setMode(FormMode.New);
    openTagEdit();
  };

  const openTagEdit = () => {
    const drawerRefEl: any = drawerTags.current;
    drawerRefEl.open();
  }

  const closeTagEdit = () => {
    const drawerRefEl: any = drawerTags.current;
    drawerRefEl.close();
  }

  const profilClientsEdit = () => {
    SetCustomerDetails(reservation?.customers)
    setMode(FormMode.New);
    openEditClientForm();
  };

  const openEditClientForm = () => {
    const drawerRefEl: any = drawerprofilClientsEdit.current;
    drawerRefEl.open();
  }

  const closeEditClientForm = () => {
    const drawerRefEl: any = drawerprofilClientsEdit.current;
    drawerRefEl.close();
  }


  const GotoRute = (event: any) => {
    switch (event.target.value) {
      case "1":
        window.location.href = `/`+ urlProfile;
        break;
      case "2":
        window.location.href = `/FeedbackClient`;
        break;
      case "3":
        window.location.href = `/clientsPreferences`;
        break;      
    }
  }

  return (
    <div className='userDetails_payment'>
      <h3 className='userDetails_header'>Fiche Client Restaurant</h3>
      <div className='userDetails_content'>
        <div className="ProfilClientsDetailsTopActions">
        <select className='selectSyle' onChange={GotoRute}>
            <option value="1">Profil</option>
            <option value="2" onClick={() => navigate(`/FeedbackClient`)} onSelect={() => navigate(`/FeedbackClient`)}>Feedback</option>
            <option value="3" onClick={() => navigate(`/clientsPreferences`)} onSelect={() => navigate(`/clientsPreferences`)}>Préférences</option>
            {/* <option value={5}>Email</option> */}
            {/* <option value={6}>Whatsapp</option> */}
          </select>
          {/* <button className="btnstyle">Partager ficher client</button> */}
          <h4 className='ProfilClientsDetailsTopActionsh3'>Prochain séjour:</h4>

          <div className='selectSyleDiv'>

            <input className="DataDetailsSyle" placeholder='Du'></input>
            <input className="DataDetailsSyle" placeholder='Au'></input>
          </div>

        </div>
        <div className="userDetails_info">
          <div className="userDetails_photo">
            <ProfilClientsCarousel />
          </div>
          <div className="userDetails_details">
            {Civilite.map((r) => (
              <div className="userDetails_element">
                <a onClick={profilClientsEdit}>
                  <p className='user_chambre'>{r.CiviliteTxt}</p>
                  <p className='user_nome'>{r.MandameTxt}</p>
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="moreContent">
          <div className='TagsLine'>
                <div className='TagsName' onClick={ClientTagEdit}>
                <a className="">  <img alt="" src={tagIcon} ></img> TAG </a>
                </div>
            {allergiesCustomer.map((r) => (
              //  <p>{r.allergies.displayAs}</p>
               <img className="TagImg" src={`data:image/jpeg;base64,${r.allergies.image}`} alt="" />    
              // <img className="TagLogo" src={`data:image/jpeg;base64,${r.tags.tagImage}`} alt="" />
            ))}
            {foodPreferencesCustomer.map((r) => (
               <img className="TagImg" src={`data:image/jpeg;base64,${r.food_Preferences.image}`} alt="" />
            ))}
          </div>
        </div>


        <div className="userDetails_detailsCc">
          {CreditCard.map((r) => (
            <div className="userDetails_CreditCard">
              {!r.statoCreditCard &&
                <div>
                  <a onClick={() => CreditCardAproved(r.statoId)}> <p className='user_statoName'>{r.statoName}  <img src={r.ImgStato}></img></p></a>
                </div>}
              {r.statoCreditCard &&
                <div>
                  <p className='user_statoName'>{r.statoName}  <img src={r.ImgStato}></img></p>
                </div>}
            </div>
          ))}
        </div>

        <GnzDrawer ref={drawerTags} component={
          <ProfilClientsTagsForm
            myParam={idCustomer ?? "0"}
            allergiesCustomer={allergiesCustomer}
            foodPreferencesCustomer={foodPreferencesCustomer}
            closeForm={closeTagEdit}
          />
        } />

        <GnzDrawer ref={drawerprofilClientsEdit} component={
          <ProfilClientsEditForm
            myParam={idCustomer ?? "0"}
            idReservation={id ?? "0"}
            CustomerDetails={CustomerDetails}
            closeForm={closeEditClientForm}
          />
        } />

        <GnzDrawer ref={drawerApproveCreditcard} component={
          <ProfilClientsCreditCardForm
            myParam={idCustomer ?? "0"}
            statoName={statoName ?? ""}
            closeForm={closeCreditCardAproved}
            saveChanges={saveChanges}
            reloadData={saveChanges}
          />
        } />

        <div className="moreContent">
          <div className="">
            <h3>Historiques</h3>
            <HistoriClientsTable />
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  );
}

export default ProfilClientsRistorantDetailsTable