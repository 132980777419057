import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import Service from '../../../../models/ServiceModel';

interface ServiceTypeFilterProps {
    filterService: string, 
    onFilterChange: (type: string) => void;
    isContinuous: boolean,
}

function ServiceTypeFilter(props: ServiceTypeFilterProps) {   
    return (         
        <select 
            value={props.filterService} 
            className="filter-input" 
            onChange={e => props.onFilterChange(e.target.value)}
            disabled={props.isContinuous}>
          <option value={"lunch"}>Déjeuner</option>
          <option value={"diner"}>Dîner</option>
        </select>
        

    );
}

export default ServiceTypeFilter;