const enum EventType {
    None = 0,
    Pending = 1,
    Confirmed = 2,
    Seated = 3,
    PartiallySeated = 4,
    Gone = 5,
    WaitingList = 6,
    WaitingCB = 7,
    NoShow = 8,
    Cancel = 9
}

export default EventType;