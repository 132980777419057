export default class ReservationStatus {
    public id: number = 0;
    public state: string = '';
    public statusLabel: string = '';

    constructor(
        id: number = 0,
        state: string = '', 
        statusLabel: string = '',        
    )
    {
        this.state = state;
        this.statusLabel = statusLabel;
        this.id = id;
    }
}