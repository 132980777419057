import React, { useEffect, useState } from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import FoodPreferencesReservation from "../../../models/FoodPreferencesReservation";
import Food_Preferences from "../../../models/Food_Preferences";
import FoodPreferencesCustomer from "../../../models/FoodPreferencesCustomer";

interface IFoodPrefSelect{
    foodPref: FoodPreferencesReservation[] | FoodPreferencesCustomer[],
    foodPrefList: Food_Preferences[],
    setFoodPrefIds: React.Dispatch<React.SetStateAction<Number[]>>,
    foodPrefType: string,
}

export default function FoodPrefSelect(props: IFoodPrefSelect) {
  const [label_foodPref, setLabelFoodPref] = useState<Record<number, string>>({});
  const [foodPrefIds, setFoodPrefIds] = useState<Number[]>([])

  // useEffect(() => (
  //     props.setFoodPrefIds(foodPrefIds)
  //   ), [foodPrefIds])

  useEffect(() => {
    const labelMap: Record<number, string> = {};
    const ids: Number[] = [];
    props.foodPrefList.forEach((f) => {
        labelMap[f.id] = f.displayAs;
    });

    props.foodPref.forEach((f) => {
      const foodPref = props.foodPrefList.find(fp => fp.id === Number(f.food_PreferencesId));
      if (foodPref) {
        ids.push(foodPref.id);
      }
    });
  
    setLabelFoodPref(labelMap);
    setFoodPrefIds(ids);
    props.setFoodPrefIds(ids);
  }, []);
  

  return (
    <Select
      size="small"
      variant='outlined'
      multiple
      value={foodPrefIds}
      onChange={(e) => {
        const newValues = Array.isArray(e.target.value) ? e.target.value : [];
        setFoodPrefIds(newValues);
        props.setFoodPrefIds(newValues);
      }}
      renderValue={(selectedPref) => (
        <Stack gap={1} direction="row" flexWrap="wrap">
          {selectedPref.map((value: Number) => (
            value !== 0 ?
            <Chip
              key={value.toString()}
              label={label_foodPref[Number(value)]}
              onDelete={() => {
                const updatedIds = foodPrefIds.filter((item) => item !== value);
                props.setFoodPrefIds(updatedIds);
                setFoodPrefIds(updatedIds);
              }}
              deleteIcon={
                <CancelIcon
                  onMouseDown={(event) => event.stopPropagation()}
                />
              }
            /> : null
          ))}
        </Stack>
      )}
    >
      {props.foodPrefList ? (
      props.foodPrefList.map((foodPref) => (
        <MenuItem key={foodPref.id} value={foodPref.id}>
          {foodPref.displayAs}
        </MenuItem>
      ))
    ) : null}
    </Select>
  );
}