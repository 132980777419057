import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import GnzDatePicker from '../../../shared/GnzDatePicker/GnzDatePicker';
import { DatePickerProps } from 'antd/es/date-picker';
import { decrementDate, incrementDate, setDate } from '../../../../redux/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

interface BonCadeauFiltersProps {
  filterBon: string;
  setFilterBon: React.Dispatch<React.SetStateAction<string>>;
  filterService: string;
  setFilterService: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  sortOption: string;
  setSortOption: React.Dispatch<React.SetStateAction<string>>;
}

interface Service {
  label: string;
}

// ...

function BonCadeauFilters(props: BonCadeauFiltersProps) {
    const [gnzStartDatePickerKey, setGnzStartDatePickerKey] = useState(0);
    const [gnzEndDatePickerKey, setGnzEndDatePickerKey] = useState(0);
    const dispatch = useDispatch();
    const service: Service[] = [
      { label: "Restaurant" },
      { label: "Bar" },
      { label: "Spa" }
    ]
  
    const { currentDate: appCurrentStartDate } = useSelector((state: RootState) => state.app);
    const { currentDate: appCurrentEndDate } = useSelector((state: RootState) => state.app);
  
    // Utilisez des états locaux distincts pour stocker currentStartDate et currentEndDate
    const [currentStartDate, setCurrentStartDate] = useState(appCurrentStartDate);
    const [currentEndDate, setCurrentEndDate] = useState(appCurrentEndDate);
  
    const handleResetStartDate = () => {
      props.setStartDate(null);
      setCurrentStartDate(Date.now());
      setGnzStartDatePickerKey(prevKey => prevKey + 1);
    };
    
    const handleResetEndDate = () => {
      props.setEndDate(null);
      setCurrentEndDate(Date.now());
      setGnzEndDatePickerKey(prevKey => prevKey + 1);
    };
  
    const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      props.setSortOption(e.target.value);
    };
  
    const onChangeAppCurrentStartDate: DatePickerProps['onChange'] = (date: any) => {
      date.$d = new Date(`${date.$d.getFullYear()}-${date.$d.getMonth() + 1}-${date.$d.getDate()}`)
      date.$d.setHours(2);
      props.setStartDate(date);
      setCurrentStartDate(date.$d.getTime()); // Mettez à jour currentStartDate ici
    }
    const onChangeAppCurrentEndDate: DatePickerProps['onChange'] = (date: any) => {
      date.$d = new Date(`${date.$d.getFullYear()}-${date.$d.getMonth() + 1}-${date.$d.getDate()}`)
      date.$d.setHours(2);
      props.setEndDate(date);
      setCurrentEndDate(date.$d.getTime()); // Mettez à jour currentEndDate ici
    }
  
    useEffect(() => {
      setCurrentStartDate(appCurrentStartDate);
    }, [appCurrentStartDate]);
  
    useEffect(() => {
      setCurrentEndDate(appCurrentEndDate);
    }, [appCurrentEndDate]);
  
    return (
      <div className='bons_filters'>
        <input
          type="text"
          placeholder="Numéro bon"
          value={props.filterBon}
          onChange={e => props.setFilterBon(e.target.value)}
          className="search-input"
        />
        <select
          value={props.filterService}
          onChange={e => props.setFilterService(e.target.value)}
          className="filter-input"
        >
          <option value="">Tous les statuts</option>
          {service.map((b, index) => (
            <option key={index} value={b.label}>{b.label}</option>
          ))}
          {/* Ajoutez d'autres options de statut ici */}
        </select>
        <div className='date-filters'>
            <div className='date-filter'>
                    <GnzDatePicker className="PrimaryDatePicker" currentDate={currentStartDate} onChange={onChangeAppCurrentStartDate} key={`gnzStartDatePicker-${gnzStartDatePickerKey}`} />
                    <button onClick={handleResetStartDate}>X</button>
            </div>
            <div className='date-filter'>
                    <GnzDatePicker className="PrimaryDatePicker" currentDate={currentEndDate} onChange={onChangeAppCurrentEndDate} key={`gnzEndDatePicker-${gnzEndDatePickerKey}`} />
                    <button onClick={handleResetEndDate}>X</button>
            </div>        
        </div>
        <select
          value={props.sortOption}
          onChange={handleSortChange} // Ajout de l'événement onChange
          className="filter-input"
        >
          <option value="default">Trier par</option>
          <option value="date">Date achat</option>
          <option value="destinataire">Destinataire</option>
        </select>
      </div>
    );
  }
  
  export default BonCadeauFilters;
  