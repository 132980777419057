export default class Billing {
    public id: number = 0;
    public paymentTypeId: number = 0;
    public amount: number = 0

    constructor(
        id: number = 0,
        paymentTypeId: number = 0,
        amount: number = 0
    )
    {
        this.id = id;
        this.paymentTypeId = paymentTypeId;
        this.amount = amount;
    }
}
