import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Service, CardType, CardStatus } from './CarteCreditInterfaces';

interface UpdateCardFormInterface{
    servicesList: Service[];
    cardTypesList: CardType[];
    cardStatusesList: CardStatus[];
    handleResetDateExpiration: () => void;
    handleSubmit: (e: React.FormEvent) => void;
    nom: string;
    setNom: React.Dispatch<React.SetStateAction<string>>;
    prenom: string;
    setPrenom: React.Dispatch<React.SetStateAction<string>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    telephone: string;
    setTelephone: React.Dispatch<React.SetStateAction<string>>;
    dateExpiration: Date | null;
    setDateExpiration: React.Dispatch<React.SetStateAction<Date | null>>;
    setShowCardFormUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

function UpdateCardForm(props: UpdateCardFormInterface){
    
    const cardUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setNom(e.target.value);
        props.setPrenom(e.target.value);
        props.setTelephone(e.target.value);

    };

    return (
        <div className="cartes-form-overlay">
            <div className="cartes-form-container">
                <form onSubmit={props.handleSubmit}>
                    {/* Ajoutez votre formulaire ici */}
                    <h3>Update Carte de Crédit</h3>
                    <hr/>
                    <input
                        type="text"
                        placeholder="Nom Client"
                        value={props.nom}
                        onChange={(e) => props.setNom(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Prénom Client"
                        value={props.prenom}
                        onChange={(e) => props.setPrenom(e.target.value)}
                    />
                    <input
                        type="text"
                        value={props.telephone}
                        placeholder="Téléphone"
                        onChange={(e) => props.setTelephone(e.target.value)}
                    />
                    <input
                        type="email"
                        value={props.email}
                        placeholder="Email"
                        onChange={(e) => props.setEmail(e.target.value)}
                    />
                    <select
                        className="filter-input"
                    >
                        {props.servicesList.map((s, index) => (
                            <option key={index} value="service label">{s.label}</option>
                        ))}
                    </select>
                    <hr/>
                    <h4>Caractéristiques Carte</h4>
                    <select
                        className="filter-input"
                    >
                        {props.cardTypesList.map((t, index) => (
                            <option key={index} value={t.type}>{t.type}</option>
                        ))}
                        {/* Ajoutez d'autres options de statut ici */}
                    </select>  
                    <input
                        type="text"
                        placeholder="Numéro de la carte"
                    /> 
                    <div>  
                    <DatePicker
                        placeholderText='Date Expiration'
                        value={props.dateExpiration ? props.dateExpiration.toLocaleDateString() : ''}
                        selected={props.dateExpiration}
                        onChange={(date: Date | null) => {
                            if (date) {
                                props.setDateExpiration(date);
                            }
                        }}
                    />
                    <button onClick={props.handleResetDateExpiration}>X</button>
                    </div>
                    <input 
                        type='number'
                        placeholder='Cryptogramme'
                    />                
                    <input
                        type="text"
                        placeholder="Nom exact du détenteur"
                    />
                    <select
                        className="filter-input"
                    >
                        {props.cardStatusesList.map((s, index) => (
                            <option key={index} value={s.status}>{s.status}</option>
                        ))}
                    </select>
                    {/* <select
                        className="filter-input"
                    >
                        {props.types_debit.map((t, index) => (
                            <option key={index} value={t.type}>{t.type}</option>
                        ))}
                    </select> */}
                    <hr/>
                    <button className="close-button" type="submit">Valider</button>
                </form>
            </div>
        </div>
    )
}

export default UpdateCardForm;