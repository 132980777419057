import { useEffect, useRef, useState } from 'react';
import { DatePicker, TimePicker, TimePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import GnzButton from '../../../shared/GnzButton/GnzButton';
import GnzDatePicker from '../../../shared/GnzDatePicker/GnzDatePicker';
import CustomNumberInput from '../../../shared/CustomInputs/NumberInput';
import AllergySelect from '../../../shared/CustomInputs/AllergySelect';
import FoodPrefsSelect from '../../../shared/CustomInputs/FoodPrefsSelect';
import MenuSelect from '../../../shared/CustomInputs/MenusSelect';

import Customer from '../../../../models/Customers';
import Menu from '../../../../models/MenuModel';
import Reservation from '../../../../models/ReservationModel';
import ReservationEvent from '../../../../models/ReservationEventModel';

import Allergy from '../../../../enums/ReservationEnums/ReservationAllergy';
import FoodPreference from '../../../../enums/ReservationEnums/FoodPreference';
import SpecialOccasion from '../../../../models/SpecialOccasion';
// import ReservationStatus from '../../../../enums/ReservationEnums/ReservationStatus';
import FormMode from '../../../../enums/FormMode';

import moment from 'moment';
import EventType from '../../../../enums/ReservationEnums/ReservationEventType';
import CustomerReservationForm from './CustomerReservationForm';
// import { Restaurant } from '../../../restaurant/types/types';
import Restaurant from '../../../../models/Restaurant';
import Waiter from '../../../../models/Employee';
import axios from 'axios';
import { capitalize, FormControl, InputLabel, Select, Button, Grid, Typography, CardContent, MenuItem, TextField } from '@mui/material';
import Allergies from '../../../../models/Allergies';
import Food_Preferences from '../../../../models/Food_Preferences';
import { cp } from 'fs';
import AllergiesReservation from '../../../../models/AllergiesReservation';
import FoodPreferencesReservation from '../../../../models/FoodPreferencesReservation';
import CustomerActions from '../../../../models/CustomersActions';
import ReservationStatus from '../../../../models/ReservationStatus';
import PaymentType from '../../../../models/PaymentType';
import Billing from '../../../../models/Billing';
import { billingService } from '../../api/BillingService';
import ReservationMenu from '../../../../models/ReservationMenu';
import GnzTimePicker from '../../../shared/gnz-time-picker/GnzTimePicker';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';

import './ReservationForm.css';
import TableModel from '../../../../models/Table';
import Service from '../../../../models/ServiceModel';
import { servicesRestauService } from '../../api/ServicesRestauService';
import { reservationRestauService } from '../../api/ReservationRestauService';

interface IReservationForm {
  mode: FormMode,
  menus: Menu[],
  currentDate: number,
  title: string,
  closeForm: () => void,
  // submit: (customers: Array<Customer>, reservations: Array<Reservation>) => void
  submit: (customer: Customer, reservation: Reservation, allergiesResaId: Number[], foodPrefResaIds: Number[], allergiesCustId: Number[], foodPrefCustIds: Number[], menusResaIds: Number[]) => void
  submitLabel: string,
  submitColor: string,
  customers: Customer[],
  restaurantsList: Restaurant[],
  restaurant?: Restaurant
  waiterList: Waiter[],
  formCustomer: Customer,
  formReservation: Reservation,
  foodPrefs: Food_Preferences[],
  allergies: Allergies[],
  specialOccasions: SpecialOccasion[],
  allergiesResa: AllergiesReservation[],
  foodPrefResa: FoodPreferencesReservation[],
  allergiesCust: AllergiesCustomer[],
  foodPrefCust: FoodPreferencesCustomer[],
  menusResa: ReservationMenu[],
  menuList: Menu[],
  customerActionsList: CustomerActions[],
  resaStatusList: ReservationStatus[],
  paymentTypeList: PaymentType[],
  currentReservations: Reservation[],
  currentServices: Service[],
  tables: TableModel[]
}

function ReservationForm(props: IReservationForm) {
  const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';
  const [dateResa, setDateResa] = useState(new Date());
  const [nbAdults, setNbAdults] = useState(0);
  const [nbChildren, setNbChildren] = useState(0);  
  const [nbVisits, setNbVisits] = useState(0);
  const [gratuite, setGratuite] = useState(0);
  const [customerId, setCustomerId] = useState(0);
  const [timestamp, setTimestamp] = useState(0);
  const [langage, setLangage] = useState(''); 
  const [price, setPrice] = useState(0); 
  const [specialRequest, setSpecialRequest] = useState('');
  const [specialRequests, setSpecialRequests] = useState<string[]>([]);
  const [waiterId, setWaiterId] = useState(0);
  const [establishmentWaiters, setEstablishmentWaiters] = useState<Array<Waiter>>([]);

  const [history, setHistory] = useState('');

  // const [status, setStatus] = useState(ReservationStatus.None);
  const [reservationStatusId, setReservationStatusId] = useState(0);
  // const [facturation, setFacturation] = useState(Facturation.None);
  // const [specialOccasion, setSpecialOccasion] = useState(SpecialOccasion.None);

  const [currentCustomer, setCurrentCustomer] = useState<Customer>(new Customer());
  const [menusResaIds, setMenusResaIds] = useState<Number[]>([]);
  
  const [allergiesResaIds, setAllergiesResaIds] =  useState<Number[]>([]);
  const [allergiesCustIds, setAllergiesCustIds] =  useState<Number[]>([]); 
  const [foodPrefsResaIds, setFoodPrefsResaIds] = useState<Number[]>([]);
  const [foodPrefsCustIds, setFoodPrefsCustIds] = useState<Number[]>([]);
  
  const [allergiesGrp, setAllergiesGrp] = useState<Array<Allergy>>([]); 
  const [foodPrefsGrp, setFoodPrefsGrp] = useState<FoodPreference[]>([]);
  const [events, setEvents] = useState<Array<ReservationEvent>>([]);
  const [establishments, setEstablishments] = useState<Restaurant[]>([]);
  const [currentEstablishmentId, setCurrentEstablishmentId] = useState<number>();
  const [table, setTable] = useState(0);
  const [menusList, setMenusList] = useState<Array<Menu>>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [menusCount, setMenusCount] = useState<Map<number, number>>(new Map());

  const [formCustomer, setFormCustomer] = useState<Customer>(new Customer());
  const [formReservation, setFormReservation] = useState<Reservation>(new Reservation());
  const [billing, setBilling] = useState<Billing>(new Billing())
  const resaStatusCancelOption = props.resaStatusList.find(r => r.state === 'cancel')
  const [remainingTables, setRemainingTables] = useState<Record<string, TableModel[]>>({})
  const [restaurantId, setRestaurantId] = useState<number>(0)
  const [services, setServices] = useState<Service[]>([])
  const [reservations, setReservations] = useState<Reservation[]>([])
  const [serviceTypeList, setServiceTypeList] = useState<string[]>([])
  const [selectedServiceType, setSelectedServiceType] = useState<string>('');
  const [tableKey, setTableKey] = useState<string>('')


  useEffect(() => {
    if (formReservation && formReservation.date) {
      const reservationDate = new Date(formReservation.date);
  
      // Trouver le service correspondant
      const matchedService = services.find(service => {
        const debut = new Date(`${reservationDate.toISOString().split('T')[0]}T${service.heureDebut}`);
        const fin = new Date(`${reservationDate.toISOString().split('T')[0]}T${service.heureFin}`);
  
        return reservationDate >= debut && reservationDate <= fin;
      });
  
      if (matchedService) {
        setSelectedServiceType(matchedService.type)
        if (props.formReservation.tableId !== 0){
          setTableKey(`${formReservation.tableId}-${matchedService.heureDebut}`)
        }
        // Stocker dans un state si besoin
      }
    }
  }, [formReservation, services]);

  useEffect(() =>{
    setRestaurantId(Number(props.restaurant?.id))
    setServices(props.currentServices)
    setReservations(props.currentReservations)
  }, [])

  useEffect(() =>{
    fetchAllServices()
  }, [restaurantId])

  useEffect(() =>{
    fecthReservationsToday()
  }, [restaurantId])

  useEffect(() =>{
    fetchRemainingPlaces()
  }, [reservations, services])


  const fetchRemainingPlaces = async () => {
      var tables: TableModel[] = [];
      const newRemainingTables: Record<string, TableModel[]> = {};
      for (const service of services) {
        const date = new Date(props.currentDate).toISOString().split('T')[0];
        const [openingHours, openingMinutes] = (service.heureDebut.split(':'));
        const [closingHours, closingMinutes] = (service.heureFin.split(':'));

        const filteredResa = reservations.filter((resa: Reservation) => {
          const resaDate = new Date(resa.date);
          const resaHours = resaDate.getHours();
          const resaMinutes = resaDate.getMinutes();  
          return (
            (resaDate.toISOString().split('T')[0] === date) &&
            ((resaHours > Number(openingHours) && resaHours < Number(closingHours)) ||
            (resaHours === Number(openingHours) && resaMinutes >= Number(openingMinutes)) ||
            (resaHours === Number(closingHours) && resaMinutes <= Number(closingMinutes))) 
            // && resa.reservationStatus.state !== "confirmed"
          ) 
        })
        if (filteredResa.length !== 0) {
          tables = props.tables.filter((table) => 
            !filteredResa.some((reservation: Reservation) => reservation.table?.id === table.id && reservation.reservationStatus.state === 'confirmed')
          );
        
          // Si la réservation a une table attribuée, on l'ajoute à la liste filtrée
          if (formReservation.tableId !== 0 && formReservation.reservationStatus.state === 'confirmed') {
            const reservedTable = props.tables.find((table) => table.id === formReservation.tableId);
            if (reservedTable) {
              tables.push(reservedTable);
            }
          }
        } else {
          tables = props.tables;
        }
  
        newRemainingTables[`${service.heureDebut}-${service.id}-${service.type}`] = tables      
      }
      // setState((prevState) => ({ ...prevState, availableTables: tables}))
      setRemainingTables(newRemainingTables);
    };


    const fetchAllServices = async () => {
        const services = await servicesRestauService.getServiceByRestaurant(restaurantId);
        const uniqueServiceTypes = Array.from(new Set(services.map(service => service.type)));
        setServices(services)
        setServiceTypeList(uniqueServiceTypes)
    }

    const fecthReservationsToday = async () => {
        const date = new Date(props.currentDate).toISOString().split('T')[0];
        const reservations = await reservationRestauService.getResaByRestauAndDate(restaurantId, date);
        setReservations(reservations)
      }


  // const [state, setState] = useState({
  //   // allergies: [] as Allergies[],
  //   // foodPrefs: [] as Food_Preferences[],
  //   // allergiesResa: [] as AllergiesReservation[],
  //   // foodPrefsResa: [] as Food_Preferences[],
  //   waiterFormId: ""
  // })
  const fetchBilling = async () =>{
    if (props.formReservation.billingId && props.formReservation.billingId !== 0){
      const billing = await billingService.getBillingById(props.formReservation.billingId);
      setBilling(billing);
    }
  }
 
  useEffect(() => {
    if (props.formReservation){
      setFormReservation(props.formReservation)
    }
    if (props.formCustomer){
      setFormCustomer(props.formCustomer)
    }
    // axios.get(apiUrl + `/Waiter/idRestaurant=${props.restaurant?.id}`)
    // .then(response => {
    //   const data = response.data
    //   setState(prevState => ({...prevState, waitersList: data}))
    // })
    fetchBilling();

    // if (props.formReservation){
    //   axios.get(apiUrl + `/AllergiesReservation/ResaId=${props.formReservation.id}`)
    //         .then(response => {
    //       const data = response.data
    //       const ids = data.map((a: AllergiesReservation) => a.allergieId);
    //       setAllergiesIds(ids); // Met à jour l'état
    //     })
    //   axios.get(apiUrl + `/FoodPreferenceResa/ResaId=${props.formReservation.id}`)
    //     .then(response => {
    //   const data = response.data
    //   const ids = data.map((fp: FoodPreferencesReservation) => fp.food_PreferencesId);
    //   setFoodPrefsIds(ids); // Met à jour l'état
    // })
    //   }
  }, [props.formReservation]);

  // useEffect(() => {
  //   console.log('FORM Resa ::: ', formReservation)
  //   console.log("FORM Customer ::: ", formCustomer)
  // }, [formReservation, formCustomer]);





  // useEffect(() => {
  //   state.allergiesResa.map((allergie) => {
  //     axios.get(apiUrl + `/Allergy/${allergie.allergiesId}`)
  //     .then(response => {
  //       const data = response.data
  //       console.log('Allrgy DATA ::: ', data)
  //       setState(prevState => ({...prevState, allergies: data}))
  //     })
  //   })
  // }, [state.allergiesResa])

  // useEffect(() => {
  //   if (props.resa.id) {
  //     setDateResa(props.resa.date);
  //     setNbAdults(props.resa.adultCount);
  //     setNbChildren(props.resa.childrenCount);
  //     setPrice(props.resa.price);
  //     // setFacturation(props.resa.facturation);
  //     setGratuite(props.resa.gratuit);
  //     setCustomerId(props.resa.customerId);
  //     setTimestamp(props.resa.timestamp);
  //     setEvents(props.resa.events);
  //     // setStatus(props.resa.status);
  //     // setAllergiesGrp(props.resa.allergiesGroup);
  //     // setFoodPrefsGrp(props.resa.foodPrefsGroup);
  //     // setSpecialOccasion(props.resa.specialOccasion);
  //     setMenus(props.menus);
  //     setCurrentEstablishmentId(props.resa.restaurantId);
  //     setTable(props.resa.table);
  //     setWaiterId(props.formReservation.waiterId)
      

  //     const customerForResa = props.customers.find(c => Number(c.idCustomer) === props.resa.customerId);
  //     if (customerForResa){
  //       setCurrentCustomer(customerForResa);
  //     }

  //     if (customerForResa){
  //       // setAllergies(customerForResa.allergies);
  //       // setFoodPrefs(customerForResa.foodPrefs);
  //       setLangage(customerForResa.languageCode);
  //       // setNbVisits(customerForResa.nbVisits);
  //     }
  //   }
  //   // buildHistory();
  // }, [])

  // useEffect(() => {
  //   if (currentEstablishmentId){
  //   setEstablishmentWaiters(props.waiterList.filter(w => w.restaurantId === currentEstablishmentId))
  // }
  // }, [currentEstablishmentId, props.waiterList]);

  // useEffect(() => {
  //   // Compter le nombre d'occurrences de chaque menu
  //   const newMenuCountMap = new Map<number, number>();
  //   props.resa.menus.forEach(menu => {
  //     const id = menu.id;
  //     newMenuCountMap.set(id, (newMenuCountMap.get(id) || 0) + 1);
  //   });
  //   setMenusCount(newMenuCountMap);
  // }, [props.resa.menus]);


  // const buildHistory = () => {
  //   let newHistory = '';
  //   let newHistoryPrefix = '';
  //   for (const event of props.resa.events.sort((e1, e2) => e1.date - e2.date)) {
  //     switch (event.type) {
  //       case EventType.Confirmed:
  //         newHistoryPrefix = `"Confirmé" Par `;
  //         break;
  //       case EventType.Pending:
  //         newHistoryPrefix = `"En attente" Par `;
  //         break;
  //       case EventType.Seated:
  //         newHistoryPrefix = `"Assis" Par `;
  //         break;
  //       case EventType.PartiallySeated:
  //         newHistoryPrefix = `"Partiellement assis" Par `;
  //         break;
  //       case EventType.Gone:
  //         newHistoryPrefix = `"Parti" Par `;
  //         break;
  //       case EventType.WaitingList:
  //         newHistoryPrefix = `"Sur liste d'attente" Par `;
  //         break;
  //       case EventType.WaitingCB:
  //         newHistoryPrefix = `"En attente CB" Par `;
  //         break;
  //       case EventType.NoShow:
  //         newHistoryPrefix = `"No Show" Par `;
  //         break;
  //       case EventType.Cancel:
  //         newHistoryPrefix = `"Annulé" Par `;
  //         break;             
  //     }

  //     newHistory +=  newHistoryPrefix + `${event.user} le ${moment(event.date).format('DD-MM-YYYY')} à ${moment(event.date).format('HH')}h${moment(event.date).format('mm')}\n\n`;
  //   }

  //   setHistory(newHistory);
  // }


  // const submit = () => {
  //   const newReservations: Array<Reservation> = [];
  //   const newCustomers: Array<Customer> = [];
  //     if (currentCustomer.firstName !== "" && currentCustomer.lastName !== "" && currentCustomer.phone && status !== ReservationStatus.None) {
  //     // currentCustomer.allergies = allergies;
  //     // currentCustomer.foodPrefs = foodPrefs;
  //     newCustomers.push(new Customer(
  //       currentCustomer.idCustomer,
  //       currentCustomer.firstName,
  //       currentCustomer.lastName,
  //       currentCustomer.sex,
  //       currentCustomer.birthDate,
  //       currentCustomer.phone,
  //       currentCustomer.email,
  //       // allergies,
  //       // foodPrefs,
  //       // currentCustomer.customerStatus,
  //       currentCustomer.bedroom,
  //       // nbVisits,
  //       currentCustomer.languageCode,
  //     ));
  //     newReservations.push(new Reservation(
  //       customerId,
  //       dateResa,
  //       nbAdults,
  //       nbChildren,
  //       price,
  //       specialOccasion,
  //       specialRequests,
  //       facturation,
  //       gratuite,
  //       timestamp || props.currentDate,
  //       events,
  //       status,
  //       reservationStatusId,
  //       allergiesGrp,
  //       foodPrefsGrp, 
  //       false,
  //       currentEstablishmentId,
  //       table,
  //       waiterId,
  //       menusList,
  //       id
  //     ));
  //   props.submit(newCustomers, newReservations); 
  //   }
  // }



  // useEffect(() => {
  //     console.log("allergiesCust FORM : ", allergiesCustIds)
  //     console.log("foodPrefsCust FORM : ", foodPrefsCustIds)
  //   }, [allergiesCustIds, foodPrefsCustIds])

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const childRef = useRef<{ validateFields: () => { [key: string]: string } } | null>(null);

// const validateChildFields = () => {
//   const childErrors = childRef.current?.validateFields();
//   setErrors(childErrors || {});
//   return Object.keys(childErrors || {}).length === 0; // Retourne true si pas d'erreurs
// };

const submit = () => {
  // event.preventDefault();
  const childErrors = childRef.current?.validateFields() || {};

  if (Object.keys(childErrors).length > 0) {
    console.log("Erreurs détectées, formulaire non envoyé !");
    return;
  }

  props.submit(formCustomer, formReservation, allergiesResaIds, foodPrefsResaIds, allergiesCustIds, foodPrefsCustIds, menusResaIds);
  
};

  const handleEstablishmentChange = (id: number) => {
    if (id){
    setCurrentEstablishmentId(id)
    props.formReservation.restaurantId = id;
    }
  }

  const handleWaiterChange = (value: number) => {
    props.formReservation.waiterId = value;
    }
    
    const handleSpecialRequestChange = (value: string) => {
      // const updatedSpecialRequests = [...formReservation.specialRequests, value];
      // handleReservationChange('specialRequests', updatedSpecialRequests)      
      setSpecialRequest(value);
      // console.log('updatedSpecialRequests handle ::: ', updatedSpecialRequests)
    };
    
  const onKeyDownSpecialRequest = (e: any) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    const newValue = specialRequest.trim();
    if (newValue) {
      const updatedSpecialRequests = [...formReservation.specialRequests, newValue]; // Créer un nouveau tableau
      handleReservationChange('specialRequests', updatedSpecialRequests); // Mettre à jour l'état
      setSpecialRequest(''); // Réinitialiser la saisie
    }
  }
};

  const handleSpecialRequestDelete = (valueToDelete: string) => {
    const updatedSpecialRequests = formReservation.specialRequests.filter((value) => value !== valueToDelete);
    handleReservationChange('specialRequests', updatedSpecialRequests)
    // setSpecialRequests(updatedSpecialRequests);
  };

    const handleCustomerChange = (customer: Customer) => {
      setFormCustomer(customer);
    };

    const handleReservationChange = (field: string, value: any) => {
      const updatedResa = { ...formReservation, [field]: value };
      setFormReservation(updatedResa);
    };

    const handlePaymentTypeChange = (value: any) => {
      // Créer une copie locale avec les modifications
      const updatedBilling = { ...billing, paymentTypeId: value };
      // Mettre à jour l'état
      setBilling(updatedBilling);
      handleReservationChange('billing', updatedBilling)
    };


  const onChangeDateTimePicker: TimePickerProps['onChange'] = (date: any) => {
    if (!date) {
      handleReservationChange("date", new Date());
      return;
    }
    const formattedDate = new Date(`${date.$d.getFullYear()}-${date.$d.getMonth() + 1}-${date.$d.getDate()} ${date.$H}:${date.$m}`);
    handleReservationChange("date", formattedDate)
  };

  

  return (
    <Grid className="form-container"> 
      <Grid container rowSpacing={1}>
        <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" columnSpacing={2} width={"100%"}>
          <h3 className="form-title">{props.title}</h3>
          <Button variant="outlined" type='button' className='close-btn' onClick={props.closeForm}>X</Button>
        </Grid>

        <CustomerReservationForm
          ref={childRef}
          langage={langage}
          customersList={props.customers}
          customer={formCustomer}
          onCustomerChange={handleCustomerChange}
          allergies={props.allergies}
          foodPrefs={props.foodPrefs}
          allergiesCustomer={props.allergiesCust}
          foodPrefsCustomer={props.foodPrefCust}
          setAllergiesIds={setAllergiesCustIds}
          setFoodPrefsIds={setFoodPrefsCustIds}
          />

        <Grid container item alignItems="flex-end" columnSpacing={2}>
        <Grid item xs={12} md={4}>
            <Typography className="input-title">Établissement</Typography>
            <Select variant='outlined' size='small' value={formReservation.restaurantId && formReservation.restaurantId !== 0 ? formReservation.restaurantId : ""} onChange={(e) => {
                handleReservationChange("restaurantId", Number(e.target.value))
                setRestaurantId(Number(e.target.value))
              }}>
              <MenuItem value={""} disabled>Restaurant</MenuItem>
                {props.restaurantsList.map((e, index) => (
                  <MenuItem key={index} value={e.id}>{e.nom}</MenuItem>
                )
                )}
            </Select>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography className="input-title">Service</Typography>
            <Select variant='outlined' size='small' value={selectedServiceType} onChange={(e) => {
                setSelectedServiceType(e.target.value)
              }}>
              <MenuItem value={""} disabled>Service</MenuItem>
                {serviceTypeList.map((e, index) => (
                  <MenuItem key={index} value={e}>{e}</MenuItem>
                )
                )}
            </Select>
          </Grid>


          <Grid item xs={12} md={4}>
            <Typography className='input-title'>Table</Typography>
            <Select
                size="small"
                variant="outlined"
                value={tableKey}
                onChange={(e) => {
                  handleReservationChange("tableId", Number(e.target.value.split("-")[0]))
                  setTableKey(e.target.value)}
                }
              >
                <MenuItem value={""} disabled>Choix table</MenuItem>
                {(() => {
                  // Dictionnaire pour compter le nombre de services par type
                  const serviceCount: Record<string, number> = {};

                  return Object.entries(remainingTables)
                  .filter(([key]) => {

                    const serviceType = key.split("-")[2]; // Récupère le type de service depuis la clé
                    return serviceType === selectedServiceType; // Ne garde que les services correspondants
                  })
                    .sort(([keyA], [keyB]) => {
                      const heureA = keyA.split("-")[0];
                      const heureB = keyB.split("-")[0];
                      return heureA.localeCompare(heureB);
                    })
                    .map(([key, tables]) => {
                      const [heureDebut, serviceId, serviceType] = key.split("-");

                      // Incrémente le compteur pour ce type de service
                      if (!serviceCount[serviceType]) {
                        serviceCount[serviceType] = 1;
                      } else {
                        serviceCount[serviceType]++;
                      }
                      // Détermine le libellé du service
                      const rank = `${serviceCount[serviceType]}${serviceCount[serviceType] === 1 ? "er" : "e"}`;
                      let serviceLabel = "";
                      switch (serviceType) {
                        case "lunch":
                          serviceLabel = `Déjeuner - ${rank} Service`;
                          break;
                        case "diner":
                          serviceLabel = `Dîner - ${rank} Service`;
                          break;
                        case "nonstop":
                          serviceLabel = `Non Stop - ${rank} Service`;
                          break;
                      }
                      return tables.map((table) => (
                        <MenuItem key={`${key}-${table.id}`} value={`${table.id}-${heureDebut}`}>
                          {table.numero !== 0 ? `${serviceLabel} : Table ${table.numero} - ${table.nbPersonne} pers.` : null}
                        </MenuItem>
                      ));
                    });
                })()}
              </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className='input-title'>Serveur</Typography>
            <Select size='small' variant='outlined' value={formReservation.waiterId && formReservation.waiterId !== 0 ? formReservation.waiterId : ""} onChange={(e) => handleReservationChange("waiterId", Number(e.target.value))}>
              <MenuItem value={""} disabled>Serveur</MenuItem>
                {props.waiterList.map((w: Waiter, index: any) => (
                  <MenuItem key={index} value={w.id}>{capitalize(w.prenom)} {w.nom.toUpperCase()}</MenuItem>
                )
                )}
            </Select>
          </Grid>
        </Grid>
          
        <Grid container item alignItems="flex-end" columnSpacing={2}>
          <Grid item xs={6} md={6}>
            <Typography className='input-title'>Date</Typography>
            <DatePicker className="date-picker" format={'DD/MM/YY'} locale={locale} value={formReservation.date ? dayjs(new Date(formReservation.date).getTime()) : dayjs(new Date().getTime())} onChange={onChangeDateTimePicker} />
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography className='input-title'>Heure</Typography>
            <TimePicker
              className='time-picker'
              value={formReservation.date ? dayjs(new Date(formReservation.date).getTime()) : dayjs(new Date().getTime())}
              locale={locale}
              format={'HH:mm'}
              minuteStep={5}
              use12Hours={false}
              onChange={onChangeDateTimePicker}
            /> 
          </Grid>
        </Grid>

          
        <Grid container item alignItems="flex-end" columnSpacing={2}>
          <Grid item xs={6} md={6}>
            <Typography className='input-title'>Nombre d'adultes</Typography>
            <TextField
              inputProps={{ min: 0 }}
              type='number'
              variant='outlined'
              size='small'
              aria-label="nbAdults"
              value={formReservation.adultCount}
              onChange={(val) => handleReservationChange("adultCount", val.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography className='input-title'>Nombre d'enfants</Typography>
            <TextField
              inputProps={{ min: 0 }}
              type='number'
              variant='outlined'
              size='small'
              aria-label="nbChildren"
              value={formReservation.childrenCount}
              onChange={(val) => handleReservationChange("childrenCount", val.target.value)}
              fullWidth
                />
          </Grid>
        </Grid>
        <Grid container item alignItems="flex-end" columnSpacing={2}>
        <Grid item xs={6} md={6}>
            <Typography className='input-title'>Visites</Typography>
            <TextField
              type='number'
              size='small'
              variant='outlined'
              aria-label="nbVisits"
              value={nbVisits}
              onChange={(val) => setNbVisits(Number(val.target.value))}
              fullWidth
            />
        </Grid>
        <Grid item xs={6} md={6}>
            <Typography className='input-title'>Tarif</Typography>
            <TextField
              inputProps={{ min: 0 }}
              type='number'
              variant='outlined'
              size='small'
              value={formReservation.price}
              onChange={(val) => handleReservationChange("price", val.target.value)}
              fullWidth
            />
          </Grid>
          </Grid>


        <Grid item xs={12} md={12}>
          <Typography className='input-title'>Occasion spéciale</Typography>
          <Select 
            variant='outlined' 
            size='small'
            labelId='special_occasion_label' 
            value={formReservation.specialOccasionId && formReservation.specialOccasionId !== 0 ? formReservation.specialOccasionId : ""} 
            onChange={(e) => handleReservationChange("specialOccasionId", Number(e.target.value))}
            style={{ minWidth: '200px' }}
          >
            <MenuItem value={""}>Non renseigné</MenuItem>
            {/* Filtrer les options en fonction du mode */}
            {props.specialOccasions.map((occasion: SpecialOccasion, index: any) => (
                <MenuItem key={index} value={occasion.id}>
                  {capitalize(occasion.displayAs)}
                </MenuItem>
              ))}
          </Select>
        </Grid>

        <Grid container item alignItems="flex-end" columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <Typography className='input-title'>Allergies</Typography>
            <AllergySelect
              allergies={props.allergiesResa}
              allergiesList={props.allergies}
              setAllergiesIds={setAllergiesResaIds}
              allergieType='reservation'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className='input-title'>Préférences</Typography>
            <FoodPrefsSelect
              foodPref={props.foodPrefResa}
              foodPrefList={props.foodPrefs}
              setFoodPrefIds={setFoodPrefsResaIds}
              foodPrefType='reservation'
            />
          </Grid>
        </Grid>

        <Grid container item alignItems="flex-end" columnSpacing={2}>
          <MenuSelect
            menusList={props.menuList}
            setMenusResaIds={setMenusResaIds}
            menusResa={props.menusResa}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
            menusCount={menusCount}
            setMenusCount={setMenusCount}
          />
        </Grid>

        <Grid container item alignItems="flex-end" columnSpacing={2}>
          <Grid item xs={12} md={12}>
            <Typography className='input-title'>Demande spéciale</Typography>
            <TextField
              type="text"
              variant='outlined'
              size='small'
              className='special-wish-input'
              value={specialRequest ? specialRequest : ''}
              onChange={(value) => handleSpecialRequestChange(value.target.value)}
              onKeyDown={onKeyDownSpecialRequest}
              placeholder='Touche "Entrée" pour enregistrer la demande'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={12}>
            {formReservation.specialRequests.map((value, index) => (
              <Grid item xs={12} md={12} display="flex" flexDirection="row" alignItems="center">
                <Grid item xs={10} md={10}>
                  <Typography>{value}</Typography>
                </Grid>
                <Grid item xs={2} md={2} justifyItems="end" paddingTop={0.5} paddingBottom={0.5}>
                  <Button size="small" variant="outlined" onClick={() => handleSpecialRequestDelete(value)} fullWidth>X</Button>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid container item alignItems="flex-end" columnSpacing={2}>
        <Grid item xs={12} md={4}>
            {/* <div>Statut Réservation</div>    */}
            <Typography className='input-title'>Statut Réservation</Typography>
            <Select 
              variant='outlined'
              size='small'
              labelId='resa_status_label' 
              value={formReservation.reservationStatusId && formReservation.reservationStatusId !== 0 ? formReservation.reservationStatusId : ''} 
              onChange={(e) => handleReservationChange("reservationStatusId", Number(e.target.value))}
            >
              {/* Filtrer les options en fonction du mode */}
              {props.resaStatusList
                .filter((status: ReservationStatus) => 
                  props.mode === FormMode.Edit || status.state !== "cancel"
                )
                .map((status: ReservationStatus, index: any) => (
                  <MenuItem key={index} value={status.id}>
                    {capitalize(status.statusLabel)}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <div>Statut Client</div>    */}
            <Typography className='input-title'>Statut Client</Typography>
            <Select 
              variant='outlined'
              size='small'
              labelId='client_status_label' 
              value={formReservation.customerActionId && formReservation.customerActionId !== 0 ? formReservation.customerActionId : ''} 
              onChange={(e) => handleReservationChange("customerActionId", Number(e.target.value))}
            >
              <MenuItem value="">Non renseigné</MenuItem>
              {/* Filtrer les options en fonction du mode */}
              {props.customerActionsList.map((action: CustomerActions, index: any) => (
                  <MenuItem key={index} value={action.id}>
                    {capitalize(action.actionLabel)}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
          {/* <div>Type de facturation</div>    */}
          <Typography className='input-title'>Facturation</Typography>
            <Select
              required
              variant='outlined'
              size='small'
              labelId='facturation_label' 
              value={billing && billing.paymentTypeId !== 0 ? billing.paymentTypeId : ''} 
              onChange={(e) => handlePaymentTypeChange(Number(e.target.value))}
              // onChange={(e) => handleReservationChange("billing", props.paymentTypeList.find(billing => billing.id === Number(e.target.value)))}
            >
              <MenuItem value="">Non renseigné</MenuItem>
              {/* Filtrer les options en fonction du mode */}
              {props.paymentTypeList.map((type: PaymentType, index: any) => (
                  <MenuItem key={index} value={type.id}>
                    {capitalize(type.displayAs)}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>



        {props.mode === FormMode.Edit ? (
          <div>
            <div>Historique</div>
            <textarea disabled value={history}></textarea>
          </div>
        ) : null}
        <div>
          <Button type="submit" variant="contained" onClick={submit} color="primary">
            {props.submitLabel}
          </Button>
          {/* <GnzButton label={props.submitLabel} onClick={submit} backgroundColor={props.submitColor} /> */}
        </div>
      </Grid>
    </Grid>
  );
  
}

export default ReservationForm;

