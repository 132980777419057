import { useEffect, useRef, useState } from 'react';
import GnzDrawer from '../../shared/gnz-drawer/GnzDrawer';
import axios from 'axios';
import { API_MEWS_URL }   from '../../../ConfigUrl';
import { useNavigate } from 'react-router-dom';
import Feedback from '../../../models/Feedback';
import GnzButton from '../../shared/gnz-button/GnzButton';
import AllergiesCustomer from '../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../models/FoodPreferencesCustomer';
import ProfilClientsPreferenceForm from '../../ProfilClients/ProfilClientPreferences/ProfilClientPreferencesForm/ProfilClientsPreferenceForm'
import { format } from 'date-fns';
import GnzImage from '../../shared/gnz-image/GnzImage';
import plus2 from '../../../images/shared/plus2.png';
import more from '../../../images/shared/more.png';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import './FeedbackHistory.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import FormMode from '../../../enums/FormMode';
import ConsigneFilter from '../../../enums/ConsigneFilter';


function FeedbackHistory() {
  const navigate = useNavigate();
  const drawerPreferences = useRef<HTMLDivElement>(null); 
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [feedback, SetFeedback] = useState<Array<Feedback>>([]);
  const [allergiesCustomer, AllergiesCustomer] = useState<Array<AllergiesCustomer>>([]);
  const [foodPreferencesCustomer, FoodPreferencesCustomer] = useState<Array<FoodPreferencesCustomer>>([]);  
  const { currentDate } = useSelector((state: RootState) => state.app);
  const drawerRef = useRef<HTMLDivElement>(null);
  const cancelDialogRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const [filter, setFilter] = useState<ConsigneFilter>(ConsigneFilter.None);
  const [feedbackNew, setFeedbackNew] = useState<Feedback>(new Feedback());  
  const idCustomer = localStorage.getItem('idCustomer');  
  const Url = API_MEWS_URL;
  const [urlProfile, setUrlProfile] = useState('');  
  
  useEffect(() => {   
      setUrlProfile(localStorage.getItem('TypeProfilDetails') ?? "clientsDetails"); 
  }, [])

  useEffect(() => {
    var customerId = idCustomer;
    if(customerId != ""){
      axios.get(Url + 'Feedback/' + customerId)
      .then(res => SetFeedback(res.data));
    }    
  }, [])

  useEffect(() => {
    axios.get(Url + 'AllergiesCustomer/' + idCustomer)
      .then(res => AllergiesCustomer(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPreferenceCustomer/' + idCustomer)
      .then(res => FoodPreferencesCustomer(res.data));
  }, [])

  const ClientPreferences = () => {
    openDrawerPreferences();
  };

  const openDrawerPreferences = () => {
    const drawerRefEl: any = drawerPreferences.current;
    drawerRefEl.open();
  }

  const closePreferencesEdit = () => {
    const drawerRefEl: any = drawerPreferences.current;
    drawerRefEl.close();
  }

  const handleChange = (event: any) => {
    // SetFeedback([]);
    // axios.get(Url + 'Feedback/' + startDate + '/' + event.target.value)
    //   .then(res => SetFeedback(res.data));
  };    

  const newFeedback = () => {
    setMode(FormMode.Add);
    setFeedbackNew(new Feedback());
    openDrawer();
  };

  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }

  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }

  const feedbackFormTitle = () => {
    switch (mode) {
      case FormMode.New:
        return 'Nouveau Feedback';
      case FormMode.Edit:
        return 'Edition de la feedback';
      default:
        return '';
    }
  }

  const GotoRute = (event: any) => {
    switch (event.target.value) {
      case "1":
        window.location.href = `/`+ urlProfile;
        break;
      case "2":
        window.location.href = `/FeedbackClient`;
        break;
      case "3":
        window.location.href = `/clientsPreferences`;
        break;      
    }
  }

  return (
    <div className='userPreference'>
      <h3 className='userPreference_header'>Fiche Client</h3>
      <div className='userPreference_content'>
      <div className="ProfilClientsPreferenceTopActions">
       <select className='selectSyle' onChange={GotoRute}>
          <option value="1" onClick={() => navigate(`/`+urlProfile)} onSelect={() => navigate(`/`+urlProfile)}>Profil</option>
          <option value="2" onClick={() => navigate(`/FeedbackClient`)} onSelect={() => navigate(`/FeedbackClient`)}  >Feedback</option>
          <option value="3" onClick={() => navigate(`/clientsPreferences`)} onSelect={() => navigate(`/clientsPreferences`)} >Préférences</option>         
          {/* <option value={3}>Email</option> */}
        </select>

        <div className='PreferenceSelectSyleDiv'>
          <GnzButton  label="Partager ficher client" onClick={ClientPreferences} color="#ffffff" backgroundColor="#1D62AF" />
        </div>
        <div className='selectTimeDiv'>
          <input type="date" className="Datasyle" defaultValue={date} onChange={(e) => setStartDate(e.target.value)} />
          <input type="date" className="Datasyle" defaultValue={date} onChange={handleChange} />
        </div>
        </div>

        <div className="userPreference_info">
        <div className="userPreference_Items">
            <h2><strong>Historiques</strong>:</h2>
            {feedback.map((r, index) => (
              <div>
                <p>Feedback le {format(r.createDate, 'dd/MM/yyyy')} à {format(r.createDate, 'hh:mm:ss')} 
                  service {r.servicesItems?.servicesName}              
                </p>
                <p>{r.commerntarie}</p>
                <p>{r.status?.statusName} le {format(r.updateDate, 'dd/MM/yyyy')} à {format(r.updateDate, 'hh:mm:ss')}</p>
                <p>{r.solution}</p>
                </div>
            ))}
          </div>      

        </div> 

        <div className='FeedbackSelectSyleDiv'>          
                  <GnzButton leftIcon={<GnzImage src={plus2} />} label="Nouveau Feedback" onClick={newFeedback} 
                  color="#ffffff" backgroundColor="#1D62AF"  />
        </div>

        <GnzDrawer ref={drawerRef} component={
        <FeedbackForm
          mode={mode}
          feedbackType={'FeedbackClient'}
          feedback={feedback[0]?? undefined}
          startDate={startDate}
          endDate={endDate}
          title={feedbackFormTitle()}
          closeForm={closeDrawer}
          customerId={idCustomer ?? ""}
        />
      } />     

        <GnzDrawer ref={drawerPreferences} component={
          <ProfilClientsPreferenceForm
            myParam={idCustomer ?? ""}
            allergiesCustomer={allergiesCustomer}
            foodPreferencesCustomer={foodPreferencesCustomer}
            closeForm={closePreferencesEdit}
          />
        } />      
      </div>
    </div>
  );
}

export default FeedbackHistory