import * as React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography} from '@mui/material/';

import { reservationRestauService } from '../../api/ReservationRestauService';

import halal from '../../../../images/icones_restau/halal.png';
import casher from '../../../../images/icones_restau/casher.png';
import vegan from '../../../../images/icones_restau/vegan.png';
import vegetarien from '../../../../images/icones_restau/vegetarien.png';
import seafood from '../../../../images/icones_restau/seafood.png';
import sugarfree from '../../../../images/icones_restau/sugarfree.png';
import porkfree from '../../../../images/icones_restau/porkfree.png';
import diabetique from '../../../../images/icones_restau/diabetique.png';
import enceinte from '../../../../images/icones_restau/enceinte.png';
import paleo from '../../../../images/icones_restau/paleo.png';
import pescetarien from '../../../../images/icones_restau/pescetarien.png';
import gluten from '../../../../images/icones_restau/gluten.png';
import soja from '../../../../images/icones_restau/soja.png';
import fish from '../../../../images/icones_restau/fish.png';
import oeuf from '../../../../images/icones_restau/oeuf.png';
import moutarde from '../../../../images/icones_restau/moutarde.png';
import mollusque from '../../../../images/icones_restau/mollusque.png';
import champignon from '../../../../images/icones_restau/champignon.png';
import lait from '../../../../images/icones_restau/lait.png';
import fruits_coques from '../../../../images/icones_restau/fruits_coques.png';
import sesame from '../../../../images/icones_restau/sesame.png';
import ail_oignon from '../../../../images/icones_restau/ail_oignon.png';
import tomate from '../../../../images/icones_restau/tomate.png';
import crustace from '../../../../images/icones_restau/crustace.png';
import arachide from '../../../../images/icones_restau/arachide.png';
import celeri from '../../../../images/icones_restau/celeri.png';
import sulphites from '../../../../images/icones_restau/sulphite.png';
import lupin from '../../../../images/icones_restau/lupin.png';
import user from '../../../../images/shared/user.png';
import pencilwhite from '../../../../images/shared/pencilwhite.png';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Reservation from '../../../../models/ReservationModel';
import GnzButton from '../../../shared/GnzButton/GnzButton';
import GnzImage from '../../../shared/GnzImage/GnzImage';

import Customer from '../../../../models/Customers';
import EventType from '../../../../enums/ReservationEnums/ReservationEventType';
import { useEffect, useState } from 'react';
import Menu from '../../../../models/MenuModel';
import moment from 'moment';

import './ReservationTable.css';
import Waiter from '../../../../models/Employee';
import axios from 'axios';
// import { Service } from '../../../restaurant/types/types';
import Service from '../../../../models/ServiceModel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import Customers from '../../../../models/Customers';
import Status from '../../../../models/Status';
import ReservationStatus from '../../../../models/ReservationStatus';
import AllergiesReservation from '../../../../models/AllergiesReservation';
import FoodPreferencesReservation from '../../../../models/FoodPreferencesReservation';

import { reservationStatusService } from '../../api/ReservationStatusService';
import { foodPrefsService } from '../../api/FoodPrefsService';
import { allergiesService } from '../../api/AllergiesService';
import ReservationMenu from '../../../../models/ReservationMenu';
import { menuService } from '../../api/MenuService';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import CustomerActions from '../../../../models/CustomersActions';
import Employee from '../../../../models/Employee';
import ReservationsRestau from '../../../../models/ReservationsRestau';
import ReservationHotel from "../../../../models/Reservation";
import { reservationHotelService } from '../../api/ReservationHotelService';
import { customersService } from '../../api/CustomersService';

const allergiesIcons = new Map([
  ["gluten", gluten],
  ["fish", fish],
  ["soja", soja],
  ["egg", oeuf],
  ["mustard", moutarde],
  ["molluscs", mollusque],
  ["mushrooms", champignon],
  ["dairyProduct", lait],
  ["nuts", fruits_coques],
  ["sesame", sesame],
  ["garlicOinions", ail_oignon],
  ["tomatoe", tomate],
  ["shellFish", crustace],
  ["arachid", arachide],
  ["celery", celeri],
  ["sulphites", sulphites],
  ["lupin", lupin]
]);

const foodPrefsIcons = new Map([
  ["vegan", vegan],
  ["vegetarian", vegetarien],
  ["seaFoodFree", seafood],
  ["porkFree", porkfree],
  ["pregnant", enceinte],
  ["paleo", paleo],
  ["pescetarian", pescetarien],
  ["casher", casher],
  ["halal", halal],
  ["diabetic", diabetique],
  ["sugarFree", sugarfree],
]);
interface IRow {
  resa: Reservation,
  customer?: Customer,
  editReservation: any,
  cancelReservation: any,
  retablirReservation: any,
  key: any,
  waiter?: Waiter,
  menus: Menu[],
  customerId: any
  reload: boolean
}

function Row(props: IRow) {
  const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';
  const [open, setOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  
  // const combinedFoodPrefs = Array.from(
  //   new Set([...(props.customer?.foodPrefs || []), ...(props.resa?.foodPrefsGroup || [])])
  // );
const [prixTotal, setPrixTotal] = useState(0);
const [menuCountMap, setMenuCountMap] = useState<Map<string, number>>(new Map());
const [customer, setCustomer] = useState<Customer>();
const [resaStatus, setResaStatus] = useState<ReservationStatus>(new ReservationStatus());
const [waiter, setWaiter] = useState<Waiter>();
const [allergiesResa, setAllergiesResa] =  useState<AllergiesReservation[]>([]); 
const [foodPrefsResa, setFoodPrefsResa] = useState<FoodPreferencesReservation[]>([]);
const [allergiesCust, setAllergiesCust] =  useState<AllergiesCustomer[]>([]); 
const [foodPrefsCust, setFoodPrefsCust] = useState<FoodPreferencesCustomer[]>([]);
const [menusResa, setMenusResa] = useState<ReservationMenu[]>([]);
const [reservationHotel, setReservationHotel] = useState<ReservationHotel>(new ReservationHotel())

const fetchReservationHotel = async () => {
  const resa = await reservationHotelService.getResaHotelByCustomer(props.customerId)
  setReservationHotel(resa[0])
}
// useEffect(() => {
//     // Calculer le prix total des menus
//     const newPrixTotal = props.resa.menus.reduce((acc, menu) => acc + menu.price, 0);
//     setPrixTotal(newPrixTotal);

//     // Compter le nombre d'occurrences de chaque menu
//     const newMenuCountMap = new Map<string, number>();
//     props.resa.menus.forEach(menu => {
//       const label = menu.label;
//       newMenuCountMap.set(label, (newMenuCountMap.get(label) || 0) + 1);
//     });
//     setMenuCountMap(newMenuCountMap);
//   }, [props.resa.menus]);
const fetchData = async () => {
  try {
    const resaStatusResponse = reservationStatusService.getReservationStatusById(props.resa.reservationStatusId);
    const allergiesResaResponse = allergiesService.getAllergiesReservation(props.resa.id);
    const foodPrefsResaResponse = foodPrefsService.getFoodPrefReservation(props.resa.id);
    const allergiesCustResponse = allergiesService.getAllergiesCustomer(props.customerId);
    const foodPrefsCustResponse = foodPrefsService.getFoodPrefCustomer(props.customerId);
    const menusResponse = menuService.getReservationMenusByReservation(props.resa.id);
    const customerResponse = await customersService.getCustomerById(props.customerId);
    setResaStatus(await resaStatusResponse);
    setAllergiesResa(await allergiesResaResponse);
    setFoodPrefsResa(await foodPrefsResaResponse);
    setAllergiesCust(await allergiesCustResponse);
    setFoodPrefsCust(await foodPrefsCustResponse);
    setMenusResa(await menusResponse)
    setCustomer(await customerResponse)
  } catch (error) {
    console.error('Error fetching data ReservationTable:', error);
  }
};

useEffect(() => {
  fetchData();
  fetchReservationHotel()
}, []);

useEffect(() => {
  fetchData();
  fetchReservationHotel()
}, [props.reload])

const combinedAllergies = Array.from(
  new Set([...(allergiesCust || []), ...(allergiesResa || [])])
);

const allAllergies = combinedAllergies.reduce<Record<string, string>>((acc, item) => {
  const name = item.allergies.displayAs
  acc[name] = item.allergies.label;
  return acc;
}, {})

const combinedFoodPrefs = Array.from(
  new Set([...(foodPrefsCust || []), ...(foodPrefsResa || [])])
);

const allFoodPrefs = combinedFoodPrefs.reduce<Record<string, string>>((acc, item) => {
  const name = item.food_Preferences.displayAs
  acc[name] = item.food_Preferences.label;
  return acc;
}, {})

const menuCounts = menusResa.reduce<Record<string, number>>((acc, item) => {
  const menuName = item.menu.nom;
  acc[menuName] = (acc[menuName] || 0) + 1;
  return acc;
}, {});

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{
              width: '23%',
              textAlign: 'center'
            }}>

        {customer?.title} {customer?.lastName.toUpperCase()} {customer?.firstName}
        </TableCell>
        <TableCell style={{
              width: '23%',
              textAlign: 'center'
            }}>
          {props.resa.table ? props.resa.table.numero : ""}
        </TableCell>
        <TableCell style={{
              width: '23%',
              textAlign: 'center'
            }}>
          {new Date(props.resa.date).getHours() < 10 ? "0" + new Date(props.resa.date).getHours() : new Date(props.resa.date).getHours()}h{new Date(props.resa.date).getMinutes() < 10 ? "0" + new Date(props.resa.date).getMinutes() : new Date(props.resa.date).getMinutes()}
        </TableCell>
        <TableCell style={{
              width: '23%',
              textAlign: 'center'
            }}>
          {props.resa.reservationStatus?.statusLabel}
        </TableCell> 
        <TableCell style={{
              width: '8%',
              textAlign: 'center'
            }}>
          <IconButton
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table>
                <TableBody>
                <TableRow >
                    <TableCell sx={{width: '30%'}} size='small'>
                      <Typography className='sub-row-label'>Serveur</Typography>
                    </TableCell>
                    <TableCell sx={{width: '70%'}} size='small'>
                      {props.waiter?.prenom} {props.waiter?.nom.toUpperCase()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{width: '30%'}} size='small'>
                      <Typography className='sub-row-label'>Personnes</Typography>
                    </TableCell>
                    <TableCell sx={{width: '70%'}} size='small'>
                        {props.resa.adultCount + props.resa.childrenCount} ({props.resa.adultCount > 1 ? props.resa.adultCount + " adultes" : props.resa.adultCount + " adulte"}
                          {props.resa.childrenCount > 0 ?
                          props.resa.childrenCount > 1 ?
                            ", " + props.resa.childrenCount + " enfants" 
                            :
                            ", " + props.resa.childrenCount + " enfant" 
                          :
                          null}
                        )
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{width: '30%'}} size='small'>
                      <Typography className='sub-row-label'>Menus</Typography>
                    </TableCell>
                    <TableCell sx={{width: '70%'}} size='small'>
                      {Object.entries(menuCounts).map(([name, count]) => (
                          <Typography key={name} variant="body2">{count}x {name}</Typography>
                        ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{width: '30%'}} size='small'>
                      <Typography className='sub-row-label'>Chambre</Typography>
                    </TableCell>
                    <TableCell sx={{width: '70%'}} size='small'>
                      {reservationHotel ? reservationHotel.number : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{width: '30%'}} size='small'>
                      <Typography className='sub-row-label'>Allergies</Typography>
                    </TableCell>
                    <TableCell sx={{width: '70%'}} size='small'>
                      {Object.entries(allAllergies).map(([name, label]) => (
                          <img
                            className="food-icon"
                            key={label}
                            src={allergiesIcons.get(label)}
                            alt={name}
                            title={name}
                          />
                      ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{width: '30%'}} size='small'>
                      <Typography className='sub-row-label'>Préférences alimentaires</Typography>
                    </TableCell>
                    <TableCell sx={{width: '70%'}} size='small'>
                      {Object.entries(allFoodPrefs).map(([name, label]) => (
                        <img
                          className="food-icon"
                          key={label}
                          src={foodPrefsIcons.get(label)}
                          alt={name}
                          title={name}
                        />
                        ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{width: '30%'}} size='small'>
                      <Typography className='sub-row-label'>Occasion spéciale</Typography>
                    </TableCell>
                    <TableCell sx={{width: '70%'}} size='small'>
                      {props.resa.specialOccasion ? props.resa.specialOccasion.displayAs : ''}
                    </TableCell>
                  </TableRow>


                  <TableRow>
                    <TableCell sx={{width: '30%'}} size='small'>
                      <Typography className='sub-row-label'>Historique</Typography>
                    </TableCell>
                    <TableCell sx={{width: '70%', whiteSpace: 'nowrap'}} size="small">
                      <IconButton sx={{padding: 0}} onClick={() => setHistoryOpen(!historyOpen)} size='small'>
                        {historyOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {historyOpen && (
                    <TableRow>
                      <TableCell sx={{width: '30%'}} size='small'></TableCell>
                      <TableCell sx={{width: '70%'}} size='small'>
                        <Collapse in={historyOpen} timeout="auto" unmountOnExit>
                          <Box>
                          {/* {props.resa.events.sort((e1, e2) => e1.date - e2.date).map((event, key) => (
                            <div key={key} className="ResaTableHistoryRow">  
                              <div className="ResaTableRowCell">
                                {event.type !== EventType.Cancel && key === 0 ?
                                  'Ecrite par' :
                                  event.type === EventType.Cancel ?
                                  'Annulé par' :
                                  'Modifié par'
                                }
                                
                              </div>
                              <div className="ResaTableRowCell">
                                  <GnzImage src={user} title={event.user} /> 
                              </div>                   
                              <div className="ResaTableRowCell">
                                <div className="">le {moment(event.date).format('DD-MM-YYYY')} à {moment(event.date).format('HH')}h{moment(event.date).format('mm')}</div>
                              </div>
                              {event.type === EventType.Cancel && event.info.length ? (
                                  <div className="ResaTableRowCell">Raison : {event.info}</div>
                              ) : null}
                            </div>
                          ))}        */}
                          <Typography variant="body2">EVENT</Typography>
                          <Typography variant="body2">EVENT</Typography>
                          <Typography variant="body2">EVENT</Typography>
                          <Typography variant="body2">EVENT</Typography>
                          <Typography variant="body2">EVENT</Typography>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* <div className="ResaTable">
                  <div className="ResaTableItem">
                 
                    <div className="ResaTableRow">
                      
                      <div style={{
                        width: '8%',
                        textAlign: 'center'
                      }}>
                        <IconButton
                          onClick={() => setHistoryOpen(!historyOpen)}
                        >
                          {historyOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </div>
                    </div>
                  </div>
              </div> */}
              <div className="RowActions">
                <GnzButton small leftIcon={<GnzImage src={pencilwhite} />} label="Modifier" onClick={() => props.editReservation(props.resa, customer, allergiesResa, foodPrefsResa, allergiesCust, foodPrefsCust, menusResa)} color="#fff" backgroundColor="#0B4B92" />                  
                  {props.resa.events && props.resa.events.some(e => e.type === EventType.Cancel) ? (
                  //  <GnzButton small label="Retablir" onClick={() => props.retablirReservation(props.resa)} color="#fff" backgroundColor="#EBB21F" />
                  <GnzButton small label="Retablir" onClick={() => props.retablirReservation(props.resa)} color="#fff" backgroundColor="#EBB21F" />
                  ) : (
                    // <GnzButton small label="Annuler" onClick={() => props.cancelReservation(props.resa)} color="#0B4B92" backgroundColor="#95E2AF" />
                    <GnzButton small label="Annuler" onClick={() => props.cancelReservation(props.resa)} color="#0B4B92" backgroundColor="#95E2AF" />
                  )}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface ResaFiltersType {
  filterNom: string;
  filterCustomerStatus: CustomerActions[];
  filterWaiters: Employee[];
  filterReservationStatus: ReservationStatus[];
}
interface IReservationTable {
  customers: Array<Customers>,
  menus: Array<Menu>,
  editReservation: any,
  cancelReservation: any,
  retablirReservation: any,
  opening: string,
  closing: string,
  waiterList: Array<Waiter>,
  service: Service,
  restaurantId: number,
  filter: string,
  reloadReservations: boolean,
  resaFilters: ResaFiltersType,
  filteredReservations: Reservation[];
}

export default function ReservationTable(props: IReservationTable) {
  const { currentDate } = useSelector((state: RootState) => state.app);
  const cd = moment(currentDate).startOf('day').format('YYYY-MM-DD');
  const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';
  const [reservations, setReservations] = useState<Array<Reservation>>([])
  const [sortedReservations, setSortedReservations] = useState<Array<Reservation>>([])
  


  return (
    <TableContainer component={Paper} className="ResaTableContainer">   
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
          <TableCell style={{
              backgroundColor: '#0B4B92',
              color: '#fff',
              fontFamily: `'Montserrat', sans-serif`,
              width: '23%',
              textAlign: 'center'
            }}>Nom</TableCell>
            <TableCell style={{
              backgroundColor: '#0B4B92',
              color: '#fff',
              fontFamily: `'Montserrat', sans-serif`,
              width: '23%',
              textAlign: 'center'
            }}>Table</TableCell>
            <TableCell style={{
              backgroundColor: '#0B4B92',
              color: '#fff',
              fontFamily: `'Montserrat', sans-serif`,
              width: '23%',
              textAlign: 'center'
            }}>Heure</TableCell>
            <TableCell style={{
              backgroundColor: '#0B4B92',
              color: '#fff',
              fontFamily: `'Montserrat', sans-serif`,
              width: '23%',
              textAlign: 'center'
            }}>Statut</TableCell>
            <TableCell style={{
              backgroundColor: '#0B4B92',
              color: '#fff',
              fontFamily: `'Montserrat', sans-serif`,
              width: '8%'
            }}/>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.filteredReservations.map((resa, key) => ( 
            <Row 
            key={key} 
            editReservation={props.editReservation} 
            resa={resa} 
            customer={resa.customerId ? props.customers.find(c => Number(c.idCustomer) === resa.customerId) : new Customer()} 
            cancelReservation={props.cancelReservation} 
            retablirReservation={props.retablirReservation}
            waiter={resa.waiterId ? props.waiterList.find(w => w.id === resa.waiterId) : undefined}
            menus={props.menus}
            customerId={resa.customerId}
            reload={props.reloadReservations}
            />
          ))} 
        </TableBody>
      </Table>
    </TableContainer>
  );
}