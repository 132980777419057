import {useState } from 'react';
import ProfilClientPreferencesDetails from './ProfilClientPreferencesDetails/ProfilClientPreferencesDetails';

function ProfilClientPreferences() {
  const [search, setSearch] = useState('');  

  return (
    <div className="ProfilClients">
      <div className="ProfilClientsTopActions">
      <h3 className='ProfilClientsTopActionsh3'>Preference</h3>
          <div className=''>
          <input className="inputsyle" onChange={(e) => setSearch(e.target.value)} placeholder='Que recherchez-vous ?'></input>
        </div> 
      </div>
      <ProfilClientPreferencesDetails />     
    </div>
  );
}
export default ProfilClientPreferences;