import gluten from '../../../../images/icones_restau/gluten.png';
import soja from '../../../../images/icones_restau/soja.png';
import poisson from '../../../../images/icones_restau/fish.png';
import oeuf from '../../../../images/icones_restau/oeuf.png';
import moutarde from '../../../../images/icones_restau/moutarde.png';
import mollusque from '../../../../images/icones_restau/mollusque.png';
import champignon from '../../../../images/icones_restau/champignon.png';
import lait from '../../../../images/icones_restau/lait.png';
import fruits_coques from '../../../../images/icones_restau/fruits_coques.png';
import sesame from '../../../../images/icones_restau/sesame.png';
import ail_oignon from '../../../../images/icones_restau/ail_oignon.png';
import tomate from '../../../../images/icones_restau/tomate.png';
import crustace from '../../../../images/icones_restau/crustace.png';
import arachide from '../../../../images/icones_restau/arachide.png';
import celeri from '../../../../images/icones_restau/celeri.png';
import sulphites from '../../../../images/icones_restau/sulphite.png';
import lupin from '../../../../images/icones_restau/lupin.png';

export default function AllergyMod() {
    const logos_all = new Map([
        [1, gluten],
        [2, poisson],
        [3, soja],
        [4, oeuf],
        [5, moutarde],
        [6, mollusque],
        [7, champignon],
        [8, lait],
        [9, fruits_coques],
        [10, sesame],
        [11, ail_oignon],
        [12, tomate],
        [13, crustace],
        [14, arachide],
        [15, celeri],
        [16, sulphites],
        [17, lupin]
      ])

    const allergies =new Map([
        [1, "Gluten"],
        [2, "Poisson"],
        [3, "Soja"],
        [4, "Oeufs"],
        [5, "Moutarde"],
        [6, "Mollusques"],
        [7, "Champignons"],
        [8, "Produits laitiers"],
        [9, "Fruits à coques"],
        [10, "Graines de Sésames"],
        [11, "Ail/Oignons"],
        [12, "Tomates"],
        [13, "Crustace"],
        [14, "Arachides"],
        [15, "Celeri"],
        [16, "Sulphites"],
        [17, "Graines de Lupin"]
      ])

      return (
        <div>
          {Array.from(allergies).map(([id, allergyName]) => (
            <div className="modal-item" key={id}>
                <img                                
                        className=''
                        key={id}
                        src={logos_all.get(id)}
                        alt={"img_" + allergyName}                    
                    /> 
            {allergyName}
            </div>
          ))}
        </div>
      );
}

