import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';

import GnzImage from '../../shared/GnzImage/GnzImage';
import GnzDialog from '../../shared/GnzDialog/GnzDialog';
import GnzDrawer from '../../shared/GnzDrawer/GnzDrawer';
import GnzButton from '../../shared/GnzButton/GnzButton';
import plus2 from '../../../images/shared/plus2.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import FormMode from '../../../enums/FormMode';
import EventType from '../../../enums/ReservationEnums/ReservationEventType';
import ReservationFilter from '../../../enums/ReservationEnums/ReservationFilter';
// import ReservationStatus from '../../../enums/ReservationEnums/ReservationStatus';

import Customer from '../../../models/Customers';
import ReservationEvent from '../../../models/ReservationEventModel';
import Reservation from '../../../models/ReservationModel';
import CustomerStatus from '../../../enums/CustomerEnums/CustomerStatus';
import ReservationFilters from './ReservationFilters/ReservationFilters';
import CancelReservationForm from './ReservationForms/CancelReservationForm';
import ReservationTable from './ReservationTable/ReservationTable';

import './ServicesRestaurant.css';
import ReservationForm from './ReservationForms/ReservationForm';
// import { Service } from '../../restaurant/types/types';
// import { Restaurant } from '../../restaurant/types/types';
import ServiceTypeFilter from './ReservationFilters/ServiceTypeFilter';
import EstablishmentFilter from './ReservationFilters/EstablishmentFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import AllergyMod from './Modals/AllergyMod';
import FoodPrefsMod from './Modals/FoodPrefsMod';
import Waiter from '../../../models/Employee';
import axios from 'axios';
import Service from '../../../models/ServiceModel';
import Restaurant from '../../../models/Restaurant';
import { response } from 'express';
import Food_Preferences from '../../../models/Food_Preferences';
import Allergies from '../../../models/Allergies';
import AllergiesReservation from '../../../models/AllergiesReservation';
import FoodPreferencesReservation from '../../../models/FoodPreferencesReservation';
import SpecialOccasion from '../../../models/SpecialOccasion';
import Menu from '../../../models/MenuModel';
import ReservationMenu from '../../../models/ReservationMenu';
import CustomerActions from '../../../models/CustomersActions';
import ReservationStatus from '../../../models/ReservationStatus';
import PaymentType from '../../../models/PaymentType';
import Billing from '../../../models/Billing';

import { customersService } from '../api/CustomersService';
import { restaurantService } from '../api/RestaurantService';
import { servicesRestauService } from '../api/ServicesRestauService';
import { allergiesService } from '../api/AllergiesService';
import { foodPrefsService } from '../api/FoodPrefsService';
import AllergiesCustomer from '../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../models/FoodPreferencesCustomer';
import { waiterService } from '../api/WaiterService';
import Employee from '../../../models/Employee';
import ReservationsRestau from '../../../models/ReservationsRestau';
import { reservationRestauService } from '../api/ReservationRestauService';
import { tableService } from '../api/TableService';
import Calendar from './calendar/calendar';
import TableModel from '../../../models/Table';


function ServicesRestaurant() {
  const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';
  const navigate = useNavigate();
  const location = useLocation();
  const { userName } = useSelector((state: RootState) => state.user);
  const { currentDate } = useSelector((state: RootState) => state.app);
  const cd = moment(currentDate).startOf('day').format('YYYY-MM-DD');
  const drawerRef = useRef<HTMLDivElement>(null);
  const drawerFilterRef = useRef<HTMLDivElement>(null);
  const cancelDialogRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const [filter, setFilter] = useState<ReservationFilter>(ReservationFilter.None);
  const [sortFilter, setSortFilter] = useState('');
  const [showCalendarModal, setCalendarModal] = useState(false);
  const openCalendarModal = () => { setCalendarModal(true); };
  const closeCalendarModal = () => { setCalendarModal(false); };
  
  const { serviceTypeParam, restaurantIdParam } = location.state || {};
  const [initialRestauIdParam, setInitialRestauIdParam] = useState<number>(restaurantIdParam);
  const [initialServiceTypeParam, setInitialServiceTypeParam] = useState<string>(serviceTypeParam);
  
  const [filterService, setFilterService] = useState("");
  const [filterEstablishmentId, setFilterEstablishmentId] = useState(0); 
  
  const [reservations, setReservations] = useState<Array<Reservation>>([]); 
  const [currentReservations, setCurrentReservations] = useState<Array<Reservation>>([]); 
  const [orderedCurrentReservations, setOrderedCurrentReservations] = useState<Array<Reservation>>([]);
  const [resaNew, setResaNew] = useState<Reservation>(new Reservation());
  const [resaEdit, setResaEdit] = useState<Reservation>(new Reservation());
  const [resaCancel, setResaCancel] = useState<Reservation>(new Reservation());
  
  const [currentCustomers, setCurrentCustomers] = useState<Array<Customer>>([]);
  const [allMenus, setAllMenus] = useState<Array<Menu>>([]);
  const [establishments, setEstablishments] = useState<Array<Restaurant>>([]);
  
  const [currentWaitersList, setCurrentWaitersList] = useState<Array<Waiter>>([]);
  const [filterWaiter, setFilterWaiter] = useState<Waiter[]>([]);
  
  const [filterNom, setFilterNom] = useState("");
  // const [filterStatus, setFilterStatus] = useState<ReservationStatus[]>([]);
  const [filterCustomerStatus, setFilterCustomerStatus] = useState<CustomerStatus[]>([]);
  
  const [currentEstablishment, setCurrentEstablishment] = useState<Restaurant>();
  
  const [continuous, setContinuous] = useState<boolean>(false);
  
  const [loading, setLoading] = useState(true);

  const [reloadReservations, setReloadReservations] = useState(false);

  const [filteredResaCount, setFilteredResaCount] = useState<number>(0);
  const [sortedReservations, setSortedReservations] = useState<Array<Reservation>>([])

  const [remainingTables, setRemainingTables] = useState<Record<string, TableModel[]>>({})

  const [resaFilters, setResaFilters] = useState({
    filterNom: '',
    filterCustomerStatus: [] as CustomerActions[],
    filterWaiters: [] as Employee[],
    filterReservationStatus: [] as ReservationStatus[]
  })
  
  const [state, setState] = useState({
    restaurants: [] as Restaurant[],
    currentRestaurant: new Restaurant(),
    reservations: [],
    currentRestauId: 0,
    services: [] as Service[],
    currentServices: [] as Service[],
    serviceType: '',
    requestService: [],
    reservationsByRestau: [],
    customers: [] as Customer[],
    formCustomer: new Customer(),
    formReservation: new Reservation(),
    foodPrefs: [] as Food_Preferences[],
    allergies: [] as Allergies[],
    specialOccasion: [] as SpecialOccasion[],
    allergiesResa: [] as AllergiesReservation[],
    foodPrefResa: [] as FoodPreferencesReservation[],
    allergiesCust: [] as AllergiesCustomer[],
    foodPrefCust: [] as FoodPreferencesCustomer[],
    menusResa: [] as ReservationMenu[],
    waiterList: [] as Waiter[],
    menuList: [] as Menu[],
    customerActions: [] as CustomerActions[],
    reservationStatuses: [] as ReservationStatus[],
    paymentTypeList: [] as PaymentType[],
    tables: [] as TableModel[]
  })
  
  const [allergyInfoHovered, setAllergyInfoHovered] = useState<boolean[]>(Array(state.currentServices.length).fill(false));
  const [allergyInfoPos, setAllergyInfoPos] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  
  const [foodPredsInfoHovered, setFoodPrefsInfoHovered] = useState<boolean[]>(Array(state.currentServices.length).fill(false));
  const [foodPrefsInfoPos, setFoodPrefsInfoPos] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      currentRestauId: Number(restaurantIdParam) ? Number(restaurantIdParam) : initialRestauIdParam,
      serviceType: serviceTypeParam ? serviceTypeParam : initialServiceTypeParam
    }));
  }, [])
  
  useEffect(() => {
    // const serviceParam = queryParams.get("service");
    // if(state.serviceType){
    //   setState((prevState) => ({ ...prevState, serviceType: state.serviceType}))
    // }
    try{
      axios.get(apiUrl + `/FoodPref`)
      .then(response => {
        setState(prevState => ({...prevState, foodPrefs: response.data}))
      })
      axios.get(apiUrl + `/Allergy`)
      .then(response => {
        setState(prevState => ({...prevState, allergies: response.data}))
      })
      axios.get(apiUrl + `/SpecialOccasion`)
      .then(response => {
        setState(prevState => ({...prevState, specialOccasion: response.data}))
      })
      axios.get(apiUrl + `/CustomerAction`)
      .then(response => {
        setState(prevState => ({...prevState, customerActions: response.data}))
      })
      axios.get(apiUrl + `/ResaStatus`)
      .then(response => {
        setState(prevState => ({...prevState, reservationStatuses: response.data}))
      })
      axios.get(apiUrl + `/PaymentType`)
      .then(response => {
        setState(prevState => ({...prevState, paymentTypeList: response.data}))
      })
      fetchAllCustomers();   
      fetchAllRestaurants();
      fetchWaiters();
      setLoading(false)
    }catch(error){
      console.log(`Error fetching ServicesRestaurant2 : ${error}`);
      setLoading(false)
    }
  }, []);

  useEffect(() => {    
    if (state.currentRestauId !== 0){
      fetchCurrRestau();
      fetchAllServices();
      fetchTables();
      axios.get(apiUrl + `/Menu/restaurantId=${state.currentRestauId}`)
      .then(response => {
        setState((prevState) => ({ ...prevState, menuList: response.data}))
      })
    }  
  }, [state.currentRestauId, restaurantIdParam])

  useEffect(() => {    
    if (state.currentRestauId !== 0){
      fetchCurrentServices();
    }  
  }, [state.serviceType, serviceTypeParam])


  useEffect(() => {    
    if (state.currentRestauId !== 0){
      fecthReservationsToday();
    }  
  }, [state.currentRestauId, cd])

// useEffect(() => {
//   fetchRemainingPlaces()
// }, [state.currentServices])
  

  const fetchAllServices = async () => {
    const services = await servicesRestauService.getServiceByRestaurant(state.currentRestauId)
    setState(prevState => ({...prevState, services: services}))
    const foundNonstop = services.some((service: Service) => service.type === "nonstop");
    setState(prevState => ({...prevState, serviceType: foundNonstop ? "nonstop" : serviceTypeParam}))
    if (foundNonstop){
      setContinuous(true)
    }else{
      setContinuous(false)
    }
  }

  const fetchAllCustomers = async () => {
    const customers = await customersService.getAllCustomers();
    setState((prevState) => ({ ...prevState, customers: customers}))
  }
  const fetchCurrRestau = async () =>{
    const restaurant = await restaurantService.getRestaurantById(state.currentRestauId); 
    setState(prevState => ({...prevState, currentRestaurant: restaurant}));
  }

  const fetchAllRestaurants = async () => {
    const restaurants = await restaurantService.getAllRestaurants(); 
    setState((prevState) => ({ ...prevState, restaurants: restaurants}));
  }

  const fetchCurrentServices = async () => {
    const services = await servicesRestauService.getServiceByRestaurantAndType(state.currentRestauId, state.serviceType);
    setState(prevState => ({ ...prevState, currentServices: services }));
  };

  const fetchWaiters = async () => {
    const waiters = await waiterService.getEmployeeWaitersFB();
    setState(prevState => ({ ...prevState, waiterList: waiters }));
  };

  const fecthReservationsToday = async () => {
    const date = new Date(currentDate).toISOString().split('T')[0];
    
    const reservations = await reservationRestauService.getResaByRestauAndDate(state.currentRestauId, date);
    setCurrentReservations(reservations)
  }

  const fetchTables = async () => {
    const tables = await tableService.getRestaurantTables(state.currentRestauId);
    setState(prevState => ({ ...prevState, tables: tables }));

  }




  const fetchRemainingPlaces = async () => {
    var tables: TableModel[] = [];
    const newRemainingTables: Record<string, TableModel[]> = {};
    for (const service of state.currentServices) {
      const date = new Date(currentDate).toISOString().split('T')[0];
      const [openingHours, openingMinutes] = (service.heureDebut.split(':'));
      const [closingHours, closingMinutes] = (service.heureFin.split(':'));
      const filteredResa = currentReservations.filter((resa: Reservation) => {
        const resaDate = new Date(resa.date);
        const resaHours = resaDate.getHours();
        const resaMinutes = resaDate.getMinutes();
        
        return (
          (resaDate.toISOString().split('T')[0] === date) &&
          ((resaHours > Number(openingHours) && resaHours < Number(closingHours)) ||
          (resaHours === Number(openingHours) && resaMinutes >= Number(openingMinutes)) ||
          (resaHours === Number(closingHours) && resaMinutes <= Number(closingMinutes))) &&
          resa.reservationStatus.state !== "confirmed"
        ) 
      })
      if (filteredResa.length !== 0) {
        tables = state.tables.filter((table) => 
        !filteredResa.some((reservation: Reservation) => reservation.table?.id === table.id)
      );
      }else{
        tables = state.tables
      }

      newRemainingTables[`${service.heureDebut}-${service.id}`] = tables         
    }
    setState((prevState) => ({ ...prevState, availableTables: tables}))
    setRemainingTables(newRemainingTables);
  };



  // ========================================================
  // ========================================================
  // ========================================================
  // ========================================================
  // ========================================================
  // ========================================================
  // ========================================================
  // ========================================================
  

  const sortReservations = (reservations: Reservation[], filter: string) => {
    const sortedReservations = [...reservations];
    sortedReservations.sort((a, b) => {
      const customerA = state.customers.find(c => Number(c.idCustomer) === a.customerId);
      const customerB = state.customers.find(c => Number(c.idCustomer) === b.customerId);

      if (customerA && customerB) {
        if (filter === '') {
          return 0;
        }else if (filter === 'AZ') {
          return customerA.lastName.toLowerCase().localeCompare(customerB.lastName.toLowerCase()); // Tri A-Z
        } else if (filter === 'ZA') {
          return customerB.lastName.toLowerCase().localeCompare(customerA.lastName.toLowerCase()); // Tri Z-A
        }
      }
      return 0;
    });
    return sortedReservations;
  };

  const filteredReservations = sortedReservations.filter(resa => {
    const customer = state.customers.find(c => Number(c.idCustomer) === resa.customerId);
    const filterStatutNumbers = resaFilters.filterReservationStatus.map(status => status.id);
    const filterCustomerStatusNumbers = resaFilters.filterCustomerStatus.map(status => status.id);
    const filteredWaiters = resaFilters.filterWaiters.map(waiter => waiter.id);
    
    if (resaFilters.filterCustomerStatus.length === 0 && 
      filterStatutNumbers.length === 0 && 
      resaFilters.filterWaiters.length === 0 && 
      resaFilters.filterNom === "") {
        return true;
      }
      
      return (
        ((resaFilters.filterNom === "" || (customer?.lastName.toLowerCase().includes(resaFilters.filterNom.toLowerCase()))) ||
        (resaFilters.filterNom === "" || (customer?.firstName.toLowerCase().includes(resaFilters.filterNom.toLowerCase()))) ) &&
        // (!startDate || new Date(resa.date) >= startDate) &&
        // (!endDate || new Date(resa.date) <= new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1))
        
        (filterCustomerStatusNumbers.length === 0 || filterCustomerStatusNumbers.includes(Number(resa.customerActionId))) &&
        (filteredWaiters.length === 0 || filteredWaiters.includes(resa.waiterId)) &&
        (filterStatutNumbers.length === 0 || filterStatutNumbers.includes(Number(resa.reservationStatusId)))
      );
    });

    useEffect(() => {
      const sortedResa = sortReservations(currentReservations, sortFilter);
      setSortedReservations(sortedResa);
  }, [currentReservations, sortFilter]); // Ajoute customers ici
  
  const convertTimeToDate = (timeString: string, date: string) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const resultDate = new Date(date); // Copie de la date passée ou de la date actuelle
    
    resultDate.setHours(hours, minutes, seconds, 0); // Définir l'heure, les minutes, et les secondes
    return resultDate;
  };
  
  const useFilters = () => {
    openFiltersDrawer();
  };
  
  const newResa = () => {
    setMode(FormMode.New);
    setResaNew(new Reservation());
    setState(prevState => ({
      ...prevState,
      formReservation: new Reservation(),
      formCustomer: new Customer(),
      allergiesResa: [],
      foodPrefResa: [],
      allergiesCust: [],
      foodPrefCust: [],
      menusResa: []
    }))
    openDrawer();
  };
  
  const openFiltersDrawer = () => {
    const filterDrawerEl: any = drawerFilterRef.current;
    filterDrawerEl.open();
  }
  
  const closeFiltersDrawer = () => {
    const filterDrawerEl: any = drawerFilterRef.current;
    filterDrawerEl.close();
  }
  
  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }
  
  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }
  
  const ResaFormTitle = () => {
    switch (mode) {
      case FormMode.New:
      return 'Nouvelle réservation';
      case FormMode.Edit:
      return 'Edition de la réservation';
      default:
      return '';
    }
  }
  
  // const resaFormResa = () => {
  //   switch (mode) {
  //     case FormMode.New:
  //     return resaNew;
  //     case FormMode.Edit:
  //     return resaEdit;
  //     default:
  //     return new Reservation();
  //   }
  // }
  
  const reset = () => {
    setMode(FormMode.None);
    setResaNew(new Reservation());
    setState(prevState => ({
      ...prevState,
      formReservation: new Reservation(),
      formCustomer: new Customer(),
      allergiesResa: [],
      foodPrefResa: [],
      allergiesCust: [],
      foodPrefCust: [],
      menusResa: []
    }))
    fecthReservationsToday();
  }
  
  // const submitNew = (newCustomers: Array<Customer>, newReservations: Array<Reservation>) => {
  //   let reservationId = reservations.length ? Math.max(...reservations.map(r => r.id)) + 1 : 1;
  //   let customerId = currentCustomers.length ? Math.max(...currentCustomers.map(r => Number(r.idCustomer))) + 1 : 1;
  //   let newCustomerId: number = 0;
  //   const createdDate = Date.now();
  
  //   for (const newCustomerIndex in newCustomers) {
  //     newCustomers[newCustomerIndex].idCustomer = customerId + Number(newCustomerIndex);
  //     newCustomerId = Number(newCustomers[newCustomerIndex].idCustomer)
  //   }
  
  //   for (const newResaIndex in newReservations) {
  //     let newResaEvent = new ReservationEvent();
  
  //     if (Number(newReservations[newResaIndex].status) === ReservationStatus.Cancel) {
  //       newResaEvent = new ReservationEvent(EventType.Cancel, userName, createdDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Pending) {
  //       newResaEvent = new ReservationEvent(EventType.Pending, userName, createdDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Confirmed) {
  //       newResaEvent = new ReservationEvent(EventType.Confirmed, userName, createdDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.WaitingCB) {
  //       newResaEvent = new ReservationEvent(EventType.WaitingCB, userName, createdDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Gone) {
  //       newResaEvent = new ReservationEvent(EventType.Gone, userName, createdDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.NoShow) {
  //       newResaEvent = new ReservationEvent(EventType.NoShow, userName, createdDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.PartiallySeated) {
  //       newResaEvent = new ReservationEvent(EventType.PartiallySeated, userName, createdDate);
  //     }else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Seated) {
  //       newResaEvent = new ReservationEvent(EventType.Seated, userName, createdDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.WaitingList) {
  //       newResaEvent = new ReservationEvent(EventType.WaitingList, userName, createdDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.None) {
  //       newResaEvent = new ReservationEvent(EventType.None, userName, createdDate);
  //     }
  
  
  //     newReservations[newResaIndex].id = reservationId + Number(newResaIndex);
  //     newReservations[newResaIndex].customerId = newCustomerId;
  //     newReservations[newResaIndex].events = [newResaEvent];
  //   }
  
  //   setReservations([...reservations, ...newReservations]);
  //   setCurrentCustomers([...currentCustomers, ...newCustomers]);
  //   setCurrentReservations([...currentReservations, newReservations[0]]);
  //   setCurrentCustomers([...currentCustomers, newCustomers[0]]);
  
  //   reset();
  //   closeDrawer();
  // }
  
  const submitNew = async (newCustomer: Customer, newResa: Reservation, allergieResaIds: Number[], foodPrefResaIds: Number[], allergieCustIds: Number[], foodPrefCustIds: Number[], menusResaIds: Number[]) => {    try {   
    const response = await axios.post(`${apiUrl}/ResaRestau/AddResaAndCustomer`, 
            { customer: newCustomer, 
              reservation: newResa, 
              allergiesResaIds: allergieResaIds, 
              foodPrefResaIds: foodPrefResaIds,
              allergiesCustIds: allergieCustIds,
              foodPrefCustIds: foodPrefCustIds,
              menusResaIds: menusResaIds,
              },
            { headers: { "Content-Type": "application/json" } }
        );
        // const newReservation = response.data
        // if (response.status === 200){
        //   const reservationId = newReservation.id;
        //   const customerId = newReservation.customerId;
        // }
    } catch (error) {
        console.log("ERROR POST RESERVATION: ", error);
    }
    reset();
    closeDrawer();
  };

  const submitEdit = async (customer: Customer, reservation: Reservation, allergieResaIds: Number[], foodPrefResaIds: Number[], allergieCustIds: Number[], foodPrefCustIds: Number[], menusResaIds: Number[]) => {
    
    try {
      const response = await axios.put(`${apiUrl}/ResaRestau/UpdateResaAndCustomer`, 
          { customer: customer, 
            reservation: reservation, 
            allergiesResaIds: allergieResaIds, 
            foodPrefResaIds: foodPrefResaIds,
            allergiesCustIds: allergieCustIds,
            foodPrefCustIds: foodPrefCustIds, 
            menusResaIds: menusResaIds},
          { headers: { "Content-Type": "application/json" } }
      );
    } catch (error) {
        console.log("ERROR UPDATE RESERVATION: ", error);
    }
    reset();
    closeDrawer();
    // fetchRestauId();
  }
  
  // const submitEdit = (newCustomers: Array<Customer>, newReservations: Array<Reservation>) => {
  //   const reservationId = reservations.length ? Math.max(...reservations.map(r => r.id)) + 1 : 1;
  //   const nowDate = Date.now();
  //   const reservationsCopy = [...reservations];
  
  //   let newResaCount = 0;
  //   for (const newResaIndex in newReservations) {
  //     let newResaEvent = new ReservationEvent();
  
  //     if (Number(newReservations[newResaIndex].status) === ReservationStatus.Cancel) {
  //       newResaEvent = new ReservationEvent(EventType.Cancel, userName, nowDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Pending) {
  //       newResaEvent = new ReservationEvent(EventType.Pending, userName, nowDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Confirmed) {
  //       newResaEvent = new ReservationEvent(EventType.Confirmed, userName, nowDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.WaitingCB) {
  //       newResaEvent = new ReservationEvent(EventType.WaitingCB, userName, nowDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Gone) {
  //       newResaEvent = new ReservationEvent(EventType.Gone, userName, nowDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.NoShow) {
  //       newResaEvent = new ReservationEvent(EventType.NoShow, userName, nowDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.PartiallySeated) {
  //       newResaEvent = new ReservationEvent(EventType.PartiallySeated, userName, nowDate);
  //     }else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Seated) {
  //       newResaEvent = new ReservationEvent(EventType.Seated, userName, nowDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.WaitingList) {
  //       newResaEvent = new ReservationEvent(EventType.WaitingList, userName, nowDate);
  //     } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.None) {
  //       newResaEvent = new ReservationEvent(EventType.None, userName, nowDate);
  //     }
  
  //     newReservations[newResaIndex].events.push(newResaEvent);
  
  //     if (!newReservations[newResaIndex].id) {
  //       newReservations[newResaIndex].id = reservationId + newResaCount;
  //       newResaCount++;
  //       reservationsCopy.push(newReservations[newResaIndex]);
  //     } else {
  //       const currentResaIndex = reservationsCopy.findIndex(r => r.id === newReservations[newResaIndex].id);
  
  //       if (currentResaIndex !== -1) {
  //         reservationsCopy[currentResaIndex] = newReservations[newResaIndex];
  //       }
  //     }
  //   }
  
  //   const customerId = reservations.length ? Math.max(...reservations.map(r => r.id)) + 1 : 1;
  //   const customersCopy = [...currentCustomers];
  //   let newCustomerCount = 0;
  //   for (const newCustomerIndex in newCustomers) {
  
  //     if (!newCustomers[newCustomerIndex].idCustomer) {
  //       newCustomers[newCustomerIndex].idCustomer = customerId + newCustomerCount;
  //       newCustomerCount++;
  //       customersCopy.push(newCustomers[newCustomerIndex]);
  //     } else {
  //       const currentCustomerIndex = customersCopy.findIndex(r => r.idCustomer === newReservations[newCustomerIndex].id);
  
  //       if (currentCustomerIndex !== -1) {
  //         customersCopy[newCustomerIndex] = newCustomers[newCustomerIndex];
  //       }
  //     }
  //   }
  //   setReservations(reservationsCopy);
  //   setCurrentCustomers(customersCopy);
  //   setCurrentReservations(reservationsCopy.filter((r: Reservation) => new Date(moment(r.date).format('YYYY-MM-DD')).getTime() === currentDate));
  
  //   reset();   
  //   closeDrawer();
  // }
  
  
  // const submitEdit = (newCustomers: Customer) => {
  //   // const reservationId = reservations.length ? Math.max(...reservations.map(r => r.id)) + 1 : 1;
  //   // const nowDate = Date.now();
  //   // const reservationsCopy = [...reservations];
    
  //   // // let newResaCount = 0;
  //   // // for (const newResaIndex in newReservations) {
  //   // //   let newResaEvent = new ReservationEvent();
    
  //   // //   if (Number(newReservations[newResaIndex].status) === ReservationStatus.Cancel) {
  //   // //     newResaEvent = new ReservationEvent(EventType.Cancel, userName, nowDate);
  //   // //   } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Pending) {
  //   // //     newResaEvent = new ReservationEvent(EventType.Pending, userName, nowDate);
  //   // //   } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Confirmed) {
  //   // //     newResaEvent = new ReservationEvent(EventType.Confirmed, userName, nowDate);
  //   // //   } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.WaitingCB) {
  //   // //     newResaEvent = new ReservationEvent(EventType.WaitingCB, userName, nowDate);
  //   // //   } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Gone) {
  //   // //     newResaEvent = new ReservationEvent(EventType.Gone, userName, nowDate);
  //   // //   } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.NoShow) {
  //   // //     newResaEvent = new ReservationEvent(EventType.NoShow, userName, nowDate);
  //   // //   } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.PartiallySeated) {
  //   // //     newResaEvent = new ReservationEvent(EventType.PartiallySeated, userName, nowDate);
  //   // //   }else if (Number(newReservations[newResaIndex].status) === ReservationStatus.Seated) {
  //   // //     newResaEvent = new ReservationEvent(EventType.Seated, userName, nowDate);
  //   // //   } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.WaitingList) {
  //   // //     newResaEvent = new ReservationEvent(EventType.WaitingList, userName, nowDate);
  //   // //   } else if (Number(newReservations[newResaIndex].status) === ReservationStatus.None) {
  //   // //     newResaEvent = new ReservationEvent(EventType.None, userName, nowDate);
  //   // //   }
    
  //   // //   newReservations[newResaIndex].events.push(newResaEvent);
    
  //   // //   if (!newReservations[newResaIndex].id) {
  //   // //     newReservations[newResaIndex].id = reservationId + newResaCount;
  //   // //     newResaCount++;
  //   // //     reservationsCopy.push(newReservations[newResaIndex]);
  //   // //   } else {
  //   // //     const currentResaIndex = reservationsCopy.findIndex(r => r.id === newReservations[newResaIndex].id);
    
  //   // //     if (currentResaIndex !== -1) {
  //   // //       reservationsCopy[currentResaIndex] = newReservations[newResaIndex];
  //   // //     }
  //   // //   }
  //   // // }
    
  //   // const customerId = reservations.length ? Math.max(...reservations.map(r => r.id)) + 1 : 1;
  //   // const customersCopy = [...currentCustomers];
  //   // let newCustomerCount = 0;
  //   // for (const newCustomerIndex in newCustomers) {
    
  //   //   if (!newCustomers[newCustomerIndex].idCustomer) {
  //   //     newCustomers[newCustomerIndex].idCustomer = customerId + newCustomerCount;
  //   //     newCustomerCount++;
  //   //     customersCopy.push(newCustomers[newCustomerIndex]);
  //   //   } else {
  //   //     const currentCustomerIndex = customersCopy.findIndex(r => r.idCustomer === newReservations[newCustomerIndex].id);
    
  //   //     if (currentCustomerIndex !== -1) {
  //   //       customersCopy[newCustomerIndex] = newCustomers[newCustomerIndex];
  //   //     }
  //   //   }
  //   // }
  //   // setReservations(reservationsCopy);
  //   // setCurrentCustomers(customersCopy);
  //   // setCurrentReservations(reservationsCopy.filter((r: Reservation) => new Date(moment(r.date).format('YYYY-MM-DD')).getTime() === currentDate));
    
  //   // reset();   
  //   // closeDrawer();
  // }
  
  const resaFormSubmit = () => {
    switch (mode) {
      case FormMode.New:
      return submitNew;
      case FormMode.Edit:
      return submitEdit;
      default:
      return () => {};
    }
  }
  
  const ResaFormSubmitLabel = () => {
    switch (mode) {
      case FormMode.New:
      return 'Ajouter la réservation';
      case FormMode.Edit:
      return 'Modifier la réservation';
      default:
      return '';
    }
  }
  
  const editResa = (
    resa: Reservation,
    customer: Customer,
    allergiesResa: AllergiesReservation[],
    foodPrefResa: FoodPreferencesReservation[],
    allergiesCust: AllergiesCustomer[],
    foodPrefCust: FoodPreferencesCustomer[],
    menusResa: ReservationMenu[]
    ) => {
      setMode(FormMode.Edit);
      setState((prevState) => ({
        ...prevState,
        formReservation: resa,
        formCustomer: customer,
        allergiesResa: allergiesResa,
        foodPrefResa: foodPrefResa,
        allergiesCust: allergiesCust,
        foodPrefCust: foodPrefCust,
        menusResa: menusResa
      }));
      openDrawer();
    }
  
  const cancelResa = (resa: Reservation) => {
    setResaCancel(resa);
    openCancelDialog();
  }
  
  const retablirResa = (resa: Reservation) => {
    const resaCopy = [...reservations];
    const resaToUncancel = reservations.find(r => r.id === resa.id);
    if (resaToUncancel) {
      const uncancelResaPos = reservations.indexOf(resaToUncancel);
      
      resa.events = resa.events.filter(r => r.type !== EventType.Cancel);
      // resa.status = ReservationStatus.Pending
      
      resaCopy.splice(uncancelResaPos, 1, resa);
      setReservations(resaCopy);
      setCurrentReservations(resaCopy.filter((r: Reservation) => new Date(moment(r.date).format('YYYY-MM-DD')).getTime() === currentDate));
    }
  }
  
  const cancelResaConfirm = (resa: Reservation, reason: string) => {
    const resaCopy = [...reservations];
    const resaToUncancel = reservations.find(r => r.id === resaCancel.id);
    if (resaToUncancel) {
      const cancelResaPos = reservations.indexOf(resaToUncancel);
      
      resa.events.push(new ReservationEvent(
        EventType.Cancel,
        userName, 
        Date.now(),
        reason
      ));
      
      // resa.status = ReservationStatus.Cancel
      
      resaCopy.splice(cancelResaPos, 1, resa);
      setReservations(resaCopy);
      setCurrentReservations(resaCopy.filter((r: Reservation) => new Date(moment(r.date).format('YYYY-MM-DD')).getTime() === currentDate));
      reset();
      closeCancelDialog();
    }
  }
  
  const openCancelDialog = () => {
    const cancelDialogRefEl: any = cancelDialogRef.current;
    cancelDialogRefEl.open();
  }
  
  const closeCancelDialog = () => {
    const cancelDialogRefEl: any = cancelDialogRef.current;
    cancelDialogRefEl.close();
  }
    
    const clearFilters = () => {
      setResaFilters({
        filterCustomerStatus: [],
        filterWaiters: [],
        filterNom: "",
        filterReservationStatus: [],
      })
    }
    
    var lengthA = 0
    var lengthB = 0
    var lengthResa = 0
    
    const emptyFct = () =>{
      
    }

    useEffect(() =>{
      setFilteredResaCount(filteredReservations.length)
    }, [filteredReservations])
    
    const handleFilterRestauChange = (newId: Number) => {
      setState((prevState) => ({ ...prevState, currentRestauId: Number(newId) }));
    };
    
    const handleFilterServiceChange = (newType: string) => {
      setState((prevState) => ({ ...prevState, serviceType: newType }));

    };

    if (loading) {
      return <div>Loading...</div>;
    }
    else{
      return (
        <div>
        <div className="PrimaryHeader">
        <div className="PrimaryHeaderSeparator"></div>
        </div>
        <div className="ReservationTopActions">
        <div>
        <EstablishmentFilter
        filterEstablishmentId={state.currentRestauId}
        establishmentList={state.restaurants}
        onFilterChange={handleFilterRestauChange} // Ajoutez ceci
        />
        <ServiceTypeFilter
        filterService={state.serviceType}
        onFilterChange={handleFilterServiceChange}
        isContinuous={continuous}
        />
        </div>
        
        <div style={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
        <GnzButton label="Effacer filtres" onClick={clearFilters} color="#003671" backgroundColor="white"  />
        <GnzButton label="Filtres" onClick={useFilters} color="#003671" backgroundColor="#95E2AF" />
        <select value={sortFilter} onChange={(e) => setSortFilter(e.target.value)}>
        <option value=''>trier par</option>
        {/* <option value={ReservationFilter.Statut}>Statut</option>
        <option value={ReservationFilter.CustomerStatus}>Formule</option> */}
        <option value='AZ'>Nom A - Z</option>
        <option value='ZA'>Nom Z - A</option>
        </select>
        <GnzButton leftIcon={<GnzImage src={plus2} />} label="Nouvelle Réservation" onClick={newResa} color="#003671" backgroundColor="#95E2AF" /> 
        </div>
        
        </div>
        <GnzDrawer ref={drawerFilterRef} component={
          <ReservationFilters
          filterNom={filterNom}
          setFilterNom={setFilterNom}
          // filterStatus={filterStatus}
          // setFilterStatus={setFilterStatus}
          filterCustomerStatus={filterCustomerStatus}
          setFilterCustomerStatus={setFilterCustomerStatus}
          closeFilters={closeFiltersDrawer}
          filteredResaCount={filteredResaCount}
          currentWaitersList={state.waiterList}
          filterWaiter={filterWaiter}
          setFilterWaiter={setFilterWaiter}
          resaStatuses={state.reservationStatuses}
          customerStatuses={state.customerActions}
          resaFilters={resaFilters}
          setResaFilters={setResaFilters}
          />
        } />
        {state.currentServices.map((service: Service, index) => {
          const openingTime = service.heureDebut;
          //const openingTime = "12:00"
          const [openingHours, openingMinutes] = (openingTime.split(':'));
          const closingTime = service.heureFin;
          //const closingTime = "14:00"
          const [closingHours, closingMinutes] = (closingTime.split(':'));
          
          const filteredResa = filteredReservations.filter(resa => {
            const resaDate = new Date(resa.date);
            const resaHours = resaDate.getHours();
            const resaMinutes = resaDate.getMinutes();
            
            return (
              (resaHours > Number(openingHours) && resaHours < Number(closingHours)) ||
              (resaHours === Number(openingHours) && resaMinutes >= Number(openingMinutes)) ||
              (resaHours === Number(closingHours) && resaMinutes <= Number(closingMinutes))
            )
            
          })  
          
          const allergyInfoHover = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            const rect = event.currentTarget.getBoundingClientRect();
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            setAllergyInfoPos({ top: rect.bottom + scrollTop, left: rect.left });
            setAllergyInfoHovered(prev => ({ ...prev, [index]: true }));
          };
          
          const allergyInfoLeave = () => {
            setAllergyInfoHovered(prev => ({ ...prev, [index]: false }));
          };
          
          const foodPrefsInfoHover = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            const rect = event.currentTarget.getBoundingClientRect();
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            setFoodPrefsInfoPos({ top: rect.bottom + scrollTop, left: rect.left });
            setFoodPrefsInfoHovered(prev => ({ ...prev, [index]: true }));
          };
          
          const foodPrefsInfoLeave = () => {
            setFoodPrefsInfoHovered(prev => ({ ...prev, [index]: false }));
          };
          
          return (
            <div key={index} className="ReservationTable">
            <div className='resa-table-title'>
            {continuous ? 
              <h3>{state.currentRestaurant.nom} : Service Continu ({openingHours}:{openingMinutes}-{closingHours}:{closingMinutes})</h3> 
              : 
              <h3>{state.currentRestaurant.nom} : {index < 1 ? (index + 1) + "er" : (index + 1) + "ème"} Service ({openingHours}:{openingMinutes}-{closingHours}:{closingMinutes})</h3>
            }
            <div id='info-links'>
            <div className="modal-link" onMouseEnter={allergyInfoHover} onMouseLeave={allergyInfoLeave}>
            <p>Allergies</p>
            <FontAwesomeIcon icon={faCircleQuestion} id={`tooltipIcon_${index}`}/>
            {allergyInfoHovered[index] && (
              <div className='allergy-modal' style={{ position: 'absolute', top: allergyInfoPos.top, left: allergyInfoPos.left }}>
              <AllergyMod/>
              </div>
            )}
            </div>
            <div className="modal-link" onMouseEnter={foodPrefsInfoHover} onMouseLeave={foodPrefsInfoLeave}>
            <p>FoodPrefs</p>
            <FontAwesomeIcon icon={faCircleQuestion} id={`tooltipIcon_${index}`}/>
            {foodPredsInfoHovered[index] && (
              <div className='allergy-modal' style={{ position: 'absolute', top: foodPrefsInfoPos.top, left: foodPrefsInfoPos.left }}>
              <FoodPrefsMod />
              </div>
            )}
            </div>
            </div>
            </div>
            <ReservationTable
              customers={state.customers} 
              menus={allMenus}
              editReservation={editResa} 
              cancelReservation={cancelResa} 
              retablirReservation={retablirResa} 
              opening={service.heureDebut}
              closing={service.heureFin}
              waiterList={state.waiterList}
              service={service}
              restaurantId={state.currentRestauId}
              filter={sortFilter}
              resaFilters={resaFilters}
              reloadReservations={reloadReservations}
              filteredReservations={filteredResa}
            />
            <div className='table-bottom-btn'>
            <GnzButton id={`globalDisplay-btn-${index}`} label="Plan de table" onClick={emptyFct} color="white" backgroundColor="#1D62AF" />
            <GnzButton 
              onClick={openCalendarModal}
              backgroundColor="#95E2AF"
              color="#1D62AF"
              label="Visualisation globale"
              />
                {
                    showCalendarModal &&
                    <Calendar onClose={closeCalendarModal} />
                }
            </div>
            </div>
          )      
        }
      )}
      <GnzDrawer ref={drawerRef} component={
        <ReservationForm
          mode={mode}
          currentDate={currentDate}
          title={ResaFormTitle()} 
          closeForm={closeDrawer}
          menus={allMenus}
          customers={state.customers}
          submit={resaFormSubmit()}
          submitLabel={ResaFormSubmitLabel()}
          submitColor="#003671"
          restaurantsList={state.restaurants}
          restaurant={state.currentRestaurant}
          waiterList={state.waiterList}
          formCustomer={state.formCustomer}
          formReservation={state.formReservation}
          foodPrefs={state.foodPrefs}
          allergies={state.allergies}
          specialOccasions={state.specialOccasion}
          allergiesResa = {state.allergiesResa}
          foodPrefResa = {state.foodPrefResa}
          allergiesCust = {state.allergiesCust}
          foodPrefCust = {state.foodPrefCust}
          menusResa={state.menusResa}
          menuList = {state.menuList}
          customerActionsList={state.customerActions}
          resaStatusList={state.reservationStatuses}
          paymentTypeList={state.paymentTypeList}
          currentReservations={state.reservations}
          currentServices={state.currentServices}
          tables={state.tables}
        />
      } />
      <GnzDialog ref={cancelDialogRef} component={
        <CancelReservationForm
        resa={resaCancel}
        confirmSubmit={cancelResaConfirm}
        cancelSubmit={closeCancelDialog}
        />}
        />
        </div>
      );
    }
  }
  
  export default ServicesRestaurant;
