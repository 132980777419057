import Restaurant from "./Restaurant";

export default class TableModel {
    public id: number = 0;
    public numero: number = 0;
    public nbPersonne: number = 0;
    public restaurantId: number = 0;

    constructor(
        id: number = 0,
        numero: number = 0,
        nbPersonne: number = 0,
        restaurantId: number = 0       
    ) {
        this.id = id;
        this.numero = numero;
        this.nbPersonne = nbPersonne;
        this.restaurantId = restaurantId;
    }
}