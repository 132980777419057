import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect, useState } from 'react';
import { Service } from '../types/types';
import { serviceRestauService } from '../api/serviceRestauService';

interface UpdateTimeProps {
    onClose: () => void;
    initialService: Service | null;
    onUpdate: (updatedService: Service) => void;
}

const UpdateTime: React.FC<UpdateTimeProps> = ({ onClose, initialService, onUpdate }) => {

    const defaultService: Service = {
        id: 0,
        type: "nonstop",
        index: 0,
        heureDebutString: "",
        heureFinString: "",
        restaurantId: 0,
    };

    const [service, setService] = useState < Service > (initialService || defaultService);

    useEffect(() => {
        setService(initialService || defaultService);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialService]);

    const handleServiceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setService({ ...service, type: event.target.value });
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>, isStartTime: boolean) => {
        if (isStartTime) {
            const heureDebut = event.target.value;
            setService({...service, heureDebutString: heureDebut});
        } else {
            const heureFin = event.target.value;
            setService({...service, heureFinString: heureFin});
        }
    };

    const handleSubmit = async () => {
        try {
            if (service.heureDebutString === "" || service.heureFinString === "" || service.heureDebutString >= service.heureFinString) {
                alert("Veuillez renseigner une heure de début et de fin valide");
                return;
            }
            
            const updatedService = await serviceRestauService.putService(service.id, service);
            onUpdate(updatedService);
            onClose();
        } catch (error) {
            console.error("Error updating service:", error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-add'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1>Modifier l'horaire de service</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>

                <RadioGroup
                    row
                    className='radio-restau'
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={service.type}
                    onChange={handleServiceTypeChange}
                >
                    <FormControlLabel value="nonstop" control={<Radio className="custom-radio" />} label="Non-Stop" />
                    <FormControlLabel value="lunch" control={<Radio className="custom-radio" />} label="Déjeuner" />
                    <FormControlLabel value="diner" control={<Radio className="custom-radio" />} label="Dîner" />
                </RadioGroup>

                <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                    <div style={{ width: "30%", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="startTime">Du</label>
                        <input
                            type="time"
                            id="startTime"
                            value={service.heureDebutString.length > 0 ? service.heureDebutString : ""}
                            onChange={(e) => handleTimeChange(e, true)}
                        />
                    </div>

                    <div style={{ width: "30%", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="endTime">Au</label>
                        <input
                            type="time"
                            id="endTime"
                            value={service.heureFinString.length > 0 ? service.heureFinString : ""}
                            onChange={(e) => handleTimeChange(e, false)}
                        />
                    </div>
                </div>
                <button className='restau-btn restau-modal-btn' onClick={handleSubmit}>
                    Appliquer
                </button>
            </div>
        </Modal>
    );
}

export default UpdateTime;