import { useEffect, useRef, useState } from 'react';
import Customers from '../../../../models/Customers';
import FormMode from '../../../../enums/FormMode';
import GnzDrawer from '../../../shared/gnz-drawer/GnzDrawer';
// import ProfilClientsPreferenceForm from '../ProfilClientPreferencesForm/ProfilClientsPreferenceForm'
import ProfilClientsPreferenceForm from '../../ProfilClienteDetails/ProfilClientsDetailsForm/ProfilClientsTagsForm'
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Customer from '../../../../models/Customers';
import GnzButton from '../../../shared/gnz-button/GnzButton';
import GnzImage from '../../../shared/gnz-image/GnzImage';
import plus2 from '../../../../images/shared/plus2.png';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import { API_MEWS_URL } from '../../../../ConfigUrl';
import './ProfilClientPreferencesDetails.css';


interface IProfilClientPreferencesDetails {
}


function ProfilClientPreferencesDetails(props: IProfilClientPreferencesDetails) {
  const navigate = useNavigate();
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const drawerPreferences = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const myParam = localStorage.getItem('idCustomer')
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [Customers, Customer] = useState<Array<Customer>>([]);
  const [allergiesCustomer, AllergiesCustomer] = useState<Array<AllergiesCustomer>>([]);
  const [foodPreferencesCustomer, FoodPreferencesCustomer] = useState<Array<FoodPreferencesCustomer>>([]);
  const [urlProfile, setUrlProfile] = useState(''); 

  const Url = API_MEWS_URL;
  
  useEffect(() => {   
      setUrlProfile(localStorage.getItem('TypeProfilDetails') ?? "clientsDetails"); 
  }, [])

  const ClientPreferences = () => {
    setMode(FormMode.New);
    openDrawerPreferences();
  };

  const openDrawerPreferences = () => {
    const drawerRefEl: any = drawerPreferences.current;
    drawerRefEl.open();
  }

  const closePreferencesEdit = () => {
    const drawerRefEl: any = drawerPreferences.current;
    drawerRefEl.close();
  }

  useEffect(() => {
    axios.get(Url + 'AllergiesCustomer/' + myParam)
      .then(res => AllergiesCustomer(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPreferenceCustomer/' + myParam)
      .then(res => FoodPreferencesCustomer(res.data));
  }, [])

  const handleChange = (event: any) => {
    axios.get(Url + 'Reservation/' + startDate + '/' + event.target.value)
      .then(res => Customer(res.data));

  };

  const GotoRute = (event: any) => {
    switch (event.target.value) {
      case "1":
        window.location.href = `/`+ urlProfile;
        break;
      case "2":
        window.location.href = `/FeedbackClient`;
        break;
      case "3":
        window.location.href = `/clientsPreferences`;
        break;      
    }
  }

  return (
    <div className='userPreference'>
      <h3 className='userPreference_header'>Fiche Client</h3>

      <div className='userPreference_content'>
        <div className="ProfilClientsPreferenceTopActions">
        <select className='selectSyle' onChange={GotoRute}>
            <option value="1" onClick={() => navigate(`/`+urlProfile)} onSelect={() => navigate(`/`+urlProfile)}>Profil</option>
            <option value="2" onClick={() => navigate(`/FeedbackClient`)} onSelect={() => navigate(`/FeedbackClient`)}  >Feedback</option>
            <option value="3" onClick={() => navigate(`/clientsPreferences`)} onSelect={() => navigate(`/clientsPreferences`)} >Préférences</option>
            {/* <option value={3}>Email</option> */}
          </select>
        </div>

        <div className="userPreference_info">
          <div className="userPreference_Items">
            <p><strong>Concierge</strong>:</p>
            <p>Voiture model Mercedes Class A TG501PV</p>
          </div>

          <div className="userPreference_Items">
            <p><strong>Housekeeping</strong>:</p>
            <p>Minibar</p>
            <p>Eau mineral temperature ambiente</p>
          </div>

          <div>
            <p>chambre lumineuse</p>
          </div>

          <div className="userPreference_Items">
            <p><strong>Liberie</strong>:</p>
            <p>chambre lumineuse</p>
          </div>


          <div className="userPreference_Items">
            <p><strong>Rest..HOtel</strong>:</p>           
          </div>

<div>
  <h4>Allergies</h4>         
</div>

<div>
  {allergiesCustomer.map(r => (
    <div className='TagsLine'>
      {r.allergies.displayAs} <img className="TagImg" src={`data:image/jpeg;base64,${r.allergies.image}`} alt="" />
    </div>
  ))}
</div>

<div >
  <h4>FoodPreferences</h4>         
</div>

<div>
  {foodPreferencesCustomer.map(r => (
    <div className='TagsLine'>
      {r.food_Preferences.displayAs} <img className="TagImg" src={`data:image/jpeg;base64,${r.food_Preferences.image}`} alt="" />
    </div>
  ))}
</div>



          {/* <div className="moreContent">
            {allergiesCustomer.map((r) => (
              // <p>{r.allergies.displayAs}</p>
              <img className="TagImg" src={`data:image/jpeg;base64,${r.allergies.image}`} alt="" />
            ))}
            {foodPreferencesCustomer.map((r) => (
              // <p>{r.food_Preferences.displayAs}</p>
              <img className="TagImg" src={`data:image/jpeg;base64,${r.food_Preferences.image}`} alt="" />
            ))}

          </div> */}
        </div>

        <div className='ClientPreferencesBtn'>
          <GnzButton leftIcon={<GnzImage src={plus2} />} label="Preference" onClick={ClientPreferences} color="#ffffff" backgroundColor="#1D62AF" />
        </div>

        <GnzDrawer ref={drawerPreferences} component={
          <ProfilClientsPreferenceForm
            myParam={myParam ?? "0"}
            allergiesCustomer={allergiesCustomer}
            foodPreferencesCustomer={foodPreferencesCustomer}
            closeForm={closePreferencesEdit}
          />
        } />

      </div>
    </div>
  );
}

export default ProfilClientPreferencesDetails