import moment from 'moment';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import './GnzDatePicker.css';

interface IGnzDatePicker {
  className: string,
  currentDate: number,
  onChange: any,
  disabled?: boolean
}

function GnzDatePicker(props: IGnzDatePicker) {
  return (
    <DatePicker className={props.className} locale={locale} value={dayjs(moment(props.currentDate).format('MM/DD/YY'))} format="DD/MM/YY" onChange={props.onChange} disabled={props.disabled}/>
  );
}

export default GnzDatePicker;

