import { useEffect, useState } from 'react';
import Feedback from '../../../models/Feedback';
import './FeedbackForm.css';
import Status from '../../../models/Status';
import ServicesItems from '../../../models/ServicesItems';
import StatusClient from '../../../models/StatusClient';
import FormMode from '../../../enums/FormMode';
import axios from 'axios';
import { API_MEWS_URL }   from '../../../ConfigUrl';
import Customer from '../../../models/Customers';

interface IFeedbackForm {
  mode: FormMode,
  feedbackType: string,
  feedback?: Feedback,
  startDate: any,
  endDate: any,
  title: string,
  closeForm: () => void,
  customerId: string,
}

function FeedbackForm(props: IFeedbackForm) {
  const [comment, setComment] = useState('');
  const [parameter, setParameter] = useState('');
  const [resolution, setResolution] = useState('');
  const [status, setStatus] = useState('');
  const [customerId, setcustomerId] = useState('');
  const [idFeedback, setIdFeedback] = useState('');
  const [idReservation, setidReservation] = useState('');
  const [customers, setCustomers] = useState<Array<Customer>>([]);
  const [service, setservice] = useState('');
  const [statusImg, setStatusImg] = useState('');
  const [StatusFilter, SetStatusFilter] = useState<Array<Status>>([]);
  const [ServicesItemsFilter, SetServicesItems] = useState<Array<ServicesItems>>([]);
  const [StatusClientFilter, StatusClient] = useState<Array<StatusClient>>([]);
  const _idReservation = localStorage.getItem('idReservation');
  const _TypeClient = localStorage.getItem('TypeClient');  
  const Url = API_MEWS_URL;

  useEffect(() => {
    axios.get(Url + 'Status')
      .then(res => SetStatusFilter(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'ServicesItems')
      .then(res => SetServicesItems(res.data));
  }, [])

  useEffect(() => {
    if (props.feedback == null) {
    //   setcustomerId(props.feedback.customers);
    // } else {
      axios.get(Url + 'Customers/GetCustomersReservations/' + props.startDate + '/' + props.endDate)
        .then(res => setCustomers(res.data));
    }
  }, [])


  const LoadReservationCustomer = async () => {
    setCustomers([]);
    axios.get(Url + 'Customers/GetCustomersByParameter/' + parameter)
    .then(res => setCustomers(res.data));
  }

  useEffect(() => {
    axios.get(Url + 'StatusClient')
      .then(res => StatusClient(res.data));
  }, [])

  useEffect(() => {
    setcustomerId(props.customerId ?? 0);
    if(_TypeClient == "H"){
      setidReservation(_idReservation ?? "");
      
    }
    if (props.feedback != null && props.mode != 3) {
      setcustomerId(props.feedback.customerId);
      setComment(props.feedback.commerntarie);
      setResolution(props.feedback.solution);
      setStatus(props.feedback?.idStatus);
      setservice(props.feedback.idServicesItems);
      setIdFeedback(props.feedback.idFeedback);
    }
  }, [])

  const handleChange = async () => {

    switch (props.mode) {
      case 1:     
      case 3:     
          try {
            const response = await  axios.post(Url + 'Feedback', {
              "customerId": customerId,
              "idReservation": idReservation,
              "commerntarie": comment,
              "idStatus": status,
              "idServicesItems": service,
              "solution": resolution
            })
              .then();
          } catch (error) {
            console.error('Hubo un error:', error);
          } finally {
            props.closeForm();
            if(props.feedbackType == 'FeedbackClient'){
              window.location.href = `/FeedbackClient`;
            }else{
              window.location.href = `/Feedback`;
            }           
          }  
        break;

      case 2:     
          try {
            const response = await axios.put(Url + 'Feedback/' + idFeedback, {
              "IdFeedback": idFeedback,
              "customerId": customerId,
              "commerntarie": comment,
              "idStatus": status,
              "idServicesItems": service,
              "solution": resolution
            })
              .then();
          } catch (error) {
            console.error('Hubo un error:', error);
          } finally {
            props.closeForm();
            if(props.feedbackType == 'FeedbackClient'){
              window.location.href = `/FeedbackClient`;
            }else{
              window.location.href = `/Feedback`;
            }     
          }   
        break;
    }
  };

  var Clients = false;  // Cambiar a false para ver el otro caso
  var EditMode = false;  // Cambiar a false para ver el otro caso
  var AddMode = false;

  if (props.customerId == "") {
    Clients = true;
  }
  if (props.mode == 2) {
    Clients = false;
    EditMode = true;
  }
  if (props.mode == 3) {
    Clients = false;
    EditMode = false;
    AddMode = true;
  }

  return (
    <div className="FeedbackForm">
      <div className="FeedbackFormTitle">{props.title}</div>
      <div className="FeedbackFormClose" onClick={props.closeForm}>X</div>
      <div className="PrimaryHeader">
        <div className="PrimaryHeaderSeparator"></div>
      </div>
      {Clients &&
        <div>
          <div className="FeedbackFormInputTitle">Client</div>

          <input className='InputStyle' value={parameter} onChange={(e) => setParameter(e.target.value)}/>
          <button className='ButtonStyle' onClick={LoadReservationCustomer}>Cerca</button>      

          <select className="FeedbackFormSelect" value={customerId} onChange={(e) => setcustomerId(e.target.value)} >
            <option value={0}> </option>
            {customers.map(items => (
              <option value={items.idCustomer}> {items.lastName} {items.firstName}</option>
            ))}
          </select>
        </div>}

      {EditMode &&
        <div>
          <div className="FeedbackFormInputTitle">Feedback - {props.feedback?.customers?.lastName}
            {props.feedback?.customers?.firstName}, chambre {props.feedback?.reservations?.number ?? "Pending.."} </div>
        </div>}

        {AddMode &&
        <div>
          <div className="FeedbackFormInputTitle"> {props.feedback?.customers?.lastName}  {" "}
            {props.feedback?.customers?.firstName} </div>
        </div>}


      <div className="FeedbackFormInputTitle">Commentaire</div>
      <textarea className='FeedbackFormTextarea' value={comment} onChange={(e) => setComment(e.target.value)} spellCheck={false}></textarea>
      <div className="FeedbackFormInputTitle">Status</div>
      <select className="FeedbackFormSelect" value={status} onChange={(e) => setStatus(e.target.value)}>
        <option value={0}> </option>
        {StatusFilter.map(items => (
          <option value={items.idStatus}>{items.statusName}</option>
        ))}
      </select>
      <div className="FeedbackFormInputTitle">Service</div>
      <select className="FeedbackFormSelect" value={service} onChange={(e) => setservice(e.target.value)}>
        <option value={0}> </option>
        {ServicesItemsFilter.map(items => (
          <option value={items.idServicesItems}>{items.servicesName}</option>
        ))}
      </select>
      <div className="FeedbackFormInputTitle">Résolution</div>
      <textarea className='FeedbackFormTextarea' value={resolution} onChange={(e) => setResolution(e.target.value)} spellCheck={false}></textarea>
      <div className="FeedbackFormInputTitle">Status Client</div>
      <form>
        <div>
          {StatusClientFilter.map(items => (
            <label><input type="radio" value={statusImg} id={items.statusClientName} />
              {/* <p><RadioButton value={statusImg}  onChange={(e) => setStatusImg(e.value)} variant="filled"/>  */}
              <img className="TagLogo" src={`data:image/jpeg;base64,${items.statusClientImage}`} alt="" />
            </label>
          ))}
        </div>
      </form>

      <div className="ProfilClientFormActions">
        <button className='ValiderButton' onClick={handleChange}>Valider</button>
      </div>
    </div>
  );
}

export default FeedbackForm;
