
import { useRef, useState } from 'react';
import { format } from 'date-fns';
import Feedback from '../../../models/Feedback';
import GnzDrawer from '../../shared/gnz-drawer/GnzDrawer';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import CancelFeedbackForm from '../CancelFeedbackForm/CancelFeedbackForm';
import FormMode from '../../../enums/FormMode';


import './FeedbackTableItems.css';

interface IFeedbackTableItems {
    feedback: Feedback
}

interface FeedbackTableItemsClients {
    itemTitle: string;
    itemDetails: string;
}

export default function FeedbackTableItems(props: IFeedbackTableItems) {
    const [show, setShow] = useState(true);
    const drawerRef = useRef<HTMLDivElement>(null);  
    const drawerAnullerRef = useRef<HTMLDivElement>(null);
    const [feedbackCancel, setFeedbackCancel] = useState<Feedback>(new Feedback());
    const [feedbackEdit, setFeedbackEdit] = useState<Feedback>(new Feedback());
    const [mode, setMode] = useState<FormMode>(FormMode.None);
    const _infoItineraire: FeedbackTableItemsClients[] = [
        { itemTitle: "Dates", itemDetails: format(props.feedback.createDate, 'dd/MM/yyyy') },
        { itemTitle: "Heure", itemDetails: format(props.feedback.createDate, 'HH:mm:ss') },
        {
            itemTitle: "Dates séjour", itemDetails: format(props.feedback.reservations?.startUtcDate ?? new Date(), 'dd/MM/yyyy') + " a " +
                format(props.feedback.reservations?.endUtcDate ?? new Date(), 'dd/MM/yyyy')
        },
        { itemTitle: "Statut", itemDetails: props.feedback?.status?.statusName ?? "-" },
        { itemTitle: "Commentaire", itemDetails: props.feedback.commerntarie },
        { itemTitle: "Service", itemDetails: props.feedback.servicesItems.servicesName },
        { itemTitle: "Initiales", itemDetails: "AI" }
    ];
    const closeDrawer = () => {
        const drawerRefEl: any = drawerRef.current;
        drawerRefEl.close();
    }

    const editFeedback = () => {
        setMode(FormMode.Edit);
        setFeedbackEdit(props.feedback);
        openDrawer();
    }

    const openDrawer = () => {
        const drawerRefEl: any = drawerRef.current;
        drawerRefEl.open();
    }

    const closeAnulerDrawer = () => {
        const drawerAnuller: any = drawerAnullerRef.current;
        drawerAnuller.close();
    }

    const AnullerFeedback = () => {
        // setMode(FormMode.Edit);
        setFeedbackCancel(props.feedback);
        openAnullerDrawer();
    }

    const openAnullerDrawer = () => {
        const drawerAnuller: any = drawerAnullerRef.current;
        drawerAnuller.open();
    }

    return (
        <div>
            <a className='feedbackStyle' type="button" onClick={() => {setShow(!show)}}>
                {show ? ' v ' : ' ^'}
            </a>

            {show ? (
                <div>

                </div>
            ) : (
                <div className='tableStyle'>
                    <div>
                        <table>                            
                            <tbody >
                                {_infoItineraire.map((r, index) => (
                                    <tr>
                                        <td className='tableItinereire_data'><strong>{r.itemTitle}</strong></td>
                                        <td className='tableItinereire_data'>{r.itemDetails}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>

                    <div>
                        <a className='tableXAnnuler_data' onClick={editFeedback} >
                            <strong> * </strong>
                            <td className='tableAnnuler_data'> Modifier</td>
                            </a>
                        <a className='tableXAnnuler_data' onClick={AnullerFeedback}>
                            <strong> X </strong>
                            <td className='tableAnnuler_data'>  Annuler</td>
                            </a>
                    </div>
                </div>
            )}

            <GnzDrawer ref={drawerRef} component={
                <FeedbackForm
                    mode={mode}
                    feedbackType={'Feedback'}
                    feedback={feedbackEdit}
                    startDate={null}
                    endDate={null}
                    title={"Edition de la feedback"}
                    closeForm={closeDrawer}
                    customerId={""}
                />
            } />
            {/* <GnzDialog ref={cancelDialogRef} component={
                <CancelFeedbackForm
                    feedback={feedbackCancel}
                />}
            /> */}

            <GnzDrawer ref={drawerAnullerRef} component={
                 <CancelFeedbackForm
                 feedback={feedbackCancel}
                 closeAnulerDrawer={closeAnulerDrawer}
             />}
              />
            {/* <GnzDialog ref={cancelAnullerRef} component={
                <CancelFeedbackForm
                    feedback={feedbackCancel}
                />}
            /> */}
        </div>
    );
}