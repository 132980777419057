import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import './Breakfast.css';
import calendar from '../../../images/icones_restau/calendar.svg';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import Reservation from '../../../models/ReservationModel';
import Restaurant from '../../../models/Restaurant';
import React from 'react';
import ReservationStatus from '../../../models/ReservationStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import PrimaryHeader from '../../shared/PrimaryHeader/PrimaryHeader';
import axios from 'axios';
import Service from '../../../models/ServiceModel';
import { reservationStatusService } from '../api/ReservationStatusService';
import { servicesRestauService } from '../api/ServicesRestauService';
import { reservationRestauService } from '../api/ReservationRestauService';
import { restaurantService } from '../api/RestaurantService';

interface IRow {
  restaurant: Restaurant,
  reservations: Reservation[],
}

function Row(props: IRow) { 
  const navigate = useNavigate();
  const location = useLocation(); 
  const [state, setState] = useState({
    services: [] as Service[],
    reservationStatus: [] as ReservationStatus[],
    confirmCount: 0,
    pendingCount: 0,
    waitingListCount: 0 
  })

  useEffect(() => {
    fetchAllStatuses();
  }, [])
  
  const fetchAllStatuses = async () => {
    const reservationStatuses = await reservationStatusService.getAllReservationStatus();
    setState((prevState) => ({ ...prevState, reservationStatus: reservationStatuses}))
  }
  
  useEffect(() => {
    const statusCount = {
      confirmCount: 0,
      pendingCount: 0,
      waitingListCount: 0
    };
    
    props.reservations.forEach((reservation: Reservation) => {
      if (reservation.restaurantId === Number(props.restaurant.id)) {
        const status = state.reservationStatus.find(s => s.id === reservation.reservationStatusId);
        if (status) {
          switch (status.state) {
            case 'confirmed':
            statusCount.confirmCount++;
            break;
            case 'pending':
            statusCount.pendingCount++;
            break;
            case 'waiting_list':
            statusCount.waitingListCount++;
            break;
            default:
            break;
          }
        }
      }
    });
    
    setState(prevState => ({
      ...prevState,
      confirmCount: statusCount.confirmCount,
      pendingCount: statusCount.pendingCount,
      waitingListCount: statusCount.waitingListCount
    }));
  }, [props.reservations, state.reservationStatus, props.restaurant.id]);
  
  
  return (
    <React.Fragment>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}>
    {props.restaurant.nom}
    </TableCell>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}> 
    {state.confirmCount}         
    </TableCell>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}>
    {state.pendingCount}
    </TableCell>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}>
    {state.waitingListCount}
    </TableCell>
    <TableCell style={{
      width: '18%',
      textAlign: 'center'
    }}>
    </TableCell> 
    
    <TableCell className="details_link" style={{
      width: '10%',
      textAlign: 'center'
    }}>
    {Array.from(new Set(state.services.map((s: Service) => s.type)))
      .sort((a, b) => {
        const order = ['lunch', 'diner'];
        return order.indexOf(a) - order.indexOf(b);
      })
      .map((type) => {
        let label;
        switch (type) {
          case 'nonstop':
          label = 'Service continu';
          break;
          case 'lunch':
          label = 'Déjeuner';
          break;
          case 'diner':
          label = 'Dîner';
          break;
          case 'breakfast':
          break;
        }
        return (
          <p key={type} onClick={() => 
            navigate(`${location.pathname}/services`, { 
              state: { restaurantIdParam: props.restaurant.id } 
            })
          }>
          {label}
          </p>
        );
      })
    }
    
    </TableCell>
    </TableRow>
    
    </React.Fragment>
  );
}

export default function Breakfast() {
  const { currentDate } = useSelector((state: RootState) => state.app); 
  const cd = moment(currentDate).startOf('day').format('YYYY-MM-DD');
  const [state, setState] = useState({
    reservations: [],
    restaurants: [] as Restaurant[],
  })   

  useEffect(() => {
    fetchAllRestaurants();
  }, [])

  useEffect(() => {
    fetchAllReservations();
  }, [cd, currentDate])
  
  const fetchAllReservations = async () => {
    const reservations = await reservationRestauService.getAllReservationRestau();
    var filteredReservations = (reservations.filter((r: Reservation) => {
      const reservationDate = moment(r.date).startOf('day').format('YYYY-MM-DD');
      return reservationDate === cd && !r.isBreakfast;
    }));
    setState((prevState) => ({ ...prevState, reservations: filteredReservations}))
  }
  
  const fetchAllRestaurants = async () => {
    const restaurants = await restaurantService.getAllRestaurants();
    setState((prevState) => ({ ...prevState, restaurants: restaurants}))
  }

  return (
    <div id='breakfast-table'>
    <PrimaryHeader src={calendar} title="Réservations du jour" />
    <TableContainer component={Paper} className="EstablishementTableContainer">   
    <Table aria-label="collapsible table">
    <TableHead>
    <TableRow>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>Etablissement</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>Confirmées</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>En attente</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>Waiting List</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '18%',
      textAlign: 'center'
    }}>Tables restantes</TableCell>
    <TableCell style={{
      backgroundColor: '#0B4B92',
      color: '#fff',
      fontFamily: `'Montserrat', sans-serif`,
      width: '10%'
    }}/>
    </TableRow>
    </TableHead>
    <TableBody>
    {state.restaurants.map((restaurant, key) => ( 
      <Row key={key} restaurant={restaurant} reservations={state.reservations}/>
    ))} 
    </TableBody>
    </Table>
    </TableContainer>
    </div>
  );
}
