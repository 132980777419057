import './PrimaryHeader.css';

interface IPrimaryHeader {
  src: string,
  title: string
}

function PrimaryHeader(props: IPrimaryHeader) {
  return (
    <div className="PrimaryHeader">
        <img className="PrimaryHeaderLogo" src={props.src} alt=""></img>
        <div className="PrimaryHeaderTitle">{props.title}</div>
        <div className="PrimaryHeaderSeparator"></div>
    </div>
  );
}

export default PrimaryHeader;
