import './RestaurantHotelDashboard.css';
import Paiement from './Paiement/Paiement';
import ConsignesRestau from './Consignes/ConsignesRestau';
import RestaurantHotel from './Restaurant/RestaurantHotel';
import { useEffect } from 'react';

export default function RestaurantHotelDashboard() {
  return (
    <div id="dashboard-restau">
      {/* <ConsignesRestau/> */}
      <RestaurantHotel/>
      <Paiement />
    </div>
  );
}
