import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { CardStatus, CardType, Service } from './CarteCreditInterfaces';

interface CarteCreditFiltersProps {
    filterService: string;
    setFilterService: React.Dispatch<React.SetStateAction<string>>;
    filterStatut: string;
    setFilterStatut: React.Dispatch<React.SetStateAction<string>>;
    filterTypeCarte: string;
    setFilterTypeCarte: React.Dispatch<React.SetStateAction<string>>;
    sortOption: string;
    setSortOption: React.Dispatch<React.SetStateAction<string>>;
    serviceList: Service[];
    cardTypeList: CardType[];
    statusCardList: CardStatus[];
}

function CarteCreditFilters(props: CarteCreditFiltersProps) {
    const navigate = useNavigate();
    const location = useLocation();
 
    const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.setSortOption(e.target.value);
    };

    useEffect(() => {
        const newSearch = new URLSearchParams(location.search);
    newSearch.set("carte", props.filterStatut);
    navigate(`/carte-credit?${newSearch.toString()}`);
  }, [props.filterStatut, navigate, location.search]);

    return (
        <div className='cartes_filters'>
            <select
                value={props.filterService}
                onChange={e => props.setFilterService(e.target.value)}
                className="filter-input"
            >
                <option value="">Tous les services</option>
                {props.serviceList.map((b, index) => (
                    <option key={index} value={b.label}>{b.label}</option>
                ))}
            </select>
            <select
                value={props.filterStatut}
                onChange={e => props.setFilterStatut(e.target.value)}
                className="filter-input"
            >
                {props.statusCardList.map((b, index) => (
                    <option key={index} value={b.status}>{b.status === "Confirmed" ? "Carte confirmée" : "Carte en attente"}</option>
                ))}
            </select>
            <select
                value={props.filterTypeCarte}
                onChange={e => props.setFilterTypeCarte(e.target.value)}
                className="filter-input"
            >
                <option value="">Tous les types</option>
                {props.cardTypeList.map((b, index) => (
                    <option key={index} value={b.type}>{b.type}</option>
                ))}
            </select>
            <select
                value={props.sortOption}
                onChange={handleSortChange} // Ajout de l'événement onChange
                className="filter-input"
            >
                <option value="default">Trier par</option>
                <option value="date">Date réservation</option>
                <option value="client">Client</option>
            </select>
        </div>
    );
}

export default CarteCreditFilters;