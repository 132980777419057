export default class Restaurant {
    public id: string = '';
    public nom: string = '';
    public email: string = '';
    public localisation: string = '';
    public website: string = '';
    public type: boolean = false;
    public hotelId: number = 0;

    constructor(
        id: string = '',
        nom: string = '',
        email: string = '',
        localisation: string = '',
        website: string = '',
        type: boolean = false,
        hotelId: number = 0,
        
    ) {
        this.id = id;
        this.nom = nom;
        this.email = email;
        this.localisation = localisation;
        this.website = website;
        this.type = type;
        this.hotelId = hotelId;  
    }
}