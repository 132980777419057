import SpecialOccasion from "./SpecialOccasion";
import ReservationEvent from "./ReservationEventModel";
import Waiter from "./Employee";
import ReservationStatus from "./ReservationStatus";
import FoodPreferencesReservation from "./FoodPreferencesReservation";
import AllergiesReservation from "./AllergiesReservation";
import Billing from "./Billing";
import Allergies from "./Allergies";
import Food_Preferences from "./Food_Preferences";
import Employee from "./Employee";
import TableModel from "./Table";
import { Table } from "../components/restaurant/types/types";
import Customer from "./Customers";

export default class Reservation {
    public id: number = 0;
    public date: Date = new Date();
    public adultCount: number = 0;
    public childrenCount: number = 0;
    public price: number = 0;
    public billingId: number | null = null;
    public billing: Billing = new Billing();
    public customerId: number = 0;
    public customer: Customer = new Customer();
    public gratuit: number = 0;
    public timestamp: number = 0;
    public events: Array<ReservationEvent> = [];
    public allergiesGroup: AllergiesReservation[] = [];
    public foodPrefsGroup: FoodPreferencesReservation[] = [];
    public reservationStatus: ReservationStatus = new ReservationStatus();
    public reservationStatusId: number = 0;
    public specialOccasion: SpecialOccasion = new SpecialOccasion();
    public specialOccasionId: number | null = null ;
    public restaurantId: number = 0;
    public tableId: number = 0;
    public table: TableModel = new TableModel();
    public isBreakfast: boolean = false;
    public waiterId: number = 0;
    public waiter: Employee = new Employee();    
    public customerActionId: number | null = null;
    public specialRequests: string[] = [];

    constructor(
        customerId: number = 0,
        customer: Customer = new Customer(),
        date: Date = new Date(),
        adultCount: number = 0,
        childrenCount: number = 0,
        price: number = 0,
        specialOccasion: SpecialOccasion = new SpecialOccasion(),
        specialOccasionId: number | null = null,
        billingId: number | null = null,
        billing: Billing = new Billing(),
        gratuit: number = 0,
        timestamp: number = 0,
        events: Array<ReservationEvent> = [], 
        reservationStatus: ReservationStatus = new ReservationStatus(),
        reservationStatusId: number = 0,
        allergiesGroup: Array<AllergiesReservation> = [],
        foodPrefsGroup: Array<FoodPreferencesReservation> = [],
        isBreakfast: boolean = false,
        restaurantId: number = 0,
        tableId: number = 0,
        table: TableModel = new TableModel(),
        waiterId: number = 0,
        waiter: Employee = new Employee(),
        id: number = 0,
        customerActionId: number | null = null,
        specialRequests: string[] = []
    ) {
        this.date = date;
        this.adultCount = adultCount;
        this.childrenCount = childrenCount;
        this.price = price;
        this.specialOccasion = specialOccasion;
        this.specialOccasionId = specialOccasionId;
        this.billingId = billingId;
        this.billing = billing;
        this.customerId = customerId;
        this.customer = customer;
        this.gratuit = gratuit;
        this.timestamp = timestamp;
        this.events = events;
        this.id = id;
        this.reservationStatus = reservationStatus;
        this.reservationStatusId = reservationStatusId;
        this.allergiesGroup = allergiesGroup;
        this.foodPrefsGroup = foodPrefsGroup;
        this.restaurantId = restaurantId;
        this.table = table;
        this.tableId = tableId;
        this.isBreakfast = isBreakfast;
        this.waiterId = waiterId;
        this.waiter = waiter;
        this.customerActionId = customerActionId;
        this.specialRequests = specialRequests;
    }
} 