import 'react-datepicker/dist/react-datepicker.css';
import './ReservationFilters.css';
import React, { useState } from 'react';
import CustomerStatus from '../../../../enums/CustomerEnums/CustomerStatus';
import GnzButton from '../../../shared/GnzButton/GnzButton';
// import ReservationStatus from '../../../../enums/ReservationEnums/ReservationStatus';
import {faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Employee from '../../../../models/Employee';
import { Button, capitalize, Checkbox, FormControlLabel, Grid, Input, TextField, Typography } from '@mui/material';
import ReservationStatus from '../../../../models/ReservationStatus';
import CustomerActions from '../../../../models/CustomersActions';

interface ResaFiltersType {
  filterNom: string;
  filterCustomerStatus: CustomerActions[];
  filterWaiters: Employee[];
  filterReservationStatus: ReservationStatus[];
}

interface ReservationFiltersProps {
    filterNom: string;
    setFilterNom: React.Dispatch<React.SetStateAction<string>>;
    // filterStatus: ReservationStatus[];
    // setFilterStatus: React.Dispatch<React.SetStateAction<ReservationStatus[]>>;
    filterCustomerStatus: CustomerStatus[];
    setFilterCustomerStatus: React.Dispatch<React.SetStateAction<CustomerStatus[]>>;
    closeFilters: () => void;
    filteredResaCount: number;
    currentWaitersList: Employee[];
    filterWaiter: Employee[];
    setFilterWaiter: React.Dispatch<React.SetStateAction<Employee[]>>;
    resaStatuses: ReservationStatus[];
    customerStatuses: CustomerActions[];
    resaFilters: ResaFiltersType;
    setResaFilters: React.Dispatch<React.SetStateAction<ResaFiltersType>>;
  }

function ReservationFilters(props: ReservationFiltersProps) {
    const clearFilters = () => {
      props.setResaFilters({
        filterNom: '',
        filterCustomerStatus: [],
        filterWaiters: [],
        filterReservationStatus: [],
      });
      // props.setFilterStatus([]);
    }    
  const [selectedStatus, setSelectedStatus] = useState<ReservationStatus[]>([]);

  const handleCheckboxChange = (status: ReservationStatus) => {
    const updatedStatus = [...selectedStatus];
    if (updatedStatus.includes(status)) {
      updatedStatus.splice(updatedStatus.indexOf(status), 1);
    } else {
      updatedStatus.push(status);
    }

    setSelectedStatus(updatedStatus);
    props.setResaFilters(prevState => ({...prevState, filterReservationStatus: updatedStatus}));
  };

  const [selectedCustomerStatus, setSelectedCustomerStatus] = useState<CustomerActions[]>([]);

  const handleStatusCheckboxChange = (status: CustomerActions) => {
    const updatedStatus = [...selectedCustomerStatus];

    if (updatedStatus.includes(status)) {
      updatedStatus.splice(updatedStatus.indexOf(status), 1);
    } else {
      updatedStatus.push(status);
    }
    setSelectedCustomerStatus(updatedStatus);
    // props.setFilterCustomerStatus(updatedStatus);
    props.setResaFilters(prevState => ({...prevState, filterCustomerStatus: updatedStatus}));
  };
  

  const [selectedWaiters, setSelectedWaiters] = useState<Employee[]>([]);

  const handleWaiterSelect = (waiter: Employee) => {
    const updatedWaiters = [...selectedWaiters];

    if (updatedWaiters.includes(waiter)) {
      updatedWaiters.splice(updatedWaiters.indexOf(waiter), 1);
    } else {
      updatedWaiters.push(waiter);
    }
    setSelectedWaiters(updatedWaiters);
    props.setFilterWaiter(updatedWaiters);
    props.setResaFilters(prevState => ({...prevState, filterWaiters: updatedWaiters}));
  };

    return (
      <Grid className='filters-container'>
        <Grid container rowSpacing={3}> 
          <Grid container item display='flex' flexDirection='row' justifyContent='space-between'>
            <FontAwesomeIcon icon={faArrowAltCircleRight} className="ReservationsFiltersClose" onClick={props.closeFilters}/>
            <Button type='reset' onClick={clearFilters}>Clear Filters</Button>
          </Grid>
          <Grid container item xs={12} md={12}>
          <TextField
                variant='outlined'
                size='small'
                type="text"
                placeholder="Nom du client"
                value={props.resaFilters.filterNom.toUpperCase()}
                onChange={e => props.setResaFilters(prevState => ({...prevState, filterNom: e.target.value.toUpperCase()}))}
                className="search-input"
            />
          </Grid>
          <Grid container item display='flex' flexDirection='column'>
            <Typography className='filters-title'>Statut</Typography>
            <Grid container display='flex' flexDirection='row' justifyContent='start' alignItems='start' rowSpacing={0.5}>
              {Array.from(props.resaStatuses).map((status: ReservationStatus) => (
                  <Grid item display='flex' flexDirection='row' alignItems='center' xs={12} md={6} key={status.id}>
                    <FormControlLabel
                      sx={{margin: 0}}
                      control={
                        <Checkbox
                          size="small"
                          sx={{ padding: 0 }}
                          checked={props.resaFilters.filterReservationStatus.includes(status)}
                          onChange={() => handleCheckboxChange(status)}
                        />
                      }
                      label={status.statusLabel} // Associe le texte à la checkbox
                    />
                  </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container item display='flex' flexDirection='column'>
              <Typography className='filters-title'>Statut Client</Typography>
              <Grid container display='flex' flexDirection='row' justifyContent='start' alignItems='start' rowSpacing={0.5}>
                {Array.from(props.customerStatuses).map((status: CustomerActions) => (
                  <Grid item display='flex' flexDirection='row' alignItems='center' xs={12} md={6} key={status.id}>
                    <FormControlLabel
                    sx={{margin: 0}}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ padding: 0 }}
                        checked={props.resaFilters.filterCustomerStatus.includes(status)}
                        onChange={() => handleStatusCheckboxChange(status)}
                      />
                    }
                    label={status.actionLabel} // Associe le texte à la checkbox
                    />
                  </Grid>
                ))}
              </Grid>
          </Grid>
          <Grid container item display='flex' flexDirection='column'>
              <Typography className='filters-title'>Serveur</Typography>
              <Grid container display='flex' flexDirection='row' justifyContent='start' alignItems='start' rowSpacing={0.5}>
                {Array.from(props.currentWaitersList).map((e: Employee) => (
                    <Grid item display='flex' flexDirection='row' alignItems='center' xs={12} md={6} key={e.id}>
                      <FormControlLabel
                      sx={{margin: 0}}
                      control={
                        <Checkbox
                          size="small"
                          sx={{ padding: 0 }}
                          checked={props.resaFilters.filterWaiters.includes(e)}
                          onChange={() => handleWaiterSelect(e)}
                        />
                      }
                      label={`${capitalize(e.prenom)} ${e.nom.toUpperCase()}`}
                    />
                  </Grid>
                ))}
              </Grid>
          </Grid>

          <Grid container item display='flex' flexDirection='column'>
          <GnzButton small label={`Rechercher (${props.filteredResaCount.toString()})`} onClick={props.closeFilters} color="#fff" backgroundColor="#0B4B92" />
         </Grid>

        </Grid>
      </Grid>
    );
}

export default ReservationFilters;