import './BonCadeau.css';
import { useNavigate } from 'react-router-dom';
import React, { Fragment, useRef, useState } from 'react';
import BonCadeauFilters from './BonCadeauxFilters';
import axios from 'axios';
import * as html2pdf from 'html2pdf.js';
// import PrimaryBreadcrumb from '../../../../shared/PrimaryBreadcrumb/PrimaryBreadcrumb';


interface BonsData {
    id: string;
    date_achat: Date;
    date_expiration: Date;
    destinataire: Customer;
    service: Service;
    prix: number;
    from: Customer;
    paiement: MoyenPaiement;
    cree_par: Employee;
    statut?: ModifCancel;
    message?: string;
}

interface ModifCancel{
    statut: string;
    raison: string;
}

interface Employee{
    nom: string;
    prenom: string
}

interface Customer {
    nom: string;
    prenom: string;
    phone: string;
    mail: string;
}

interface MoyenPaiement {
    type: string;
}

interface Service {
    label: string;
}

function BonCadeau() {
    const navigate = useNavigate();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const [filterBon, setFilterBon] = useState("");
    const [filterService, setFilterService] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [sortOption, setSortOption] = useState("default");
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showBon, setShowBon] = useState(false);

    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [telephone, setTelephone] = useState('');
    const [email, setEmail] = useState('');
    const [adresse, setAdresse] = useState('');
    const [cp, setCp] = useState('');
    const [ville, setVille] = useState('');

    const pdfExportRef = useRef<HTMLDivElement | null>(null);
    const handleExportPDF = (nom: string, prenom: string) => {
        if (pdfExportRef.current) {
        const element = pdfExportRef.current;

        element.style.display = 'flex';
        element.style.flexDirection = 'column'
        element.style.justifyContent = 'center';
        element.style.alignItems = 'center';

        html2pdf()
            .from(element)
            .save(`Bon_Cadeau_${nom}_${prenom}.pdf`);   
        }
    };    

    let date_achat1: Date = new Date(2023, 6, 10, 15, 0)
    let date_expi1: Date = new Date(2024, 6, 10)
    let date_achat2: Date = new Date(2023, 5, 20, 12, 30)
    let date_expi2: Date = new Date(2024, 5, 20)

    const customers: Customer[] = [
        {nom: "VERNAY", prenom: "Julien", phone: "0675129477", mail:"julien@gmail.com"},
        {nom: "LE CUNUDER", prenom: "Flammenn", phone: "0658407520", mail: "poupoune@gmail.com"}
    ]

    const modifCancel: ModifCancel[] = [
        {statut: "Annulé", raison: "Défaut de paiement"},
        {statut: "Modifié", raison: "Décalage de la date"}
    ]

    const service: Service[] = [
        {label: "Restaurant"},
        {label: "Bar"},
        {label: "Spa"}
    ]

    const moyen_paiement: MoyenPaiement[] = [
        {type: "Carte Bancaire"},
        {type: "Espèces"},
        {type: "Chèque"}
    ]

    const employee: Employee[] = [
        {nom: "CARMINATI", prenom: "Alessandra"},
        {nom: "TOUGERON", prenom: "Mathieu"}
    ]

    const bons: BonsData[] = [
        { id: "R1024", date_achat: date_achat1, date_expiration: date_expi1, destinataire: customers[1], service: service[0], prix: 250, from: customers[0], paiement: moyen_paiement[0], cree_par: employee[0], statut: modifCancel[0], message: "Ceci est un message pour dire que c'est super les bons cadeau"},
        { id: "R1955", date_achat: date_achat2, date_expiration: date_expi2, destinataire: customers[0], service: service[1], prix: 100, from: customers[1], paiement: moyen_paiement[1], cree_par: employee[1], statut: modifCancel[1], message: "Ceci est un message parce que trop cool les cadeaux"}
    ];

    const sortedBons = bons.slice().sort((a, b) => {
        if (sortOption === "date") {
            return a.date_achat.getTime() - b.date_achat.getTime();
        } else if (sortOption === "destinataire") {
            return a.destinataire.nom.localeCompare(b.destinataire.nom);
        }
        return 0;
    });


    const handleSubmit = () => {    
        const destinataireData = {
            lastname: nom,
            firstname: prenom,
            phone: telephone,
            email,
            address: adresse,
            zipcode: cp,
            city: ville
        };
    
        axios.post('http://localhost:9000/add-customer', destinataireData)
        .then((response) => {
            // Réinitialiser les états après la soumission
            setNom('');
            setPrenom('');
            setTelephone('');
            setEmail('');
            setAdresse('');
            setCp('');
            setVille('');
        })
        .catch(error => {
            console.error('Erreur lors de l\'ajout du destinataire :', error);
        });
    };

    return (
        <div>
        <div className='bons_table'>
        <div className="PrimaryHeader">
        <div className="PrimaryHeaderTitle">
            {/* <PrimaryBreadcrumb breadcrumbs={['Restaurant Hotel', 'Bon Cadeau']}/> */}
        </div>
            <div className="PrimaryHeaderSeparator"></div>
        </div>
            <BonCadeauFilters
                filterBon={filterBon}
                setFilterBon={setFilterBon}
                filterService={filterService}
                setFilterService={setFilterService}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                sortOption={sortOption}
                setSortOption={setSortOption}
            />
            <button className="create-button" onClick={() => setShowCreateForm(true)}>Nouveau bon cadeau</button>
            <h3 className='bons_header'>Bons cadeaux en cours</h3>
            <table>
                <thead>
                    
                </thead>
                <tbody>
                    <tr>
                        <td>Référence</td>
                        <td>Date achat</td>
                        <td>Date expiration</td>
                        <td>Destinataire</td>
                    </tr>
                    {sortedBons
                    .filter(b =>
                        (!startDate || b.date_achat >= startDate) &&
                        (!endDate || b.date_achat <= endDate) &&
                        (filterBon === "" || (b.id.toLowerCase().includes(filterBon.toLowerCase()))) &&
                        (filterService === "" || (b.service.label.toLowerCase().includes(filterService.toLowerCase())))
                    )
                    .map((b, index) => (
                    <React.Fragment key={index}>
                    <tr className={selectedRowIndex === index ? 'selected-row' : ''} onClick={() => setSelectedRowIndex(selectedRowIndex === index ? null : index)}>
                        <td className='table_data'>{b.id}</td>
                        <td className='table_data'>{b.date_achat.toLocaleDateString()}</td>
                        <td className='table_data'>{b.date_expiration.toLocaleDateString()}</td>
                        <td className='table_data'>
                            <p>{b.destinataire.nom} {b.destinataire.prenom}</p>
                            <p>{b.destinataire.phone} - {b.destinataire.mail}</p>
                        </td>
                    </tr>
                    {selectedRowIndex === index && (
                        <Fragment>
                        <tr className="details">
                            <td colSpan={1}>Service</td>
                            <td colSpan={3}>{b.service.label}</td>
                        </tr>
                        <tr className="details">
                            <td colSpan={1}>Prix</td>
                            <td colSpan={3}>{b.prix} €</td>
                        </tr>
                        <tr className="details">
                            <td colSpan={1}>De la part de</td>
                            <td colSpan={3}>{b.from.nom} {b.from.prenom} - {b.from.phone} - {b.from.mail}</td>
                        </tr>
                        <tr className="details">
                            <td colSpan={1}>Paiement</td>
                            <td colSpan={3}>{b.paiement.type}</td>
                        </tr>
                        <tr className="details">
                            <td colSpan={1}>Historique</td>
                            <td colSpan={3}>
                                <p>Créé par {b.cree_par.nom} {b.cree_par.prenom} le {b.date_achat.toLocaleDateString()} à {b.date_achat.getHours()}h{b.date_achat.getMinutes()}</p>
                                <p>{b.statut?.statut} : {b.statut?.raison}</p>
                            </td>
                        </tr>
                        <tr className="details">
                            <td colSpan={4}>
                            <button className="create-button">Modifier</button>
                            <button className="create-button">Annuler</button>
                            <button className="create-button">Envoyer par email</button>
                            <button className="create-button" onClick={() => setShowBon(true)}>Voir bon cadeau</button>
                            </td>
                        </tr>
                        {showBon && (
                            <div className="show-bon-overlay">
                                <div ref={pdfExportRef} className='bon'>
                                <h3>Bon Cadeau N°{b.id}</h3>
                                <div id="bonCadeau" className="show-bon-container">
                                    <p>Pour {b.destinataire.nom} {b.destinataire.prenom}</p>
                                    <p>Carte cadeau d'un montant de {b.prix}€</p>
                                    <p>De la part de {b.from.nom} {b.from.prenom}</p>
                                    <p>Cette carte cadeau est valable jusqu'au {b.date_expiration.toLocaleDateString()} dans notre {b.service.label}</p>
                                    <p>{b.message}</p>
                                </div>
                                </div>
                                <button className="close-button" onClick={() => setShowBon(false)}>X</button>
                                <button className="close-button" onClick={() => [ handleExportPDF(`${b.destinataire.nom}`, `${b.destinataire.prenom}`), setShowBon(false)]}>Imprimer</button>
                            </div>
                        )}
                        </Fragment>
                        )};
                    </React.Fragment>
                    
                    ))}
                </tbody>
            </table>
            {showCreateForm && (
            <div className="create-form-overlay">
                <div className="create-form-container">
                    <form onSubmit={handleSubmit}>
                        {/* Ajoutez votre formulaire ici */}
                        <h3>Nouveau Bon Cadeau</h3>
                        <hr/>
                        <input
                            type="text"
                            placeholder="Numéro de bon"
                        />
                        <select
                            className="filter-input"
                        >
                            {service.map((b, index) => (
                                <option key={index} value={b.label}>{b.label}</option>
                            ))}
                            {/* Ajoutez d'autres options de statut ici */}
                        </select>
                        <input
                            type='number'
                            placeholder='Prix'
                        />€
                        <select
                            className="filter-input"
                        >
                            {moyen_paiement.map((b, index) => (
                                <option key={index} value={b.type}>{b.type}</option>
                            ))}
                            {/* Ajoutez d'autres options de statut ici */}
                        </select>
                        <textarea 
                            placeholder='Commentaire personnel'
                        />
                        <hr/>
                        <h4>Acheteur</h4>
                        <input
                            type="text"
                            placeholder="Nom"
                        />
                        <input
                            type="text"
                            placeholder="Prénom"
                        />
                        <input
                            type="text"
                            placeholder="Téléphone"
                        />
                        <input
                            type="text"
                            placeholder="Email"
                        />
                        <hr/>
                        <h4>Destinataire</h4>
                        <input
                            type="text"
                            placeholder="Nom"
                            value={nom}
                            onChange={(e) => setNom(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Prénom"
                            value={prenom}
                            onChange={(e) => setPrenom(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Téléphone"
                            value={telephone}
                            onChange={(e) => setTelephone(e.target.value)}
                            required
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Adresse"
                            value={adresse}
                            onChange={(e) => setAdresse(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Code Postal"
                            value={cp}
                            onChange={(e) => setCp(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Ville"
                            value={ville}
                            onChange={(e) => setVille(e.target.value)}
                            required
                        />
                        <button className="close-button" type="submit">Valider</button>
                    </form>
                    </div>
            </div>
            )}
            
        </div>
    </div>

    );
}

export default BonCadeau;