import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FeedbackHistory from './FeedbackHistory/FeedbackHistory';


import './Feedback.css';

function FeedbackClient() {
  const {userName} = useSelector((state: RootState) => state.user);  
  
  return (
    <div className="feed">
      <div className="FeedbackTopActions">
        <h3 className='FeedbackTopActionsh3'>Feedback Clients</h3>        
      </div>
      <FeedbackHistory />         
    </div>
  );
}
export default FeedbackClient;