export default class Employee {
    public id: number = 0;
    public service: string = '';
    public responsabilite: string = '';
    public prenom: string = '';
    public nom: string = '';
    public poste: string = '';
    public email: string = '';
    public createdByDpt: boolean = false; 
    

    constructor(
        id: number = 0,
        service: string = '',
        responsabilite: string = '',
        prenom: string = '',
        nom: string = '',
        poste: string = '',
        email: string = '',
        createdByDpt: boolean = false,
        
        

    ) {
        this.id = id;
        this.service = service;
        this.responsabilite = responsabilite;
        this.prenom = prenom;
        this.nom = nom;
        this.poste = poste;
        this.email = email;
        this.createdByDpt = createdByDpt;
    }
}