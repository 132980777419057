import axiosInstance from './axiosInstance';
import Allergies from '../../../models/Allergies';
import AllergiesReservation from '../../../models/AllergiesReservation';
import AllergiesCustomer from '../../../models/AllergiesCustomer';


const getAllAllergies = async (): Promise<Allergies[]> => {
    const response = await axiosInstance.get<Allergies[]>('/Allergy/');
    return response.data;
};

const getAllergiesReservation = async (reservationId: number): Promise<AllergiesReservation[]> => {
    const response = await axiosInstance.get<AllergiesReservation[]>(`/AllergiesReservation/ResaId=${reservationId}`);
    return response.data;
};

const getAllergiesCustomer = async (customerId: number): Promise<AllergiesCustomer[]> => {
    const response = await axiosInstance.get<AllergiesCustomer[]>(`/AllergiesCustomer/${customerId}`);
    return response.data;
};

export const allergiesService = {
    getAllAllergies,
    getAllergiesReservation,
    getAllergiesCustomer
};
